import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    dataRecorders: [],
    totalDataRecorders: 0,
    restaurants: []
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_NUMBER_OF_RECIPES(state, dataRecorder) {
      state.dataRecorders = state.dataRecorders.map(dr => {
        if(dr._id == dataRecorder._id) {
          dr.recorderRecipes = dataRecorder.recorderRecipes
        }
        return dr
      })
    },
    SET_RESTAURANTS(state, restaurants) {
      state.restaurants = restaurants
    },
    SET_DATA_RECORDERS(state, dataRecorders) {
      state.dataRecorders = dataRecorders
    },
    SET_TOTAL_DATA_RECORDERS(state, total) {
      state.totalDataRecorders = total
    },
    DELETE_DATA_RECORDER(state, id) {
      state.dataRecorders = state.dataRecorders.filter(r => r._id != id)
    },
    ADD_DATA_RECORDER(state, dataRecorder) {
      state.dataRecorders.push(dataRecorder)
    },
    UPDATE_DATA_RECORDER(state, dataRecorder) {
      state.dataRecorders = state.dataRecorders.map(r => {
        if(r._id == dataRecorder._id) {
          r = {...dataRecorder}
        }
        return r
      })
    },
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {

    async fetchRestaurantsToManage({ commit }, search) {
      let page = 1
      let perPage = 25
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/?page=${page}&perPage=${perPage}`
        if(search && search.length) {
          url =`${url}&search=${search}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_RESTAURANTS', res.data.restaurants);          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },
    
    async createDataRecorder({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/registerDataRecorder`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          commit('ADD_DATA_RECORDER', res.data);
          toastr.success("Userul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateDataRecorder({ commit }, formData) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/auth/dataRecorders/${formData._id}`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('UPDATE_DATA_RECORDER', res.data);
          toastr.success("Userul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async fetchDataRecorders({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/auth/dataRecorders/?page=${page}&perPage=${perPage}`
        if(search && search.length) {
          url =`${url}&search=${search}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_DATA_RECORDERS', res.data.dataIntroducers);
          commit('SET_TOTAL_DATA_RECORDERS', res.data.total);
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async fetchNumberOfRecipes({ commit }, options) {
      let dataRecorderId = options.dataRecorderId
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/auth/dataRecorders/numberOfRecipes/${dataRecorderId}`
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_NUMBER_OF_RECIPES', res.data);
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async deleteRecorderById({ commit }, id) {
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/auth/dataRecorders/${id}`
  
        const res = await axios.delete(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          toastr.success("Userul a fost sters cu succes!")
          // commit('SET_RESTAURANTS', res.data);
          commit('DELETE_DATA_RECORDER', id);
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },
  },
};
