var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px"}},[_c('section-headline',[_vm._v(" Restaurante ")]),(!_vm.isAdmin && !_vm.isRecorder)?_c('v-btn',{staticClass:"text-gray-500 primary",on:{"click":_vm.openRestaurantDialog}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"hidden-xs-only"},[_vm._v("Adauga restaurant")])],1):_vm._e(),(_vm.isAdmin)?_c('div',{staticClass:"flex"},[_c('v-btn',{staticClass:"text-gray-500 primary",on:{"click":function($event){return _vm.exportRestaurants()}}},[_c('span',[_vm._v("Exporta")])]),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1):_vm._e(),(_vm.isAdmin)?_c('v-row',{staticClass:"mb-5 mt-5"},[_c('v-select',{staticClass:"left smallText sortWidth paddedLeft",staticStyle:{"max-height":"33px"},attrs:{"label":"Plata cu OP","density":"compact","underlined":"","items":_vm.opFilterItems,"color":"primary","item":""},model:{value:(_vm.opFilter),callback:function ($$v) {_vm.opFilter=$$v},expression:"opFilter"}}),_c('v-select',{staticClass:"left smallText sortWidth paddedLeft",staticStyle:{"max-height":"33px"},attrs:{"label":"Meniu public activ","density":"compact","underlined":"","items":_vm.opFilterItems,"color":"primary","item":""},model:{value:(_vm.menuFilter),callback:function ($$v) {_vm.menuFilter=$$v},expression:"menuFilter"}}),_c('v-select',{staticClass:"left smallText sortWidth paddedLeft",staticStyle:{"max-height":"33px"},attrs:{"label":"Restaurant activ","density":"compact","underlined":"","color":"primary","items":_vm.opFilterItems,"item":""},model:{value:(_vm.activeFilter),callback:function ($$v) {_vm.activeFilter=$$v},expression:"activeFilter"}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"left smallText sortWidth paddedLeft",staticStyle:{"max-height":"33px","max-width":"560px","margin-top":"15px"},attrs:{"label":"Data creare","clearable":"","underlined":"","color":"primary"},on:{"click:clear":function($event){return _vm.clearDateField(_vm.createdFilter)}},model:{value:(_vm.createdFilter.filter),callback:function ($$v) {_vm.$set(_vm.createdFilter, "filter", $$v)},expression:"createdFilter.filter"}},'v-text-field',attrs,false),on))]}}],null,false,3908509621),model:{value:(_vm.createdFilter.show),callback:function ($$v) {_vm.$set(_vm.createdFilter, "show", $$v)},expression:"createdFilter.show"}},[_c('v-card',{staticClass:"d-flex flex-column align-center bordered"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticClass:"mr-5",attrs:{"type":"date","label":"Alege data start"},model:{value:(_vm.createdFilter.date1),callback:function ($$v) {_vm.$set(_vm.createdFilter, "date1", $$v)},expression:"createdFilter.date1"}}),_c('v-text-field',{attrs:{"type":"date","label":"Alege data stop"},model:{value:(_vm.createdFilter.date2),callback:function ($$v) {_vm.$set(_vm.createdFilter, "date2", $$v)},expression:"createdFilter.date2"}})],1),_c('v-btn',{staticClass:"text-secondary",on:{"click":function($event){return _vm.formatDateFilter(_vm.createdFilter)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"left smallText sortWidth paddedLeft",staticStyle:{"max-height":"33px","max-width":"560px","margin-top":"15px"},attrs:{"underlined":"","color":"primary","label":"Data expirare","clearable":""},on:{"click:clear":function($event){return _vm.clearDateField(_vm.expiredFilter)}},model:{value:(_vm.expiredFilter.filter),callback:function ($$v) {_vm.$set(_vm.expiredFilter, "filter", $$v)},expression:"expiredFilter.filter"}},'v-text-field',attrs,false),on))]}}],null,false,487354423),model:{value:(_vm.expiredFilter.show),callback:function ($$v) {_vm.$set(_vm.expiredFilter, "show", $$v)},expression:"expiredFilter.show"}},[_c('v-card',{staticClass:"d-flex flex-column align-center bordered"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticClass:"mr-5",attrs:{"type":"date","label":"Alege data start"},model:{value:(_vm.expiredFilter.date1),callback:function ($$v) {_vm.$set(_vm.expiredFilter, "date1", $$v)},expression:"expiredFilter.date1"}}),_c('v-text-field',{attrs:{"type":"date","label":"Alege data stop"},model:{value:(_vm.expiredFilter.date2),callback:function ($$v) {_vm.$set(_vm.expiredFilter, "date2", $$v)},expression:"expiredFilter.date2"}})],1),_c('v-btn',{staticClass:"text-secondary",on:{"click":function($event){return _vm.formatDateFilter(_vm.expiredFilter)}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.restaurants,"headers":_vm.restaurantHeaders,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalRestaurants,"footer-props":{
      itemsPerPageOptions: [25, 50, 100],
      
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.restaurantName",fn:function(props){return [_c('router-link',{class:"px-3 py-2 rounded-md text-sm font-medium sm:ml- grid forceMarginLeft",attrs:{"to":("recipes/?restaurantId=" + (props.item.restaurantId)),"aria-current":"page"}},[_c('span',[_vm._v(" "+_vm._s(props.item.restaurantName))])])]}},{key:"item.createdAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.parseDate(props.item.createdAt))+" ")]}},{key:"item.expiryDate",fn:function(props){return [_vm._v(" "+_vm._s(_vm.getExpiryDate(props.item))+" ")]}},{key:"item.hasPublicMenu",fn:function(props){return [(props.item.hasPublicMenu)?_c('font-awesome-icon',{attrs:{"color":"green","icon":"fa-solid fa-check"}}):_c('font-awesome-icon',{attrs:{"color":"red","icon":"fa-solid fa-xmark"}})]}},{key:"item.paymentPlans",fn:function(props){return [_c('div',{staticClass:"flex"},[_vm._v(" "+_vm._s(props.item.paymentPlans)+" "),(_vm.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"accent"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}],null,true)},[_c('payments-table',{attrs:{"payments":props.item.payments}})],1):_vm._e()],1)]}},{key:"item.paidByOP",fn:function(props){return [(_vm.paymentsByOP(props.item) == 1)?_c('font-awesome-icon',{attrs:{"color":"green","icon":"fa-solid fa-check"}}):(!_vm.paymentsByOP(props.item))?_c('font-awesome-icon',{attrs:{"color":"red","icon":"fa-solid fa-xmark"}}):_c('div',[_vm._v("PARTIAL")])]}},{key:"item.paymentStatus",fn:function(props){return [(props.item.paymentStatus == 'DISABLED')?_c('div',{staticClass:"text--accent"},[_vm._v("INACTIV")]):_c('div',{staticClass:"text--primary"},[_vm._v("ACTIV")])]}},(!_vm.isRecorder)?{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"flex"},[(!_vm.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(!_vm.isDisabled(props.item))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.addCredits(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Adauga credite")])]):_vm._e(),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.openDialog(props.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-pen"}})],1),(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.openDeleteDialog(props.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-trash-can"}})],1):_vm._e()],1)]}}:null],null,true)}),(_vm.deleteDialog)?_c('delete-restaurant',{attrs:{"deleteRestaurant":_vm.deleteRestaurant,"closeDialog":_vm.closeDeleteDialog,"item":_vm.currentRestaurant}}):_vm._e(),(_vm.editDialog)?_c('edit-restaurant',{attrs:{"closeDialog":_vm.closeDialog,"restaurant":_vm.currentRestaurant}}):_vm._e(),(_vm.createDialog)?_c('create-restaurant',{attrs:{"closeDialog":_vm.closeCreateDialog,"companies":_vm.companies,"userName":_vm.userName,"email":_vm.email}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }