<template>
  <v-div class="ml-5 mr-5 halfWidth transferSummaryCard" >
    <div class="grid" v-if="restaurant">
      <v-card-item class="transferSummaryItem" v-if="creditsMessage">
        <v-icon color="accent"> mdi-hand-coin </v-icon>
        {{creditsMessage}}
      </v-card-item>
      <v-card-item class="transferSummaryItem" v-if="recipesMessage">
        <v-icon color="green"> mdi-bowl-mix </v-icon>
        {{recipesMessage}}
      </v-card-item>
      <v-card-item class="transferSummaryItem" v-if="categoriesMessage">
        <v-icon color="secondary"> mdi-format-list-bulleted-type </v-icon>
        {{categoriesMessage}}
      </v-card-item>
      <v-card-item class="transferSummaryItem" v-if="ingredientsMessage">
        <v-icon color="red"> mdi-spoon-sugar </v-icon>
        {{ingredientsMessage}}
      </v-card-item>
    </div>
  </v-div>
</template>

<script>
  export default {
    props: [
      "restaurant",
      "restaurantsHaveSameUser",
      "model",
      "isDestination"
    ],

    computed: {
      creditsMessage() {
        let recipeCredits = this.model.selectedRecipes.length
        if(!recipeCredits) {
          return null
        }
        if(this.model.option == 'COPY') {
          if(this.isDestination) {
            return recipeCredits > 1 ? `Se vor consuma ${recipeCredits} credite` : `Se va consuma ${recipeCredits} credit`
          } else {
            return `Numarul de credite nu se va schimba`
          }
        } else if(this.model.option == 'MOVE') {
          if(this.isDestination) {
            return recipeCredits > 1 ? `Se vor consuma ${recipeCredits} credite` : `Se va consuma ${recipeCredits} credit`
          } else {
            return recipeCredits > 1 ? `Se vor primi ${recipeCredits} credite` : `Se va primi ${recipeCredits} credit`
          }
        }
      },
      recipesMessage() {
        let recipes = this.model.selectedRecipes.length
        if(!recipes) {
          return null
        }
        if(this.model.option == 'COPY') {
          if(this.isDestination) {
            return recipes > 1 ? `Se vor adauga ${recipes} retete` : `Se va adauga ${recipes} reteta`
          } else {
            return `Numarul de retete nu se va schimba`
          }
        } else if(this.model.option == 'MOVE') {
          if(this.isDestination) {
            return recipes > 1 ? `Se vor adauga ${recipes} retete` : `Se va adauga ${recipes} reteta`
          } else {
            return recipes > 1 ? `Se vor sterge ${recipes} retete` : `Se va sterge ${recipes} reteta`
          }
        }
      
      },
      categoriesMessage() {
        let categories = this.model.selectedCategories.length
        if(!categories) {
          return null
        }
        if(this.model.option == 'COPY') {
          if(this.isDestination) {
            return categories > 1 ? `Se vor adauga ${categories} categorii` : `Se va adauga ${categories} categorie`
          } else {
            return `Numarul de categorii nu se va schimba`
          }
        } else if(this.model.option == 'MOVE') {
          if(this.isDestination) {
            return categories > 1 ? `Se vor adauga ${categories} categorii` : `Se va adauga ${categories} categorie`
          } else {
            return categories > 1 ? `Se vor sterge ${categories} categorii` : `Se va sterge ${categories} categorie`
          }
        }
      
      },
      ingredientsMessage() {
        let ingredients = this.model.selectedIngredients.length
        if(!ingredients || this.restaurantsHaveSameUser) {
          return null
        }
        if(this.model.option == 'COPY') {
          if(this.isDestination) {
            return ingredients > 1 ? `Se vor adauga ${ingredients} ingrediente` : `Se va adauga ${ingredients} ingredient`
          } else {
            return `Numarul de ingrediente nu se va schimba`
          }
        } else if(this.model.option == 'MOVE') {
          if(this.isDestination) {
            return ingredients > 1 ? `Se vor adauga ${ingredients} ingrediente` : `Se va adauga ${ingredients} ingredient`
          } else {
            return ingredients > 1 ? `Se vor sterge ${ingredients} ingrediente` : `Se va sterge ${ingredients} ingredient`
          }
        }
      
      }
    },
    

    methods: {
      shouldDisplayCredits() {
        // doar daca sunt retete de transferat
      },
      
      shouldDisplayIngredients() {
        if(this.restaurantsHaveSameUser) {
          return false
        }
      },
      totalRecipes(restaurant) {
        let total = restaurant ? restaurant.activeRecipes.length : 0
        return total == 1 ? `${total} reteta` : `${total} retete`
      },
      totalIngredients(restaurant) {
        let total = restaurant ? restaurant.activeIngredients.length : 0
        return total == 1 ? `${total} ingredient` : `${total} ingrediente`
      },
      totalCategories(restaurant) {
        let total = restaurant ? restaurant.activeCategories.length : 0
        return total == 1 ? `${total} categorie` : `${total} categorii`
      },
      totalCredits(restaurant) {
        let total = restaurant ? restaurant.availableCredits : 0
        return total == 1 ? `${total} credit ramas` : `${total} credite ramase`
      },
      
    }
  };
</script>