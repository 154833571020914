<template>
    <v-row>
        <v-col cols="12" md="3" sm="12" lg="3">
            <div class="ma-6 pa-6">
                <template>
                    <!-- <v-card> -->
                    <v-navigation-drawer permanent expanded fixed width="20%">
                        <v-list>
                            <v-list-item class="px-1 text-center">
                                <v-list-item-avatar size="200" class="mx-auto">
                                    <v-img :src="publicData.restaurant && publicData.restaurant.logo ? publicData.restaurant.logo.signedUrl : 'http://localhost:8080/img/valori-nutritionale-logoArtboard%202@4x.91070477.png'"></v-img>
                                </v-list-item-avatar>
                            </v-list-item>
                            <v-list-item link>
                                <v-list-item-content>
                                    <v-list-item-title class="title">{{ publicData.restaurant.restaurantName
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ publicData.restaurant.description }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-phone</v-icon>

                                </v-list-item-icon>
                                <v-list-item-content>
                                    {{ publicData.restaurant.phone }}
                                </v-list-item-content>

                            </v-list-item>
                         
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-facebook</v-icon>
                                    <v-icon>mdi-instagram</v-icon>
                                    <v-icon>mdi-pin</v-icon>
                                </v-list-item-icon>

                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                    <!-- </v-card> -->
                </template>
            </div>
        </v-col>
        <v-col cols="12" md="8" sm="12" lg="8">
            <div class="ma-6 pa-6">
                <!-- <template> -->
                <v-tabs>



                    <v-tab link v-for="group in publicData.groups" :key="group.category">
                        <v-list-item>

                            <v-list-item-icon>
                                <v-icon>mdi-folder</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ group.category }}</v-list-item-title>
                        </v-list-item>
                    </v-tab>


                    <v-tab-item v-for="group in publicData.groups" :key="group.category">
                        <v-card class="mx-auto ma-6 pa-6" tile>

                            <v-list-item three-line v-for="recipe in group.recipes" :key="recipe._id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ recipe.name }} ({{ recipe.totalWeight }}
                                        grame)</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <div v-html="recipe.formattedIngredients"></div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <div v-html="recipe.formattedValuesPer100g"></div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    {{ recipe.price }} lei
                                </v-list-item-icon>
                            </v-list-item>
                        </v-card>
                    </v-tab-item>

                </v-tabs>

            </div>


        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "HelloWorld",
    data: () => ({
        drawer: null,
      

    }),
    created() {
        if (this.$route.params.id) {
            // console.log(this.$route.params)
            this.$store.dispatch('fetchPublicRecipes', { restaurant: this.$route.params.id, recipe: this.$route.params.recipe });
            // console.log(this.$store.state)
        }

    },
    computed: {
        publicData() {
            return this.$store.state.recipes.publicRecipes
        },
    }
};
</script>