import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    restaurants: [],
    relatedRestaurants: [],
    fieldNames: [],
    totalRestaurants: 0,
    realTotalRestaurants: 0,
    loading: false
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_RESTAURANTS(state, restaurants) {
      state.restaurants = restaurants
    },
    SET_RELATED_RESTAURANTS(state, restaurants) {
      state.totalRestaurants = restaurants
    },
    SET_TOTAL_RESTAURANTS(state, total) {
      state.totalRestaurants = total
    },
    SET_REAL_TOTAL_RESTAURANTS(state, total) {
      state.realTotalRestaurants = total
    },
    DELETE_RESTAURANT(state, id) {
      state.restaurants = state.restaurants.filter(r => r._id != id)
    },
    SET_FIELD_NAMES(state, fieldNames) {
      state.fieldNames = fieldNames
    },
    DELETE_FIELD_NAME(state, fieldName) {
      state.fieldNames = state.fieldNames.filter(r => {
        return (r._id != fieldName._id)
      })
    },
    ADD_FIELD_NAME(state, fieldName) {
      state.fieldNames.push(fieldName)
    },
    UPDATE_FIELD_NAME(state, fieldName) {
      state.fieldNames = state.fieldNames.map(r => {
        if(r._id == fieldName._id) {
          r = {...fieldName}
        }
        return r
      })
    },
    SET_LOADING_R(state, loading) {
      state.loading = loading
    }
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {

    async uploadLogo({commit}, data) {
      let restaurantId = data.restaurantId
      let file = data.file

      const fd = new FormData();
      fd.append("file", file);

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/files/uploadRestaurantLogo/${restaurantId}`,
          fd,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      } catch(e) {
        toastr.error(e)
      }
    },

    async updateRestaurant({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/restaurants/updateSocialInfo/${model._id}`,
          model,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          toastr.success("Informatiile au fost actualizate!")
          cb()
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async exportRestaurants({ commit }, options) {
    
      let cb = options.cb
      let search = options.search || ''
      let filter = options.filter && options.filter != {} ? JSON.stringify(options.filter) : null
      let queries = []
      if(search && search.length) {
        queries.push(`search=${search}`)
      }
      if(filter) {
        queries.push(`filter=${filter}`)
      }

      let queryString = ''
      if(queries.length) {
        queryString = `?${queries.join('&')}`
      }

      
      try {
        commit('SET_LOADING_R', true)
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/restaurants/export/admin${queryString}`,
          {
            withCredentials: true,
            responseType: 'blob'
          }
        );
        cb(res)
        commit('SET_LOADING_R', false)

      } catch (error) {
        console.log(error)
        commit('SET_LOADING_R', false)
        return this.error;
      }
    },

    async fetchRestaurants({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
      let filter = options.filter && options.filter != {} ? JSON.stringify(options.filter) : null
      try {
        // Send api request.
        commit('SET_LOADING_R', true)
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/?page=${page}&perPage=${perPage}`
        if(search && search.length) {
          url =`${url}&search=${search}`
        }
        if(filter) {
          url =`${url}&filter=${filter}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        commit('SET_LOADING_R', false)
        // Put user into store.
        if (res.status === 200) {
          commit('SET_RESTAURANTS', res.data.restaurants);
          commit('SET_TOTAL_RESTAURANTS', res.data.total);
          commit('SET_REAL_TOTAL_RESTAURANTS', res.data.realTotal);
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async fetchRelatedRestaurants({ commit }, restaurantId) {
      // let page = options.page || 1
      // let perPage = options.perPage || 25
      // let search = options.search || ''
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/related/${restaurantId}`
        // if(search && search.length) {
        //   url =`${url}&search=${search}`
        // }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_RELATED_RESTAURANTS', res.data);
          // commit('SET_TOTAL_RESTAURANTS', res.data.total);
          // commit('SET_REAL_TOTAL_RESTAURANTS', res.data.realTotal);
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async deleteRestaurantById({ commit }, id) {
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/${id}`
  
        const res = await axios.delete(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status == 200) {
          toastr.success("Restaurantul a fost sters cu succes!")
          // commit('SET_RESTAURANTS', res.data);
          commit('DELETE_RESTAURANT', id);
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async updatePublicMenu({ commit }, data) {
      const restaurantId = data.restaurantId
      const hasPublicMenu = data.hasPublicMenu
      const cb = data.cb
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/changePublicMenu/${restaurantId}/?hasPublicMenu=${hasPublicMenu}`
        const res = await axios.put(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          // commit('SET_RESTAURANTS', res.data);
          cb()
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async updateMenuConfigurations({ commit }, data) {
      const restaurantId = data.restaurantId
      const model = data.model

      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/changeMenuConfigurations/${restaurantId}`
        const res = await axios.post(
          url,
          model,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 201) {
          toastr.success("Configurarile au fost salvate!")
          // commit('SET_RESTAURANTS', res.data);
          // cb()
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async fetchFieldNames({ commit }) {
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/fieldNames`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_FIELD_NAMES', res.data);
          
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },

    async createFieldName({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/fieldNames`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          commit('ADD_FIELD_NAME', res.data);
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateFieldName({ commit }, formData) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/fieldNames`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('UPDATE_FIELD_NAME', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async changePassword({ commit }, formData) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/auth/changePassword`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          toastr.success("Parola a fost schimbata cu succes!")
        }

      } catch (error) {
        toastr.error("Parola introdusa nu este corecta!")
        console.log(error)
        return this.error;
      }
    },

    async sendResetPasswordEmail({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/password/forgotlink`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          toastr.success("A fost trimis un e-mail cu link-ul de resetare parola")
        }

      } catch (error) {
        toastr.error("Email-ul nu a fost gasit")
        console.log(error)
        return this.error;
      }
    },

    async resetPassword({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/password/reset`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          toastr.success("Parola ta a fost schimbata cu succes!")
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/login`;
        }

      } catch (error) {
        toastr.error("A aparut o problema. Te rugam sa incerci din nou.")
        console.log(error)
        return this.error;
      }
    },

    async deleteFieldNameById({ commit }, id) {
      try {
        // Send api request.
        const res = await axios.delete(
          `${process.env.VUE_APP_API_URL}/fieldNames/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('DELETE_FIELD_NAME', res.data);
          toastr.success("Campul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    }

  },
};
