<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga categorie reteta</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Nume categorie *"
                  v-model="model.name"
                  required
                  :rules="[
                    v => !!v || `Completeaza numele categoriei`,
                    v => !v || v.length <= 50 || `Numele trebuie sa contina cel mult 25 de caractere`
                  ]"
                  outlined
                />
            </div>

            <div class="padded">
              <v-text-field
                  type="number"
                  placeholder="Prioritate *"
                  v-model="model.priority"
                  :max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :rules="[
                    v => !!v || `Completeaza prioritatea`,
                    v => Number(v) > 0 && Number.isInteger(Number(v))|| `Prioritatea trebuie sa fie un numar pozitiv si intreg`
                  ]"
                  required
                  outlined
                />
            </div>

            <div class="padded">
              <v-select label="Unitate de masura *" v-model="model.unitMeasure"
                :items="categoryUnitMeasures" color="primary" :rules="[v => v !== null || `Alege unitatea de masura`]"
                >
              
              </v-select>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "model",
    "closeDialog",
    "saveCategory"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    categoryUnitMeasures: ["Grame", "Mililitri"]
  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      if(this.$refs.form.validate()) {
        this.saveCategory(this.model)
        this.close()
      }

    }
  },
  computed: {
  },
  created() {
    if(!this.model.unitMeasure) {
      this.model.unitMeasure = 'Grame'
    }
  }
};
</script>