<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{isEdit ? 'Editeaza' : 'Adauga'}} introducator retete</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" >
            <div class="customFlex">
            <div class="padded w-half m-5">
              <v-text-field
                  type="text"
                  placeholder="Nume *"
                  v-model="model.name"
                  required
                  label="Nume *"
                  :rules="[
                    v => !!v || `Completeaza numele userului`,
                    v => v && v.length <= 25 || `Numele trebuie sa contina cel mult 25 de caractere`
                  ]"
                  outlined
                />
            </div>

            <div class="padded w-half m-5" >
              <v-text-field
                  type="text"
                  placeholder="Email *"
                  v-model="model.email"
                  required
                  label="Email *"
                  :disabled="isEdit"
                  :rules="[
                    v => !!v || `Completeaza email-ul userului`,
                  ]"
                  outlined
                />
            </div>

            <div class="padded w-half m-5" v-if="!isEdit">
              <v-text-field
                  type="text"
                  placeholder="Parola *"
                  v-model="model.password"
                  required
                  label="Parola *"
                  :rules="[
                    v => !!v || `Completeaza parola userului`,
                  ]"
                  outlined
                />
            </div>
            </div>

            <div class="padded w-full m-5">
              <v-autocomplete class="" d-flex v-model="model.managesRestaurants" label="Selecteaza restaurante" hide-selected
                text-color="white" :items="restaurantsToManage" item-text="restaurantName" item-value="_id" outlined multiple item small-chips :clearable="true"
                :deletable-chips="true" :search-input.sync="searchRestaurant" no-filter return-object  @change="searchRestaurant=''">
                <template slot="selection" slot-scope="data">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :input-value="data.selected" color="warning" text-color="white" close
                        @click:close="removeRestaurant(data.item)" v-bind="attrs" v-on="on">
                        <strong>{{ data.item.restaurantName }}</strong>
                      </v-chip>
                    </template>
                    <span>{{ data.item.restaurantName }}</span>
                  </v-tooltip>

                </template>
              </v-autocomplete>
            </div>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red"  @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  props: [
    "closeDialog",
    "model"
  ],
  data: () => ({
    dialog: true,
    // isEdit: false,
    searchRestaurant: ''
  }),
  watch: {
    searchRestaurant(newVal) {
      this.timeout = setTimeout(() => {
        this.searchRestaurants()
      }, 500);
    }

  },
  computed: {
    restaurantsToManage() {
      return this.$store.state.users.restaurants.concat(this.model.managesRestaurants ? this.model.managesRestaurants : [])
    },
    isEdit() {
      return this.model._id
    }
  },
  methods: {
    close() {
      this.closeDialog()
      
    },
    searchRestaurants() {
      this.$store.dispatch('fetchRestaurantsToManage', 
        this.searchRestaurant
      );
    },
    removeRestaurant(restaurant) {
      this.model.managesRestaurants = this.model.managesRestaurants.filter(a => {
        return a._id != restaurant._id
      })
    },
    save() {
      if(this.$refs.form.validate()) {
        if(this.model._id) {
          this.$store.dispatch('updateDataRecorder', this.model);
        } else {
          this.$store.dispatch('createDataRecorder', this.model);
        }
        
        this.close()
      }

    }
  },
  created() {
    this.$store.dispatch('fetchRestaurantsToManage');
  },

};
</script>

<style scoped>
.customFlex{
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.m-0{
  margin:0
}
.padded.w-half.m-5 {
    margin: 0.5em!important;
}

.padded.w-full.m-5 {
    margin: 0.5em!important;
}

.w-full{
  max-width: 800px!important;
  margin: 50px auto;
}
</style>