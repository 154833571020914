import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    tags: [],
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {

    SET_TAGS(state, tags) {
      state.tags = tags
    },
    DELETE_TAG(state, tag) {
      state.tags = state.tags.filter(r => {
        return (r._id != tag._id)
      })
    },
    ADD_TAG(state, tag) {
      state.tags.unshift(tag)
    },
    UPDATE_TAG(state, tag) {
      state.tags = state.tags.map(r => {
        if(r._id == tag._id) {
          r = {...tag}
        }
        return r
      })
    },
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {
    async fetchTags({ commit }) {
        try {
          // Send api request.
          const res = await axios.get(
            `${process.env.VUE_APP_API_URL}/tags`,
            {
              withCredentials: true,
            },
          );
          // Put user into store.
          if (res.status === 200) {
            commit('SET_TAGS', res.data);
            
            return true;
          }
        } catch (error) {
          console.log(error)
          return
          
        }
    },

    async createTag({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/tags`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status == 201) {
          toastr.success("Eticheta a fost adaugata!")
          commit('ADD_TAG', res.data);
          
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateTag({ commit }, formData) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/tags`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status == 200) {
          commit('UPDATE_TAG', res.data);
          toastr.success("Eticheta a fost updatata!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async deleteTagById({ commit }, id) {
      try {
        // Send api request.
        const res = await axios.delete(
          `${process.env.VUE_APP_API_URL}/tags/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status == 200) {
          commit('DELETE_TAG', res.data);
          toastr.success("Etichta a fost stearsa!")
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },


  },
};
