<template>
    <div :class="['form-element','py-2', className]">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" :for="name">
            {{ label }} <span v-if="isRequired"> *</span>
        </label> -->
        <select
            :name="name"
            :id="name"
            class="w-full border bg-white rounded px-3 py-2 outline-none shadow  mb-4  text-gray-700 leading-tight  rounded "
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >

            <option value="" disabled selected>Selecteaza categoria retetei</option>
            <option
                v-for="(option, i) in options"
                :key="i"
                :value="option.name"
            >
                {{ option.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
        name: String,
        label: String,
        isRequired: Boolean,
        options: Array,
        className: String
    },
    emits: ["update:modelValue"],
};
</script>
