<template>
  <div style="position: relative">

    <qr-code :text="link" :size="size" :color="color" :bg-color="bgColor" error-level="M">
    </qr-code>

  </div>

</template>

<script>
export default {
  props: [
    "color", "bgColor", "size", "restaurant","recipe"
  ],
  data: () => ({
    dialog: true,
  }),
  watch: {
  },
  computed: {
    link() {
      return !this.recipe ? `https://valori-nutritionale.ro/restaurant/${this.restaurant.slug}&utm=qrcode` : `https://meniu.valori-nutritionale.ro/recipes/public/${this?.recipe._id}?restaurantId=${this.restaurant._id}&utm=qrcode`
      // return !this.recipe ? `https://app.valori-nutritionale.ro/meniu/${this.restaurant.slug}&utm=qrcode` : `https://app.valori-nutritionale.ro/meniu/${this?.restaurant.slug}/${this.recipe.slug}&utm=qrcode`
    }
  },
  methods: {

  },
  created() {
  }
};
</script>