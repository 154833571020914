<template>
  <div style="margin: 10px">
    <section-headline>
      Restaurante
    </section-headline>
    <v-btn v-if="!isAdmin && !isRecorder" @click="openRestaurantDialog" class="text-gray-500 primary" >
      <v-icon left dark>mdi-plus</v-icon>
      <span class="hidden-xs-only" >Adauga restaurant</span>
      
    </v-btn>

    <div class="flex" v-if="isAdmin">
      <v-btn @click="exportRestaurants()" class="text-gray-500 primary">
        <span>Exporta</span>
      </v-btn>
      <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
    </div>
  
    <v-row v-if="isAdmin" class="mb-5 mt-5">
      <v-select label="Plata cu OP" v-model="opFilter" class="left smallText sortWidth paddedLeft"
        density="compact" style="max-height: 33px;" underlined  :items="opFilterItems" color="primary" item>
      </v-select>

      <v-select label="Meniu public activ" v-model="menuFilter" class="left smallText sortWidth paddedLeft"
        density="compact" style="max-height: 33px;" underlined  :items="opFilterItems" color="primary" item>
      </v-select>

      <v-select label="Restaurant activ" v-model="activeFilter" class="left smallText sortWidth paddedLeft"
        density="compact" style="max-height: 33px;" underlined  color="primary"  :items="opFilterItems" item>
      </v-select>
      <v-menu offset-y  :close-on-content-click="false"  v-model="createdFilter.show">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="createdFilter.filter"
            label="Data creare"
            clearable
            class="left smallText sortWidth paddedLeft"
          style="max-height: 33px; max-width: 560px;  margin-top: 15px" underlined  color="primary"
            v-bind="attrs"
            v-on="on"
            @click:clear="clearDateField(createdFilter)"
          ></v-text-field>
        </template>
        <v-card class="d-flex flex-column align-center bordered">
          <div class="d-flex justify-center">
            <v-text-field v-model="createdFilter.date1" class="mr-5" type="date" label="Alege data start"/>
            <v-text-field v-model="createdFilter.date2" type="date" label="Alege data stop"/>
          </div>
          <v-btn class="text-secondary" @click="formatDateFilter(createdFilter)">
            OK
          </v-btn>
        </v-card>
      </v-menu>

      <v-menu offset-y :close-on-content-click="false" v-model="expiredFilter.show">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="left smallText sortWidth paddedLeft"
            style="max-height: 33px; max-width: 560px; margin-top: 15px" underlined  color="primary"
            v-model="expiredFilter.filter"
            label="Data expirare"
            clearable
            v-bind="attrs"
            v-on="on"
            @click:clear="clearDateField(expiredFilter)"
          ></v-text-field>
        </template>
        <v-card class="d-flex flex-column align-center bordered">
              
          <div class="d-flex justify-center">
            <v-text-field v-model="expiredFilter.date1" class="mr-5" type="date" label="Alege data start"/>
            <v-text-field v-model="expiredFilter.date2" type="date" label="Alege data stop"/>
          </div>
          <v-btn class="text-secondary" @click="formatDateFilter(expiredFilter)">
            OK
          </v-btn>
        </v-card>
      </v-menu>

    </v-row>
    <v-data-table
      :items="restaurants" 
      :headers="restaurantHeaders" 
      :search="search"
      :options.sync="options"
      :server-items-length="totalRestaurants"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100],
        
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
        
        ></v-text-field>
      </template>
      <template v-slot:[`item.restaurantName`]="props">
        <router-link :to="`recipes/?restaurantId=${props.item.restaurantId}`"
          :class="`px-3 py-2 rounded-md text-sm font-medium sm:ml- grid forceMarginLeft`" aria-current="page"> <span>
            {{ props.item.restaurantName }}</span>
        </router-link>

      </template>
      <template v-slot:[`item.createdAt`]="props">
        {{parseDate(props.item.createdAt)}}

      </template>

      <template v-slot:[`item.expiryDate`]="props">
        {{getExpiryDate(props.item)}}

      </template>

      <template v-slot:[`item.hasPublicMenu`]="props">
        <!-- <v-icon color="primary" v-if="props.item.hasPublicMenu">mdi-check-bold</v-icon> -->
        <font-awesome-icon  color="green" v-if="props.item.hasPublicMenu" icon="fa-solid fa-check" />
        <font-awesome-icon color="red"  v-else icon="fa-solid fa-xmark" />

      </template>

      <template v-slot:[`item.paymentPlans`]="props">
        <div class="flex"> 
          {{props.item.paymentPlans}}
          <v-tooltip top v-if="isAdmin">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-3" color="accent" v-bind="attrs" v-on="on" > mdi-information-outline</v-icon>
            </template>
            <payments-table :payments="props.item.payments"/>
        </v-tooltip>
        </div>

      </template>
      <template v-slot:[`item.paidByOP`]="props">
        <font-awesome-icon  color="green" v-if="paymentsByOP(props.item) == 1" icon="fa-solid fa-check" />
        <font-awesome-icon color="red"  v-else-if="!paymentsByOP(props.item)" icon="fa-solid fa-xmark" />
        <div v-else>PARTIAL</div>
      </template>
      <template v-slot:[`item.paymentStatus`]="props">

        <div  class="text--accent" v-if="props.item.paymentStatus == 'DISABLED'" >INACTIV</div>
        <div  class="text--primary" v-else >ACTIV</div>
        
      </template>
      <template v-slot:[`item.actions`]="props" v-if="!isRecorder">
        <div class="flex">
          <v-tooltip top v-if="!isAdmin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn  v-bind="attrs" v-on="on" color="primary" text
                v-if="!isDisabled(props.item)"
                @click="addCredits(props.item)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adauga credite</span>
          </v-tooltip>
  
          <v-btn 
            @click="openDialog(props.item)"
            color="accent" text
          >
            <font-awesome-icon  icon="fa-solid fa-pen" />
          </v-btn> 

          <v-btn  color="red" v-if="isAdmin" 
            @click="openDeleteDialog(props.item)" text
          >
            <font-awesome-icon  icon="fa-solid fa-trash-can" />
          </v-btn> 
        </div>
      </template>
    </v-data-table>

    <delete-restaurant
      v-if="deleteDialog"
      :deleteRestaurant="deleteRestaurant"
      :closeDialog="closeDeleteDialog"
      :item="currentRestaurant"
    />

    <edit-restaurant
      v-if="editDialog"
      :closeDialog="closeDialog"
      :restaurant="currentRestaurant"
    />

    <create-restaurant
      v-if="createDialog"
      :closeDialog="closeCreateDialog"
      :companies="companies"
      :userName="userName"
      :email="email"
    />
  </div>

</template>
<script>
import DeleteRestaurant from '../components/Modals/DeleteRestaurant.vue';
import EditRestaurant from '../components/Modals/EditRestaurant.vue';
import CreateRestaurant from '../components/Modals/CreateRestaurant.vue';
import PaymentsTable from '../components/Tables/PaymentsTable.vue'
import AuthLayout from '../Layouts/AuthLayout.vue';
import SectionHeadline from './../components/Typography/SectionHeadline.vue';

import moment from "moment-timezone";


export default {
  components: { AuthLayout, DeleteRestaurant, CreateRestaurant, SectionHeadline, EditRestaurant, PaymentsTable },
  data() {
    return {
      deleteDialog: false,
      createDialog: false,
      editDialog: false,
      currentRestaurant: null,
      search: '',
      options: {},
      opFilter: 'all',
      opFilterItems: [
        {
          text: 'Toti',
          value: 'all'
        },
        {
          text: 'DA',
          value: 'true'
        },
        {
          text: 'NU',
          value: 'false'
        }
      ],
      menuFilter: 'all',
      activeFilter: 'all',
      createdFilter: {
        show: false, date1: null, date2: null, filter: null
      },
      expiredFilter: {
        show: false, date1: null, date2: null, filter: null
      }
    
      
    };
  },
  
  watch: {
    options() {
      this.searchRestaurants()
    },
    opFilter() {
      this.searchRestaurants()
    },
    menuFilter() {
      this.searchRestaurants()
    },
    activeFilter() {
      this.searchRestaurants()
    },
    search(newVal) {
      this.timeout = setTimeout(() => {
        this.searchRestaurants()
      }, 500);
    },
  },
  computed: {
    // isDev() {
    //   return this.$store.state.auth.isDev
    // },
    
    totalRestaurants() {
      return this.$store.state.restaurants.totalRestaurants
    },
    loading() {
      return this.$store.state.restaurants.loading
    },
    isAdmin() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
    },
    isRecorder() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'DATA_RECORDER'
    },
    restaurantHeaders() {
      let userHeaders = [
        {
          text: "Restaurant",
          value: 'restaurantName'
        },
        {
          text: "Companie",
          value: 'companyName'
        },
       
        {
          text: "Adresa",
          value: 'companyAddress'
        },
        {
          text: "Pachete",
          value: 'paymentPlans'
        },
        {
          text: "Retete active",
          value: 'activeRecipes'
        },
        {
          text: "Credite ramase",
          value: 'availableCredits'
        },
        {
          text: "Data creare",
          value: 'createdAt'
        },
        {
          text: "Data expirare",
          value: 'expiryDate'
        },
        {
          text: "Actiuni",
          value: 'actions'
        }
      ]
      let adminHeaders = [
        {
          text: "Restaurant",
          value: 'restaurantName'
        },
        {
          text: "Companie",
          value: 'companyName'
        },
        {
          text: "Nume",
          value: 'userName'
        },
        {
          text: "Email",
          value: 'email'
        },
        {
          text: "CUI",
          value: 'vatId'
        },
        {
          text: "Telefon",
          value: 'userPhone'
        },
        // {
        //   text: "Adresa",
        //   value: 'companyAddress'
        // },
        {
          text: "Tip user",
          value: 'role'
        },
        {
          text: "Pachete",
          value: 'paymentPlans'
        },
        {
          text: "Retete active",
          value: 'activeRecipes'
        },
        {
          text: "Credite ramase",
          value: 'availableCredits'
        },
        {
          text: "Platit cu OP",
          value: 'paidByOP'
        },
        {
          text: "Data expirare",
          value: 'expiryDate'
        },
        {
          text: "Status",
          value: 'paymentStatus'
        },
        {
          text: "Meniu public activ",
          value: 'hasPublicMenu'
        },
        {
          text: "Data creare",
          value: 'createdAt'
        },
        {
          text: "Actiuni",
          value: 'actions'
        }
      ]
      return this.isAdmin ? adminHeaders : userHeaders
    },
    companies() {
      let companies = this.restaurants.map(r => {
        return {
          vatId: r.vatId, companyName: r.companyName
        }
      })

      return companies
    },
    userName() {
      return this.restaurants.length ? this.restaurants[0].userName : null
    },
    email() {
      return this.restaurants.length ? this.restaurants[0].email : null
    },
    restaurants() {
      return this.$store.state.restaurants.restaurants.map(r => {
        if (!r || !r.user) {
          return {}
        }        
        r.userName = r.user.name
        r.userId = r.user._id
        r.email = r.user.email
        r.companyName = r.company.companyName
        r.vatId = r.company.vatId
        r.companyAddress = r.company.companyAddress
        r.availableCredits = r.totalCredits - r.usedCredits
        r.paymentPlans = r.user.paymentPlans
        r.activeRecipes = r.user.activeRecipes
        r.restaurantId = r._id
        r.userPhone = r.user.phoneNumber
        r.role = r.user.role || 'ST'
        return r
      })
    }
  },
  methods: {
     clearDateField(filterItem) {
      filterItem.date1 = null
      filterItem.date2 = null
      this.searchRestaurants()
      // filterItem.operator = 'before'
    },

    formatDateFilter (filterItem) {
      let filterValue = null
    
      if(!filterItem.date1 || !filterItem.date2) {
        return
      }
      filterValue = `${filterItem.date1} - ${filterItem.date2}`
      
      filterItem.filter = filterValue
      filterItem.show = false
      this.searchRestaurants()
    },
    isDisabled(restaurant) {
      return restaurant.paymentStatus == 'DISABLED'
    },
    getExpiryDate(restaurant) {
      // get latest expiry date
      let expiryDates = restaurant.payments.filter(p => p.operation == 'SUBSCRIBE').map(p => p.endDate)
      expiryDates.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);

        // Sort in descending order, so the most recent date comes first
        return dateB - dateA;
      });
      return this.parseDate(expiryDates[0])
    },
    paymentsByOP(restaurant) {
      if(!restaurant.payments.length) {
        return 0
      }
      let totalPayments = restaurant.payments.filter(p => p.operation == 'SUBSCRIBE').length
      let paymentsWithOP = restaurant.payments.filter(p => p.operation == 'SUBSCRIBE').filter(p => p.paidByOP).length
      return paymentsWithOP / totalPayments
      // return 0
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    addCredits(restaurant) {
      let url = `https://valori-nutritionale.ro/adauga-credite/?nume=${restaurant.userName}&email=${restaurant.email}&cif=${restaurant.vatId}&restaurant=${restaurant.restaurantName}`
      window.open(url, "_blank");
    },
    exportRestaurants() {
      let filter = this.getFilter()
      this.$store.dispatch("exportRestaurants", {
        search: this.search,
        filter: filter,
        cb: (response) => {
          let fileName = "Restaurante.xlsx"

          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');

          fileLink.href = fileUrl;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink)
          fileLink.click();
        }
      })
    },
    getFilter() {
      let filter = {}
      if(this.opFilter && this.opFilter != 'all') {
        filter.paidByOP = this.opFilter
      }
      if(this.menuFilter && this.menuFilter != 'all') {
        filter.hasPublicMenu = this.menuFilter
      }
      if(this.activeFilter && this.activeFilter != 'all') {
        filter.active = this.activeFilter
      }
      if(this.createdFilter && this.createdFilter.date1 && this.createdFilter.date2) {
        filter.createdAt = {date1: this.createdFilter.date1, date2: this.createdFilter.date2}
      }
      if(this.expiredFilter && this.expiredFilter.date1 && this.expiredFilter.date2) {
        filter.endDate = {date1: this.expiredFilter.date1, date2: this.expiredFilter.date2}
      }
      return filter
    },
    searchRestaurants() {
      let filter = this.getFilter()
      this.$store.dispatch('fetchRestaurants', {
        page: this.options.page, 
        perPage: this.options.itemsPerPage, 
        search: this.search,
        filter: filter
      });
    },
    openDeleteDialog(restaurant) {
      this.deleteDialog = true
      this.currentRestaurant = restaurant
    },
    closeDeleteDialog() {
      this.deleteDialog = false
      this.currentRestaurant = null
    },
    openDialog(restaurant) {
      this.editDialog = true
      this.currentRestaurant = restaurant
    },
    openRestaurantDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.createDialog = false
    },
    closeDialog() {
      this.editDialog = false
      this.currentRestaurant = null
    },
    deleteRestaurant(restaurant) {
      this.$store.dispatch('deleteRestaurantById', restaurant._id);
    }
  },
  created() {
    this.$store.dispatch('fetchRestaurants', {
      page: this.options.page, 
      perPage: this.options.itemsPerPage, 
      search: ''
    });
    // this.$store.dispatch('setDev')
  }
};
</script>
<style scoped>
  .forceMarginLeft {
    margin-left: 0px
  }

  .sortWidth {
    width: 30%;
  }
</style>