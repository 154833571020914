<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Editeaza restaurant</span>
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-5" ref="form" v-model="form">
            <v-row>
              <v-col cols="4" sm="12" md="4">
                <v-row>
                  <v-col cols="12">
                    <upload-logo v-model="avatar" :setFile="setFile">
                      <div slot="activator">
                        <v-avatar size="150px" v-ripple v-if="!avatar" class="grey lighten-3 mb-3">
                          <span>Adauga logo</span>
                        </v-avatar>
                        <v-avatar size="150px" v-ripple v-else class="mb-3">
                          <img :src="avatar.imageURL" alt="avatar">
                        </v-avatar>
                      </div>
                    </upload-logo>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8" sm="12" md="8">

                <v-text-field type="text" placeholder="Nume restaurant *" label="Nume *" v-model="model.restaurantName"
                  required :rules="[v => !!v || `Completeaza numele restaurantului`]" />

                <v-textarea rows="4" type="text" placeholder="Descriere restaurant *" label="Descriere"
                  v-model="model.description" />

                <vue-tel-input :dynamicPlaceholder="true" placeholder="Numar de telefon restaurant"
                  label="Numar de telefon restaurant" defaultCountry="RO" v-model="model.phone" :onlyCountries="['RO']"
                  :validCharactersOnly="true" class="mb-5" />

                <v-text-field outlined type="text" placeholder="https://valori-nutritionale.ro" label="Site URL"
                  v-model="model.siteUrl" />

                <v-text-field outlined type="text" placeholder="https://goo.gl/maps/xxxxxxxxxx" label="Google Maps URL"
                  v-model="model.googleMapsUrl" />

                <v-text-field outlined type="text" placeholder="https://www.facebook.com/valorinutritionale"
                  label="Facebook URL" v-model="model.facebookUrl" />

                <v-text-field outlined type="text" placeholder="https://www.instagram.com/valori_nutritionale"
                  label="Instagram URL" v-model="model.instagramUrl" />
                <h3 class="padded">Personalizare meniu public</h3>
                <hr>
                <v-row>
                  <v-col cols="8" sm="12" md="6">
                    <div>
                      <v-label>Alege culoarea de baza</v-label>
                      <v-color-picker  v-model="model.bgColor"></v-color-picker>
                    </div>
                  </v-col>
                  <v-col cols="8" sm="12" md="6">
                    <div>
                      <v-label>Alege culoarea accentelor</v-label>
                      <v-color-picker  v-model="model.textColor"></v-color-picker>
                    </div>
                  </v-col>
                </v-row>
              
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import UploadLogo from '../Form/UploadLogo.vue';
export default {
  components: { UploadLogo },
  props: [
    "restaurant",
    "closeDialog",
  ],
  data: () => ({

    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {
      textColor: '#74F2CA',
      bgColor: '#0A5DF8',
    },
    form: {},
    avatar: null,
    saving: false,
    saved: false,
    phone: null
  }),
  watch: {
    avatar: {
      handler: function () {
        this.saved = false
      },
      deep: true
    },

  },
  methods: {
    setFile(data) {
      this.file = data.file

    },
    close() {
      this.closeDialog()

    },
    save() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.file) {
        this.$store.dispatch('uploadLogo', { restaurantId: this.model._id, file: this.file });
      }
      this.$store.dispatch('updateRestaurant', {
        model: this.model, cb: () => {
          this.$store.dispatch('fetchRestaurants', { page: 1, perPage: 25 });
        }
      });

      this.closeDialog()
    },
  },
  computed: {
  },
  created() {
    this.model = this.restaurant

    if (this.restaurant && this.restaurant.logo) {
      this.avatar = {
        imageURL: this.restaurant.logo.signedUrl
      }
    }
  }
};
</script>