<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga Restaurant</span>
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-5" ref="form">
            <div>
              <v-autocomplete label="Alege compania *" v-model="company"
                :items="companies" color="blue-grey-lighten-2" 
                item-text="companyName"
                item-value="vatId"
                :rules="[v => !!v || `Alege compania`]"
                item 
                outlined 
                return-object
                >
                </v-autocomplete>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Adauga</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "closeDialog",
    "companies",
    "userName",
    "email"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    // model: {},
    company: null
  }),
  watch: {

  },
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      let url = `https://valori-nutritionale.ro/adauga-restaurant/?nume=${this.userName}&email=${this.email}&cif=${this.company.vatId}`
      window.open(url, "_blank");
    },
  },
  computed: {
  },
  created() {
    this.company = this.companies ? this.companies[0] : null

  }
};
</script>