<template>
  <div>

    <router-link v-if="!external"
      :to="destination"
      :class="`${
        active ? 'bg-contrast ' : ''
      } text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-contrast sm:ml-6 fullHeight grid`"
      aria-current="page"
      > <span class="navBarText">{{ label }}</span>
      </router-link
    >
    <a v-if="external"
      :href="destination"
      :class="`${
        active ? 'bg-contrast ' : ''
      } text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-contrast sm:ml-6 fullHeight grid`"
      aria-current="page"
      > <span class="navBarText">{{ label }}</span>
      </a
    >
  </div>
</template>
<script>
  export default {
    props: ['label', 'active', 'destination','external'],
  };
</script>
