<template>
  <auth-layout headline="Configurari">
    <!-- <div>Hi there, {{ $store.state.auth.user.name }}</div> -->

    <!-- <recipe-layout
    /> -->
    <div  v-for="(item, i) in links" :key="i">
      <router-link
        :to="item.destination"
        :class="`${
          item.active ? 'bg-contrast' : ''
        } text-white block px-3 py-2 rounded-md text-base font-large hover:bg-contrast confItem`"
        aria-current="page"
        >{{ item.label }}
      </router-link>
    </div>
    
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';

  export default {
    data() {
      return {
        headline: 'Dashboard',
        links: [
          {
            label: 'Categorii de retete',
            destination: '/categories',
            active: this.$route.name === 'Categorii de retete',
          },
          {
            label: 'Ingrediente',
            destination: '/ingredients',
            active: this.$route.name === 'Ingrediente',
          },
       
        ],

      };
    },
    components: { AuthLayout, PrimaryButton },
    computed: {
      company() {
        return this.$store.state.auth.user.company
      }
    },
    created() {

    }
  };
</script>
