import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    // products: [],
    // productsInRecipes: [],
    // userProducts: [],
    // product:{},
    // productCategories: [],
    // totalproducts: 0,
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    // SET_PRODUCTS(state, products) {
    //   state.products = products
    // },
    // SET_PRODUCTS_IN_RECIPES(state, products) {
    //   state.productsInRecipes = products
    // },
    // ADD_PRODUCT(state, product) {
    //   state.products = [product, ...state.products]
    // },
    // SET_USER_PRODUCTS(state, userProducts) {
    //   state.userProducts = userProducts

    //   // userProducts.map(r => {
    //   //   if(!state.userProducts.find(ingr => ingr._id == r._id)) {
    //   //     state.userProducts.push(r)
    //   //   }
    //   // })
    // },
    // SET_TOTAL_PRODUCTS(state, total) {
    //   state.totalProducts = total
    // },
    // ADD_USER_PRODUCT(state, product) {
    //   state.userProducts = [product, ...state.userProducts]
    // },
    // EDIT_USER_PRODUCT(state, product) {
    //   state.userProducts = state.userProducts.map(r => {
    //     if(r._id == product._id) {
    //       r = {...product}
    //     }
    //     return r
    //   })
    // },
    // DELETE_USER_PRODUCT(state, product) {
    //   state.userProducts = state.userProducts.filter(r => {
    //     return (r._id != product._id)
    //   })
    // },
    // SET_PRODUCT_CATEGORIES(state, productCategories) {
    //   state.productCategories = productCategories
    // },

  },

  //===========================
  // ACTIONS
  //===========================
  actions: {
    // async fetchProductCategories({ commit }, restaurantId) {
    //   try {
    //     // Send api request.
    //     let url = `${process.env.VUE_APP_API_URL}/categories`
    //     if (restaurantId) {
    //       url = url + `/?restaurantId=${restaurantId}`
    //     }
    //     const res = await axios.get(
    //       url,
    //       {
    //         withCredentials: true,
    //       },
    //     );

    //     if (res.status === 200) {
    //       commit('SET_PRODUCT_CATEGORIES', res.data);

    //       return true;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     return
        
    //   }
    // },

    // async fetchProducts({ commit }, options) {
    //   let search = options.search
    //   let restaurantId = options.restaurantId
    //   try {
    //     // Send api request.
    //     let url =  `${process.env.VUE_APP_API_URL}/recipes`
    //     if(search && search.length) {
    //       url =`${url}/?search=${search}`
    //     }
    //     if(restaurantId && restaurantId.length) {
    //       url = search && search.length ? `${url}&restaurantId=${restaurantId}` : `${url}/?restaurantId=${restaurantId}`
    //     }
    //     const res = await axios.get(
    //       url,
    //       {
    //         withCredentials: true,
    //       },
    //     );
        
    //     if (res.status === 200) {
    //       commit('SET_PRODUCTS', res.data);
          
    //       return true;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     return

    //   }
    // },

    // async fetchProductsInRecipes({ commit }, options) {
    //   let search = options.search
    //   let restaurantId = options.restaurantId
    //   try {
    //     // Send api request.
    //     let url =  `${process.env.VUE_APP_API_URL}/product/inRecipes`
    //     if(search && search.length) {
    //       url =`${url}/?search=${search}`
    //     }
    //     if(restaurantId && restaurantId.length) {
    //       url = search && search.length ? `${url}&restaurantId=${restaurantId}` : `${url}/?restaurantId=${restaurantId}`
    //     }
    //     const res = await axios.get(
    //       url,
    //       {
    //         withCredentials: true,
    //       },
    //     );
        
    //     if (res.status === 200) {
    //       commit('SET_PRODUCTS_IN_RECIPES', res.data);
          
    //       return true;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     return

    //   }
    // },

    // async fetchUserProducts({ commit }, options) {
    //   let search = options ? options.search : ''
    //   let page = options ? options.page : 1
    //   let perPage = options ? options.perPage : 25
    //   let restaurantId = options ? options.restaurantId : null
    //   try {
    //     // Send api request.
    //     let url =  `${process.env.VUE_APP_API_URL}/recipes`
    //     url = url + `/?page=${page}&perPage=${perPage}`
    //     if(search && search.length) {
    //       url =`${url}&search=${search}`
    //     }

    //     if(restaurantId && restaurantId.length) {
    //       url =`${url}&restaurantId=${restaurantId}`
    //     }
       
    //     const res = await axios.get(
    //       url,
    //       {
    //         withCredentials: true,
    //       },
    //     );
        
    //     if (res.status === 200) {
    //       commit('SET_USER_PRODUCTS', res.data.products);
    //       commit('SET_TOTAL_PRODUCTS', res.data.total);
          
    //       return true;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     return

    //   }
    // },

    // async deleteProductById({ commit }, data) {
    //   let id = data.id
    //   let restaurantId = data.restaurantId
    //   try {
    //     let url = `${process.env.VUE_APP_API_URL}/product/${id}`
    //     if(restaurantId && restaurantId.length) {
    //       url = url + `/?restaurantId=${restaurantId}`
    //     }
    //     const res = await axios.delete(
    //       url,
    //       {
    //         withCredentials: true,
    //       },
    //     );
        
    //     if (res.status === 200) {
    //       commit('DELETE_USER_PRODUCT', res.data);
    //       toastr.success("Produsul a fost sters!")
    //       return true;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     return
        
    //   }
    // },

    async createProduct({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.post(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status == 201) {
          // commit('ADD_PRODUCT', res.data);
          // commit('ADD_USER_PRODUCT', res.data);
          toastr.success("Produsul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      if(cb) {
        cb()
      }
    },


    async createProducts({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/products/multiple`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.post(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status == 201) {
          // commit('ADD_PRODUCT', res.data);
          // commit('ADD_USER_PRODUCT', res.data);
          toastr.success("Produsul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      if(cb) {
        cb()
      }
    },

    async updateProduct({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/products`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          // commit('EDIT_USER_PRODUCT', res.data);
          toastr.success("Produsul a fost modificat!")
          
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      if(cb) {
        cb()
      }
    },

   
  },
};
