import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATEI
  //===========================
  state: {
    user: {},
    loadingUser: true,
    isAuthenticated: false,
    access_token: '',
    error: {},
    userImp: {},
    restaurant: {},
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      state.isAuthenticated = true;
      state.loadingUser = false;
      state.error = {};
    },
    SET_USER_IMP(state, userImp) {
      state.userImp = userImp
    },
    UNSET_USER_IMP(state) {
      state.userImp = {}
    },
    SET_RESTAURANT(state, restaurant) {
      state.restaurant = restaurant
    },
    UNSET_RESTAURANT(state) {
      state.restaurant = {}
    },
    SET_ACCESS_TOKEN(state, token = false) {
      if (token) {
        window.localStorage.setItem('access_token', token);
        return;
      }
      window.localStorage.removeItem('access_token');
      state.access_token = token;
    },
    SET_USER_ERROR_OR_LOGOUT(state, error) {
      state.error = error;
      state.user = {};
      state.isAuthenticated = false;
      state.loadingUser = false;
      state.access_token = '';
    },
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {

    async updateUser({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/auth`,
          model,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          toastr.success("Informatiile au fost actualizate!")
          cb()
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async fetchUser({ commit }) {
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/auth/user`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_USER', res.data);
          commit(
            'SET_ACCESS_TOKEN',
            window.localStorage.getItem('access_token'),
          );
          return true;
        }
      } catch (error) {
        if (error?.response?.data) {
          commit('SET_USER_ERROR_OR_LOGOUT', { ...error.response.data, area: 'fetchUser' });
          return false;
        }
        commit('SET_USER_ERROR_OR_LOGOUT', {
          error: 'Server error',
          message: 'Sorry, something went wrong',
          statusCode: 500,
          area: 'fetchUser',
        })

      }
    },

    async exitImp({commit}) {
      commit('UNSET_USER_IMP');
    },

    async exitRestaurant({commit}) {
      commit('UNSET_RESTAURANT');
    },

    async fetchUserById({ commit }, id) {
      if(!id || !id.length) {
        commit('UNSET_USER_IMP');
        return
      }
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/auth/userById/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_USER_IMP', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async fetchUserByRestaurantId({ commit }, id) {
      if(!id || !id.length) {
        commit('UNSET_USER_IMP');
        return
      }
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/auth/userByRestaurantId/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_USER_IMP', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async fetchRestaurantById({ commit }, id) {
      if(!id || !id.length) {
        commit('UNSET_RESTAURANT');
        return
      }
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_RESTAURANT', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async register({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/register`,
          // {
          //   name: formData.name,
          //   email: formData.email,
          //   password: formData.password,
          // },
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/`;
        }
      } catch (error) {
        if (error.response.data) {
          commit('SET_USER_ERROR_OR_LOGOUT', {
            ...error.response.data,
            area: 'register',
          });
          return this.error;
        }
        commit('SET_USER_ERROR_OR_LOGOUT', {
          error: 'Server error',
          message: 'Sorry, something went wrong',
          statusCode: 500,
          area: 'register',
        });
        return this.error;
      }
    },

  
    async login({ commit }, { email, password }) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/login`,
          { email, password },
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/`;
        }
      } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
          commit('SET_USER_ERROR_OR_LOGOUT', {
            ...error.response.data,
            area: 'login',
          });
          return this.error;
        }
        commit('SET_USER_ERROR_OR_LOGOUT', {
          error: 'Server error',
          message: 'Sorry, something went wrong',
          statusCode: 500,
          area: 'login',
        });
        return this.error;
      }
    },

    async logout({ commit }) {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/auth/logout`, {
        withCredentials: true,
      });

      commit('SET_USER_ERROR_OR_LOGOUT');
    },
  },
};
