var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.isEdit ? 'Editeaza' : 'Adauga')+" introducator retete")])]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"customFlex"},[_c('div',{staticClass:"padded w-half m-5"},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Nume *","required":"","label":"Nume *","rules":[
                  function (v) { return !!v || "Completeaza numele userului"; },
                  function (v) { return v && v.length <= 25 || "Numele trebuie sa contina cel mult 25 de caractere"; }
                ],"outlined":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('div',{staticClass:"padded w-half m-5"},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Email *","required":"","label":"Email *","disabled":_vm.isEdit,"rules":[
                  function (v) { return !!v || "Completeaza email-ul userului"; } ],"outlined":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})],1),(!_vm.isEdit)?_c('div',{staticClass:"padded w-half m-5"},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Parola *","required":"","label":"Parola *","rules":[
                  function (v) { return !!v || "Completeaza parola userului"; } ],"outlined":""},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1):_vm._e()]),_c('div',{staticClass:"padded w-full m-5"},[_c('v-autocomplete',{attrs:{"d-flex":"","label":"Selecteaza restaurante","hide-selected":"","text-color":"white","items":_vm.restaurantsToManage,"item-text":"restaurantName","item-value":"_id","outlined":"","multiple":"","item":"","small-chips":"","clearable":true,"deletable-chips":true,"search-input":_vm.searchRestaurant,"no-filter":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchRestaurant=$event},"update:search-input":function($event){_vm.searchRestaurant=$event},"change":function($event){_vm.searchRestaurant=''}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":data.selected,"color":"warning","text-color":"white","close":""},on:{"click:close":function($event){return _vm.removeRestaurant(data.item)}}},'v-chip',attrs,false),on),[_c('strong',[_vm._v(_vm._s(data.item.restaurantName))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.item.restaurantName))])])]}}]),model:{value:(_vm.model.managesRestaurants),callback:function ($$v) {_vm.$set(_vm.model, "managesRestaurants", $$v)},expression:"model.managesRestaurants"}})],1)])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mainButtonYes",attrs:{"type":"button","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Salveaza")]),_c('v-btn',{staticClass:"mainButtonNo",attrs:{"type":"button","color":"red"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Renunta")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }