<template>
  <v-expansion-panel-content>
    <v-list v-for="(item, j) in items" :key="j">
      <v-list-item :class="itemClass || 'itemToTransfer'">
        <div :class="'flex padded ml-5'">
          <v-checkbox class="recipeActions" style="align-items: center;" :input-value="itemIsSelected(item)"
            @click="selectItem(item)" :disabled="disabled ? disabled(item) : false"></v-checkbox>
          <label class="label">{{item.name || item}} </label>
        </div>
      </v-list-item>
    </v-list>
  </v-expansion-panel-content>
</template>

<script>
  export default {
    props: [
      "items",
      "itemIsSelected",
      "selectItem",
      "itemClass",
      "disabled"
    ],

    methods: {

      
    }
  };
</script>
<style scoped>
  .itemToTransfer {
    height: 15px
  }
  .padded {
    padding: 10px
  }
</style>