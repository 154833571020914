<template>
  <auth-layout>
    <recipe-layout 
    />
  </auth-layout>
</template>
<script>
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import RecipeLayout from '../Layouts/RecipeLayout.vue'

  export default {
    data() {
      return {
      };
    },
    components: { AuthLayout, RecipeLayout },
    computed: {
      isAdmin() {
        return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
      },
      userImpId() {
        return this.$route.query.userId
      },
      restaurantId() {
        let id = this.$route.query.restaurantId
        if((!id || !id.length) && this.onlyOneRestaurant) {
          id = this.$store.state.restaurants.restaurants[0]._id
        }
        return id
      },
      onlyOneRestaurant() {
        return this.$store.state.restaurants.realTotalRestaurants == 1
      },
    },
    watch: {
      restaurantId() {
        this.getData()
      }
    },
    methods: {
      getData() {
        if (this.restaurantId) {
          this.$store.dispatch('fetchUserByRestaurantId', this.restaurantId);
          this.$store.dispatch('fetchRestaurantById', this.restaurantId);
        }
      }
    },
    created() {
      this.getData()
    }
  };
</script>
