<template>
  <!-- <div class="tableHeader ml-5 mt-7"> -->
    <v-data-table
      :hide-default-footer="true"
      :items="payments"
      :headers="headers"
    >
      <template v-slot:[`item.startDate`]="props">
        {{parseDate(props.item.startDate)}}
      </template>
      <template v-slot:[`item.endDate`]="props">
        {{parseDate(props.item.endDate)}}
      </template>
      <template v-slot:[`item.paidByOP`]="props">
        <v-icon color="primary" v-if="props.item.paidByOP">mdi-check</v-icon>
      </template>
   </v-data-table>

  <!-- </div> -->
</template>

<script>
import SectionHeadline from '../Typography/SectionHeadline.vue';
import Paragraph from '../Typography/Paragraph.vue';

import moment from "moment-timezone";

export default {
  components: {
    Paragraph,
    SectionHeadline
  },
  emits: ['confirmationAction', 'cancelAction'],
  props: {
    payments: [Object]
  },
  data() {
    return {
      
    }
  },
  computed: {

    hasOP() {
      return this.payments.filter(p => p.paidByOP).length
    },
    headers() {
      let paymentHeaders =  [
        {
          text: "Plan",
          value: 'productId'
        },
        {
          text: "Start",
          value: 'startDate'
        },
        {
          text: "End",
          value: 'endDate'
        },
        {
          text: "Operation",
          value: 'operation'
        },
        {
          text: "O.P.",
          value: 'paidByOP'
        }
      ]
      if(!this.hasOP) {
        paymentHeaders = paymentHeaders.filter(p => p.value != 'paidByOP')
      }
      if(this.payments.length < 2) {
        paymentHeaders = paymentHeaders.filter(p => p.value != 'operation')
      }
      return paymentHeaders
    }
  },
  methods: {
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  }
};
</script>

