<template>
  <div>
    <!-- Header -->
    <div>
      <slot name="header"></slot>
    </div>

    <!-- Main -->
    <div
      class="
      content--container  mx-auto mt-16 pt-8 leading-6 w-full min-h-screen lg:px-16
      max-w-8xl mx-auto sm:px-1 md:px-8 lg:px-8
      lightBackground
      "
    >
    <v-card>
        <v-card-title>
          <main-headline v-if="headline" :label="headline"></main-headline>
        </v-card-title>
    
      <slot></slot>
      <!-- <footer>
        <cookie-law theme="dark-lime"></cookie-law>
      </footer> -->
      </v-card>
      <footer text-center class="text-center mt-5">
        <a target="_blank" href="https://infocons.ro/kit-numere-utile/">Descarca documentele InfoCons pe care esti obligat sa le ai in locatie</a>
        <p>Copyright 2023 - Valori Nutritionale</p>
        
      </footer>
    </div>
  </div>
</template>

<script>
  import MainHeadline from './../components/Typography/MainHeadline.vue';
  // import CookieLaw from 'vue-cookie-law'

  export default {
    components: {
      MainHeadline
    },
    props: ['headline'],
  };
</script>
