import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    unitMeasureNames: [],
    unitMeasures: []
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {

    SET_UNIT_MEASURES(state, unitMeasures) {
      state.unitMeasures = unitMeasures
    },
    DELETE_UNIT_MEASURE(state, unitMeasure) {
      state.unitMeasures = state.unitMeasures.filter(r => {
        return (r._id != unitMeasure._id)
      })
    },
    ADD_UNIT_MEASURE(state, unitMeasure) {
      state.unitMeasures.unshift(unitMeasure)
    },
    UPDATE_UNIT_MEASURE(state, unitMeasure) {
      state.unitMeasures = state.unitMeasures.map(r => {
        if(r._id == unitMeasure._id) {
          r = {...unitMeasure}
        }
        return r
      })
    },

    SET_UNIT_MEASURE_NAMES(state, unitMeasuresNames) {
      state.unitMeasureNames = unitMeasuresNames
    },
    DELETE_UNIT_MEASURE_NAME(state, unitMeasuresName) {
      state.unitMeasureNames = state.unitMeasureNames.filter(r => {
        return (r._id != unitMeasuresName._id)
      })
    },
    ADD_UNIT_MEASURE_NAME(state, unitMeasureName) {
      state.unitMeasureNames.unshift(unitMeasureName)
    },
    UPDATE_UNIT_MEASURE_NAME(state, unitMeasureName) {
      state.unitMeasureNames = state.unitMeasureNames.map(r => {
        if(r._id == unitMeasureName._id) {
          r = {...unitMeasureName}
        }
        return r
      })
    },
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {



    async fetchUnitMeasures({ commit }) {
        try {
          // Send api request.
          const res = await axios.get(
            `${process.env.VUE_APP_API_URL}/unitMeasures`,
            {
              withCredentials: true,
            },
          );
          // Put user into store.
          if (res.status === 200) {
            commit('SET_UNIT_MEASURES', res.data);
            
            return true;
          }
        } catch (error) {
          console.log(error)
          return
          
        }
    },

    async createUnitMeasure({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/unitMeasures`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status == 201) {
          toastr.success("Campul a fost adaugat!")
          commit('ADD_UNIT_MEASURE', res.data);
          
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateUnitMeasure({ commit }, formData) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/unitMeasures`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status == 200) {
          commit('UPDATE_UNIT_MEASURE', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async deleteUnitMeasureById({ commit }, id) {
      try {
        // Send api request.
        const res = await axios.delete(
          `${process.env.VUE_APP_API_URL}/unitMeasures/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status == 200) {
          commit('DELETE_UNIT_MEASURE', res.data);
          toastr.success("Campul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },



    async fetchUnitMeasureNames({ commit }) {
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/unitMeasureNames`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit('SET_UNIT_MEASURE_NAMES', res.data);
          
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },

    async createUnitMeasureName({ commit }, formData) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL}/unitMeasureNames`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status == 201) {
          commit('ADD_UNIT_MEASURE_NAME', res.data);
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateUnitMeasureName({ commit }, formData) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_API_URL}/unitMeasureNames`,
          formData,
          {
            withCredentials: true,
          },
        );

        if (res.status == 200) {
          commit('UPDATE_UNIT_MEASURE_NAME', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async deleteUnitMeasureNameById({ commit }, id) {
      try {
        // Send api request.
        const res = await axios.delete(
          `${process.env.VUE_APP_API_URL}/unitMeasureNames/${id}`,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status == 200) {
          commit('DELETE_UNIT_MEASURE_NAME', res.data);
          toastr.success("Campul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },
  },
};
