<template>
  <!-- Desktop Menu -->
  <nav class="bg-primary">

    <v-navigation-drawer v-model="sidebar" app style="width:80%">
      <v-row class="fill-height" no-gutters>
        <v-navigation-drawer mini-variant mini-variant-width="56" permanent color="accent">
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img :src="logo ? logo : $store.state.auth.user.avatar"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>

            <v-list-item :to="'/profile'">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-list class="grow">
          <v-list-item v-for="link in flattenArr" :key="link.destination" link :to="link.destination">
            <v-list-item-title v-text="link.label"></v-list-item-title>
          </v-list-item>

        </v-list>
      </v-row>

    </v-navigation-drawer>


    <v-app-bar app class="accent text-white" elevate-on-scroll>
      <span class="hidden-sm-and-up">
        <v-btn @click="sidebar = !sidebar" icon color="white">
          <v-icon>mdi-hamburger</v-icon>
        </v-btn>
      </span>

      <router-link to="/" tag="span" style="cursor: pointer" class="text-white" color="white">
        <img class="w-auto hidden-xs-only" src="../../assets/valori-nutritionale-logoArtboard 2@4x.png"
          alt="Valori Nutritionale" style="
    max-height: 60px;
" />
      </router-link>
      <v-app-bar-title class="text-white extraFlex">

        <router-link v-if="isAdmin" to="/" tag="span" style="cursor: pointer" class="text-white min-w200" color="white">

          {{ isAdmin? `ADMIN - ${(restaurant.restaurantName) ? restaurant.restaurantName : (user.name
          ? user.name : '')}` : displayName}}
        </router-link>
        <v-menu text offset-y v-if="item.children && !isAdmin" v-for="item in restaurantDropdown" :key="item.destination">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="text-white" color="white" >
              {{ item.label }} <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(it, index) in item.children" :key="index">
              <v-btn block :to="it.destination" text>{{ it.label }}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn v-for="item in links" :key="item.label" :to="!item.children ? item.destination : ''" text
           v-if="!item.children" class="text-white" color="white">
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.label }}
        </v-btn>
        <v-menu text offset-y v-if="item.children && item.children.length" v-for="item in links" :key="item.destination">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="text-white" color="white">
              {{ item.label }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(it, index) in item.children" :key="index">
              <v-btn block :to="it.destination" text>{{ it.label }}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <profile-menu v-if="
          !$store.state.auth.loadingUser && $store.state.auth.isAuthenticated
        " :logo="logo ? logo : $store.state.auth.user.avatar"></profile-menu>
      </v-toolbar-items>
    </v-app-bar>

  </nav>
</template>

<script>
import MobileNavbarItem from './Navbar/MobileNavbarItem.vue';
import NavbarItem from './Navbar/NavbarItem.vue';
import BurgerButton from '../Buttons/BurgerButton.vue';
import ProfileMenu from './Navbar/ProfileMenu/ProfileMenu.vue';

export default {
  // ======================
  // DATA
  // ======================
  data() {
    return {
      showMobileMenu: false,
      showProfileMenu: false,
      sidebar: false
    };
  },
  // ======================
  // Components
  // ======================
  components: {
    NavbarItem,
    MobileNavbarItem,
    BurgerButton,
    // ProfileMenu,
    ProfileMenu,
  },
  // watch: {
  //   isAdmin()
  // },
  computed: {
    company() {
      return this.$store.state.auth.user.company
    },
    user() {
      return this.$store.state.auth.user
    },
    totalRestaurants() {
      this.$store.state.restaurants.totalRestaurants
    },
    restaurant() {
      let restaurants = this.$store.state.restaurants.restaurants
      let defaultRestaurant = this.onlyOneRestaurant ? restaurants[0] : {}
      return this.$store.state.auth.restaurant.restaurantName ? this.$store.state.auth.restaurant : defaultRestaurant
    },
    logo() {
      return this.restaurant && this.restaurant.logo && this.restaurant.logo.signedUrl ? this.restaurant.logo.signedUrl : null
    },
    restaurantId() {
      let id = this.$route.query.restaurantId
      if((!id || !id.length) && this.onlyOneRestaurant) {
        id = this.$store.state.restaurants.restaurants[0]._id
      }
      return id
    },
    onlyOneRestaurant() {
      return this.$store.state.restaurants.realTotalRestaurants == 1
    },
    displayName() {
      return this.restaurant && this.restaurant.restaurantName ? this.restaurant.restaurantName : (this.user ? this.user.name : '')
    },  
    userImp() {
      return this.$store.state.auth.userImp
    },
    userImpId() {
      return this.$route.query.userId
    },
    impRestaurantName() {
      return this.userImp && this.userImp.company ? this.userImp.company.restaurantName : ''
    },
    username() {
      return this.$store.state.auth.user.name
    },
    isAdmin() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
    },
    isRecorder() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'DATA_RECORDER'
    },
    flattenArr() {
      const result = []
      this.links.map((obj) => {
        if (obj.children) {
          const el = { ...obj, ...{} }
          delete el.children
          result.push(el)
          Object.values(obj.children).map((v, i) => {
            result.push(v)
          })
        } else {
          result.push(obj)
        }
      })
      return result
    },
    links() {
      
      let restaurantConfigChildren = [
        {
          label: 'Ingrediente',
          destination: '/ingredients',
          active: this.$route.name === 'ConfigurariAdmin'
        },
      ]
      if(this.restaurant.restaurantName) {
        restaurantConfigChildren = restaurantConfigChildren.concat([
          {
            label: 'Categorii de retete',
            destination: `/categories/?restaurantId=${this.restaurant._id}`,
            active: this.$route.name === 'ConfigurariAdmin'
          },
          {
            label: 'Configurari meniu',
            destination: `/menuConfigurations/?restaurantId=${this.restaurant._id}`,
            active: this.$route.name === 'ConfigurariAdmin'
          }
        ])
      }
      let restaurantLinks = [
        // {
        //   label: 'Restaurante',
        //   destination: '/restaurants',
        //   active: this.$route.name === 'Dashboard',
        // },
        {
          label: 'Configurari',
          destination: '/configurations',
          active: this.$route.name === 'Configurari',
          children: restaurantConfigChildren
        }
      ]

      if(this.restaurantId) {
        restaurantLinks.unshift(
          {
            label: 'Retete',
            destination: `/recipes/?restaurantId=${this.restaurantId}`,
            active: this.$route.name === 'Recipes',
          },
        )
      }

      
      let configChildren = [
        {
          label: 'Nomenclatoare campuri',
          destination: '/fieldNames',
          active: this.$route.name === 'ConfigurariAdmin'
        },
        {
          label: 'Denumiri unitati de masura',
          destination: '/unitMeasureNames',
          active: this.$route.name === 'ConfigurariAdmin'
        },
        {
          label: 'Unitati de masura',
          destination: '/unitMeasures',
          active: this.$route.name === 'ConfigurariAdmin'
        },
        {
          label: 'Etichete retete',
          destination: '/tags',
          active: this.$route.name === 'ConfigurariAdmin'
        },
        
        {
          label: 'Ingrediente',
          destination: '/ingredients',
          active: this.$route.name === 'ConfigurariAdmin'
        },
        
      ]

      let configChildrenRecorder = []

      if(this.restaurant.restaurantName) {
        configChildren = configChildren.concat([
          {
          label: `Categorii de retete ${this.restaurant.restaurantName}`,
          destination: `/categories/?restaurantId=${this.restaurant._id}`,
          active: this.$route.name === 'ConfigurariAdmin'
        },
        {
          label: `Configurari meniu ${this.restaurant.restaurantName}`,
          destination: `/menuConfigurations/?restaurantId=${this.restaurant._id}`,
          active: this.$route.name === 'ConfigurariAdmin'
        }
        ])
        configChildren.push(...[
          {
            label: `Ingrediente - ${this.restaurant.restaurantName}`,
            destination: `/ingredients/?restaurantId=${this.restaurant._id}`,
            active: this.$route.name === 'ConfigurariAdmin'
          },

        ])

        configChildrenRecorder = configChildrenRecorder.concat([
          {
            label: `Categorii de retete ${this.restaurant.restaurantName}`,
            destination: `/categories/?restaurantId=${this.restaurant._id}`,
            active: this.$route.name === 'ConfigurariAdmin'
          },
          // {
          //   label: `Configurari meniu ${this.restaurant.restaurantName}`,
          //   destination: `/menuConfigurations/?restaurantId=${this.restaurant._id}`,
          //   active: this.$route.name === 'ConfigurariAdmin'
          // },
          {
            label: `Ingrediente ${this.restaurant.restaurantName}`,
            destination: `/ingredients/?restaurantId=${this.restaurant._id}`,
            active: this.$route.name === 'ConfigurariAdmin'
          },
        ])
      }

      let adminLinks = [
        {
          label: 'Restaurante',
          destination: '/dashboard',
          active: this.$route.name === 'dashboard',
        },
        {
          label: 'Introducatori Retete',
          destination: '/dashboardRecorders',
          active: this.$route.name === 'DashboardRecorders',
        },
        {
          label: 'Configurari',
          destination: '/configurationsAdmin',
          active: this.$route.name === 'ConfigurariAdmin',
          children: configChildren
        },
        {
          label: 'Transfer date',
          destination: '/transferData',
          active: this.$route.name === 'TransferData',
        }
      ]

      let recorderLinks = [
        
        {
          label: 'Restaurante',
          destination: '/dashboard',
          active: this.$route.name === 'dashboard',
        },
        {
          label: 'Configurari',
          destination: '/configurationsAdmin',
          active: this.$route.name === 'ConfigurariAdmin',
          children: configChildrenRecorder
        },
        
      ]

      if(this.restaurantId) {
        recorderLinks.push(
          {
            label: 'Retete',
            destination: `/recipes/?restaurantId=${this.restaurantId}`,
            active: this.$route.name === 'Recipes',
          },
        )
      }

      return this.isAdmin ? adminLinks : (this.isRecorder ? recorderLinks : restaurantLinks)


    },
    deactivateRestaurants(id) {

    },
    restaurantDropdown() {
      let restaurantsChildren = this.$store.state.restaurants.restaurants.map(r => {
        return {
          label: r.restaurantName,
          destination: `/recipes/?restaurantId=${r._id}`,
          active: this.$route.query.restaurantId === r._id
        }
      })

      if(!this.isRecorder) {
        restaurantsChildren.push({
          label: 'GESTIONEAZA RESTAURANTE',
          destination: '/restaurants',
          active: this.$route.name === 'Restaurants'
        })
      }
      

      let restaurantDropdown = [
        {
          label: `${this.isAdmin ? 'ADMIN - ' : ''} ${this.restaurant.restaurantName ? this.restaurant.restaurantName : this.username}`,
          destination: `/recipes`,
          children: restaurantsChildren
        }
      ]
      return restaurantDropdown
    }
  },
  // ======================
  // Methods
  // ======================
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    async logout() {
      window.localStorage.removeItem('access_token');
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },

  },
  created() {
  }
};
</script>
<style scoped>
.min-w200{
  min-width: 200px;
  display:block
}
</style>

