<template>
  <auth-layout headline="Etichete retete">
      <v-btn 
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        @click="openDialog">
        ADAUGA ETICHETA
      </v-btn>

      <create-tag
        v-if="dialog"
        :closeDialog="closeDialog"
        :model="tag"
        :saveTag="saveTag"
      />

       <v-data-table
          :headers="tagHeaders"
          :items="tags"
        >
          <template v-slot:[`item.icon`]="props">
            <div class="iconView" v-html="props.item.icon" :style="`fill: ${props.item.color}`"/>
          </template>
          <template v-slot:[`item.actions`]="props">
            <v-btn text color="accent" class="categoryActions"
              @click="openDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-pen" />
            </v-btn> 
            <v-btn text color="red" class="categoryActions"
              @click="openDeleteDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-trash-can" />
            </v-btn> 
            
          </template>
        </v-data-table>

        <delete-tag
          v-if="deleteDialog"
          :item="currentTag"
          :closeDialog="closeDeleteDialog"
          :deleteTag="deleteTag"
        />
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import CreateTag from '../components/Modals/CreateTag.vue'
  import DeleteTag from '../components/Modals/DeleteTag.vue'


  export default {
    data() {
      return {
        headline: 'Dashboard',
        dialog: false,
        deleteDialog: false,
        tag: {
          name: null,
          key: null
        },
        currentCategory: null,
        tagHeaders: [
          {
            text: "Nume",
            value: 'name'
          },
          {
            text: "Icon",
            value: 'icon'
          },
          
          {
            text: "Actiuni",
            value: 'actions'
          }
        ],

      };
    },
    components: { AuthLayout, PrimaryButton,CreateTag, DeleteTag },
    computed: {
      company() {
        return this.$store.state.auth.user.company
      },
      tags() {
        return this.$store.state.tags.tags
      },
    },
    methods: {
      openDialog(tag) {
        this.dialog = true
        if(tag) {
          this.tag = tag
        }
      },
      closeDeleteDialog() {
        this.deleteDialog = false
      },

      openDeleteDialog(tag) {
        this.currentTag = tag
        this.deleteDialog = true
      },
      deleteTag(tag) {
        this.currentTag = null
        this.$store.dispatch('deleteTagById', tag._id);
      },
      closeDialog() {
        this.dialog = false
      },
      saveTag(model) {
        if(model._id) {
          this.$store.dispatch('updateTag', model);
        } else {
          this.$store.dispatch('createTag', model);
        }
        
        this.tag = {
          name: null,
          key: null
        }
      },
    },
    created() {
      this.$store.dispatch('fetchTags');
      this.$store.dispatch('exitImp');
    }
  };
</script>

<style scoped>
  .iconView {
    height: 25px;
    justify-content: center;
    display: flex;
  }
</style>
