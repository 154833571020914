<template>
  <div class="extraFlex">
    <div class="ml-3 relative">

      <v-menu offset-y text>
        <template v-slot:activator="{ attrs, on }">
          <span v-bind="attrs" v-on="on" text>
            <profile-icon v-bind="attrs" v-on="on" :logo="logo"></profile-icon>
          </span>
        </template>

        <v-list dense nav>

          <v-list-item :to="'/profile'">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="">
            <a :href="termsLink" target="_blank" class="forceLeft v-list-item v-list-item--link theme--light">
              <v-list-item-action>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Termeni si conditii</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>

          <v-list-item>
            <a :href="helpLink" target="_blank" class="forceLeft v-list-item v-list-item--link theme--light">
              <v-list-item-action>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Help</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>
          <v-list-item>
            <a :href="subscriptionManage" target="_blank" class="forceLeft v-list-item v-list-item--link theme--light">
              <v-list-item-action>
                <v-icon>mdi-folder-check-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Gestioneaza abonament</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

    </div>
  </div>
</template>

<script>
import ProfileIcon from './ProfileIcon.vue';
export default {
  data() {
    return {
      termsLink: 'https://valori-nutritionale.ro/termeni-si-conditii/',
      helpLink: 'https://valorinutritionale.tawk.help/',
      subscriptionManage: 'https://billing.stripe.com/p/login/9AQeY58JJ0Zo0YUfYY',
      showProfileMenu: false,
      links: [
        {
          label: 'Profile',
          destination: '/profile',
          active: this.$route.name === 'Profile',
        },
        // {
        //   label: 'Community',
        //   destination: '/community',
        //   active: this.$route.name === 'Community',
        // },
      ],
    };
  },
  props: ['src', "logo"],
  components: {
    ProfileIcon,
  },
  methods: {
    async logout() {
      window.localStorage.removeItem('access_token');
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    toggleShowProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
  },
};
</script>
<style>
.extraFlex {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}
</style>