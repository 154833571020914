<template>
  <auth-layout>
    <restaurants-layout v-if="!onlyOneRestaurant || restaurantsView"
    />
    <recipe-layout v-else/>
  </auth-layout>
</template>
<script>
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import RecipeLayout from '../Layouts/RecipeLayout.vue'
  import RestaurantsLayout from '../Layouts/RestaurantsLayout.vue'

  export default {
    data() {
      return {
      };
    },
    components: { AuthLayout, RecipeLayout, RestaurantsLayout },
    computed: {
      isAdmin() {
        return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
      },
      restaurantId() {
        let id = this.$route.query.restaurantId
        if((!id || !id.length) && this.onlyOneRestaurant) {
          id = this.$store.state.restaurants.restaurants[0]._id
        }
        return id
      },
      restaurantsView() {
        return this.$route.path.includes('restaurants')
      },
      onlyOneRestaurant() {
        return this.$store.state.restaurants.realTotalRestaurants == 1
      },

    },
    watch: {
      restaurantId() {
        this.getData()
      }
    },
    methods: {
      getData() {
        if (this.restaurantId) {
          this.$store.dispatch('fetchRestaurantById', this.restaurantId);
        } 
        // else if(this.onlyOneRestaurant) {
        //   let firstRestaurant = this.$store.state.restaurants.restaurants[0]
        //   this.$store.dispatch('fetchRestaurantById', firstRestaurant._id);
        // }
      }
    },
    created() {
      // this.$store.dispatch('fetchRestaurants');
      if(!this.onlyOneRestaurant) {
        this.$store.dispatch('exitRestaurant');
      }
      
      
      this.getData()
    }
  };
</script>
