<template>
  <v-btn
    :loading="loading"
    @click="onClick"
    type="button"
    class="mainButton"
    color="accent"
  >
    <slot></slot>
  </v-btn>
</template>
<script>

// hover:bg-gray-700 
  //     active:bg-gray-900 
  //     focus:border-gray-900 
  //     focus:ring-gray-300 
  //     text-white
  //     flex items-center 
  //     justify-center px-4 py-2 
  //     border border-transparent 
  //     rounded-md font-semibold 
  //     text-xs uppercase
  //     focus:outline-none focus:ring 
  //     disabled:opacity-25 relative
  import ButtonBase from './ButtonBase.vue';
  export default {
    components: {
      ButtonBase,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
        onClick: Function
      },
    },
  };
</script>
