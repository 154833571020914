import Vue from "vue";
import Vuex from "vuex";

import AuthStore from './modules/auth';
import RecipesStore from './modules/recipes';
import RestaurantsStore from './modules/restaurants';
import UnitMeasuresStore from './modules/unitMeasures'
import IngredientsStore from './modules/ingredients'
import ProductsStore from './modules/products'
import UsersStore from './modules/users'
import TransferStore from './modules/transfer'
import TagsStore from './modules/tags'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthStore,
    recipes: RecipesStore,
    restaurants: RestaurantsStore,
    unitMeasures: UnitMeasuresStore,
    ingredients: IngredientsStore,
    products: ProductsStore,
    users: UsersStore,
    transfer: TransferStore,
    tags: TagsStore
  }
})
