const UnitMeasures = {
  GRAMS : "g",
  KCAL : "Kcal",
  KJ : "Kj",
}

const fields = {
  ENERGY: 'energy',
  ENERGY_KJ: 'energyKJ',
  FAT: 'fat',
  SATURATED_DAT: 'saturated_fat',
  CARBOHYDRATES: 'carbohydrates',
  SUGARS: 'sugars',
  PROTEIN: 'proteins',
  FIBER: 'fibers',
  SODIUM: 'sodium',
}

const MapUnitMeasures = {
  [fields.ENERGY]: UnitMeasures.KCAL,
  [fields.ENERGY_KJ]: UnitMeasures.KJ,
  [fields.FAT]: UnitMeasures.GRAMS,
  [fields.SATURATED_DAT]: UnitMeasures.GRAMS,
  [fields.CARBOHYDRATES]: UnitMeasures.GRAMS,
  [fields.SUGARS]: UnitMeasures.GRAMS,
  [fields.PROTEIN]: UnitMeasures.GRAMS,
  [fields.FIBER]: UnitMeasures.GRAMS,
  [fields.SODIUM]: UnitMeasures.GRAMS,
}

const nutriKeys = [
  'energy', 'energyKJ', 'fat', 'saturated_fat', 
  'carbohydrates', 'sugars', 'proteins', 'sodium'
]

export {MapUnitMeasures, nutriKeys}