import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    ingredients: [],
    ingredientsInRecipes: [],
    userIngredients: [],
    ingredientCategories: [],
    totalIngredients: 0,
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_INGREDIENTS(state, ingredients) {
      state.ingredients = ingredients
    },
    SET_INGREDIENTS_IN_RECIPES(state, ingredients) {
      state.ingredientsInRecipes = ingredients
    },
    ADD_INGREDIENT(state, ingredient) {
      state.ingredients = [ingredient, ...state.ingredients]
    },
    SET_USER_INGREDIENTS(state, userIngredients) {
      state.userIngredients = userIngredients

      // userIngredients.map(r => {
      //   if(!state.userIngredients.find(ingr => ingr._id == r._id)) {
      //     state.userIngredients.push(r)
      //   }
      // })
    },
    SET_TOTAL_INGREDIENTS(state, total) {
      state.totalIngredients = total
    },
    ADD_USER_INGREDIENT(state, ingredient) {
      state.userIngredients = [ingredient, ...state.userIngredients]
    },
    EDIT_USER_INGREDIENT(state, ingredient) {
      state.userIngredients = state.userIngredients.map(r => {
        if(r._id == ingredient._id) {
          r = {...ingredient}
        }
        return r
      })
    },
    DELETE_USER_INGREDIENT(state, ingredient) {
      state.userIngredients = state.userIngredients.filter(r => {
        return (r._id != ingredient._id)
      })
    },
    SET_INGREDIENT_CATEGORIES(state, ingredientCategories) {
      state.ingredientCategories = ingredientCategories
    },

  },

  //===========================
  // ACTIONS
  //===========================
  actions: {
    async fetchIngredientCategories({ commit }) {
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/ingredient/categories`,
          {
            withCredentials: true,
          },
        );
        
        if (res.status === 200) {
          commit('SET_INGREDIENT_CATEGORIES', res.data);
          
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },

    async fetchIngredients({ commit }, options) {
      let search = options.search
      let restaurantId = options.restaurantId
      let abortController = options.abortController
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/ingredient`
        if(search && search.length) {
          url =`${url}/?search=${search}`
        }
        if(restaurantId && restaurantId.length) {
          url = search && search.length ? `${url}&restaurantId=${restaurantId}` : `${url}/?restaurantId=${restaurantId}`
        }

        let options = {withCredentials: true}
        if(abortController) {
          options.signal = abortController.signal
        }
        const res = await axios.get(
          url,
          options
        );
        
        if (res.status === 200) {
          commit('SET_INGREDIENTS', res.data);
          
          return true;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error(error.message);
        } 
        console.log(error)
        return

      }
    },

    async fetchIngredientsInRecipes({ commit }, options) {
      let search = options.search
      let restaurantId = options.restaurantId
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/ingredient/inRecipes`
        if(search && search.length) {
          url =`${url}/?search=${search}`
        }
        if(restaurantId && restaurantId.length) {
          url = search && search.length ? `${url}&restaurantId=${restaurantId}` : `${url}/?restaurantId=${restaurantId}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        
        if (res.status === 200) {
          commit('SET_INGREDIENTS_IN_RECIPES', res.data);
          
          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchUserIngredients({ commit }, options) {
      let search = options ? options.search : ''
      let page = options ? options.page : 1
      let perPage = options ? options.perPage : 25
      let restaurantId = options ? options.restaurantId : null
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/ingredient/forUser`
        url = url + `/?page=${page}&perPage=${perPage}`
        if(search && search.length) {
          url =`${url}&search=${search}`
        }

        if(restaurantId && restaurantId.length) {
          url =`${url}&restaurantId=${restaurantId}`
        }
       
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        
        if (res.status === 200) {
          commit('SET_USER_INGREDIENTS', res.data.ingredients);
          commit('SET_TOTAL_INGREDIENTS', res.data.total);
          
          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async deleteIngredientById({ commit }, data) {
      let id = data.id
      let restaurantId = data.restaurantId
      try {
        let url = `${process.env.VUE_APP_API_URL}/ingredient/${id}`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.delete(
          url,
          {
            withCredentials: true,
          },
        );
        
        if (res.status === 200) {
          commit('DELETE_USER_INGREDIENT', res.data);
          toastr.success("Ingredientul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
        return
        
      }
    },

    async createIngredient({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/ingredient`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.post(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status == 201) {
          commit('ADD_INGREDIENT', res.data);
          commit('ADD_USER_INGREDIENT', res.data);
          toastr.success("Ingredientul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      if(cb) {
        cb()
      }
    },

    async updateIngredient({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/ingredient`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('EDIT_USER_INGREDIENT', res.data);
          toastr.success("Ingredientul a fost modificat!")
          
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      if(cb) {
        cb()
      }
    },

    async replaceIngredient({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let ingredientToReplace = formData.ingredientToReplace
      let ingredientToReplaceWith = formData.ingredientToReplaceWith
      let selectedRestaurants = formData.selectedRestaurants
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/ingredient/replace/${ingredientToReplace}/${ingredientToReplaceWith}`
        if(restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          selectedRestaurants,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          // commit('EDIT_USER_INGREDIENT', res.data);
          toastr.success("Ingredientul a fost inlocuit!")
          
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      if(cb) {
        cb()
      }
    }
  },
};
