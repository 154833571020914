<template>
  <guest-layout headline="Register">
    <div class="w-full h-full flex items-center justify-center">
      <card class="w-6/12 h-6/12" center="true">
        <span class="mb-4">
          <main-headline label="Register" class="mb-2"></main-headline>
        </span>

        <span class="mb-6"
          >Already have an account?
          <router-link to="/login">
            <span class="text-blue-700 underline">Log in</span>
          </router-link></span
        >
        <!-- Divide oAuth and local auth -->
        <v-divider style="border-block: white;"/>

        <!-- Register via email + password -->
        <form class="w-full" @submit.prevent="localRegister">

          <label>{{formData.name.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.name.placeholder"
            v-model="formData.name.value"
            @change="validateName"
          />
          <div v-if="formData.name.error" class="text-sm -mt-4 text-red-400">{{ formData.name.error }}</div>

          <label>{{formData.email.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.email.placeholder"
            v-model="formData.email.value"
            @change="validateEmail"
          />
          <div v-if="formData.email.error" class="text-sm -mt-4 text-red-400">{{ formData.email.error }}</div>


        <!-- comp name -->   
          <label>{{formData.companyName.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.companyName.placeholder"
            v-model="formData.companyName.value"
            @change="validateName"
          />

          <label>{{formData.restaurantName.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.restaurantName.placeholder"
            v-model="formData.restaurantName.value"
            @change="validateName"
          />

          <label>{{formData.vatId.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.vatId.placeholder"
            v-model="formData.vatId.value"
            @change="validateName"
          />

          <label>{{formData.companyAddress.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.companyAddress.placeholder"
            v-model="formData.companyAddress.value"
            @change="validateName"
          />

          <label>{{formData.county.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.county.placeholder"
            v-model="formData.county.value"
            @change="validateName"
          />

          <label>{{formData.city.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            :placeholder="formData.city.placeholder"
            v-model="formData.city.value"
            @change="validateName"
          />

          <label>{{formData.password.label}}</label>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            :placeholder="formData.password.placeholder"
            v-model="formData.password.value"
            @change="validatePassword"
          />
          <div v-if="formData.password.error" class="text-sm -mt-4 text-red-400">{{ formData.password.error }}</div>

          <!-- Submit button -->
          <v-btn class="w-full" type="submit">
            <div class="w-full flex items-center justify-center">
              <span> Register </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </div>
          </v-btn>

          <!-- Show error message if registration fails -->
          <div
            v-if="$store.state.auth.error && $store.state.auth.error.area === 'register'"
            class="text-red-500"
          >
            {{ $store.state.auth.error.message }}
          </div>
        </form>

        <!-- Forgot password link -->
        <span class="w-full flex justify-between mt-3">
          <router-link to="/forgotpassword">
            <span class="text-blue-700 underline">Forgot your password?</span>
          </router-link>
        </span>
      </card>
    </div>
  </guest-layout>
</template>

<script>
  import GuestLayout from '../../Layouts/GuestLayout.vue';
  import Card from '../../components/Widgets/Card.vue';
  import MainHeadline from '../../components/Typography/MainHeadline.vue';
  import InputField from './../../components/Form/Input.vue';
  import PrimaryButton from './../../components/Buttons/PrimaryButton.vue';
  import Divider from './../../components/Widgets/Divider.vue';

  export default {
    data() {
      return {
        formData: {
          name: {
            type: 'text',
            name: 'name',
            label: 'Your name',
            placeholder: 'Bruce Wayne',
            value: '',
            error: '',
            isRequired: true,
          },
          email: {
            type: 'text',
            name: 'email',
            label: 'Your email',
            placeholder: 'bruce@wayne-enterprise.com',
            value: '',
            error: '',
            isRequired: true,
          },
          password: {
            type: 'password',
            name: 'password',
            label: 'Your password (min. 10 chars)',
            placeholder: '',
            value: '',
            error: '',
            isRequired: true,
          },
          companyName: {
            type: 'text',
            name: 'companyName',
            label: 'Nume companie',
            placeholder: 'Hanul vanatorilor',
            value: '',
            error: '',
            isRequired: true,
          },
          restaurantName: {
            type: 'text',
            name: 'restaurantName',
            label: 'Nume restaurant',
            placeholder: 'Hanul vanatorilor',
            value: '',
            error: '',
            isRequired: true,
          },
          vatId: {
            type: 'text',
            name: 'vatId',
            label: 'Cod Fiscal',
            placeholder: '...',
            value: '',
            error: '',
            isRequired: true,
          },
          companyAddress: {
            type: 'text',
            name: 'companyAddress',
            label: 'Adresa',
            placeholder: 'Strada Principala, nr. 1',
            value: '',
            error: '',
            isRequired: true,
          },
          county: {
            type: 'text',
            name: 'county',
            label: 'Judet',
            placeholder: 'Vrancea',
            value: '',
            error: '',
            isRequired: true,
          },
          city: {
            type: 'text',
            name: 'type',
            label: 'Oras',
            placeholder: 'Focsani',
            value: '',
            error: '',
            isRequired: true,
          },
          
        },
      };
    },
    components: {
      GuestLayout,
      Card,
      MainHeadline,
      InputField,
      PrimaryButton,
      Divider,
    },
    methods: {

      async localRegister() {
        this._validateInputs();
        if (
          this.formData.email.error ||
          this.formData.name.error ||
          this.formData.password.error
        ) {
          return;
        }
        try {
          const res = await this.$store.dispatch('register', {
            name: this.formData.name.value,
            email: this.formData.email.value,
            password: this.formData.password.value,
            companyName: this.formData.companyName.value,
            restaurantName: this.formData.restaurantName.value,
            companyAddress: this.formData.companyAddress.value,
            vatId: this.formData.vatId.value,
            city: this.formData.city.value,
            county: this.formData.county.value
          });
        } catch (error) {
          console.log(error);
        }
      },
      /**
       * Validate the form input fields.
       */
      _validateInputs() {
        this.validateName();
        this.validateEmail();
        this.validatePassword();
      },
      /**
       * Validate the name field
       */
      validateName() {
        if (this.formData.name.value.length < 3) {
          return (this.formData.name.error =
            'Please provide a name with at least 2 characters.');
        }
        this.formData.name.error = '';
      },
      /**
       * Validate the email field
       */
      validateEmail() {
        const isEmail = String(this.formData.email.value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          );
        if (!isEmail) {
          return (this.formData.email.error =
            'Please enter a valid email address.');
        }

        return (this.formData.email.error = '');
      },
      /**
       * Validate the password field
       */
      validatePassword() {
        if (this.formData.password.value.length < 10) {
          return (this.formData.password.error =
            'Please provide a password with at least 10 characters.');
        }
        this.formData.password.error = '';
      },
    },
  };
</script>
