<template>
  <auth-layout headline="Transfera date">

    <v-form class="w-full" ref="form1">
      <div class="flex">
        <v-layout>

          <v-autocomplete class="ml-5 mr-5" hide-selected :search-input.sync="searchRestaurantFrom" no-filter 
            label="De la restaurantul *" v-model="model.fromRestaurant" :items="restaurantsFrom" color="primary" 
            :rules="[v => !!v || `Alege restaurantul`]"  item outlined item-text="restaurantName"
            item-value="_id" return-object  @change="clearData()">
            
          </v-autocomplete>
          <div>
          <v-btn v-if="model.toRestaurant && model.fromRestaurant" icon color="accent" @click="switchRestaurants()">
            <v-icon color="accent"> mdi-swap-horizontal-bold </v-icon>
          </v-btn>
          </div>

          <v-autocomplete class="ml-5 mr-5" hide-selected :search-input.sync="searchRestaurantTo" no-filter 
            label="La restaurantul *" v-model="model.toRestaurant" :items="restaurantsTo" color="primary" 
            :rules="[v => !!v || `Alege restaurantul`]"  item outlined item-text="restaurantName"
            item-value="_id" return-object >

          </v-autocomplete>
        </v-layout>
      </div>
      <div class="flex">
        <v-layout>
          <transfer-summary :restaurant="model.fromRestaurant" :restaurantsHaveSameUser="restaurantsHaveSameUser" v-if="model.fromRestaurant"/>
          <transfer-summary :restaurant="model.toRestaurant" :restaurantsHaveSameUser="restaurantsHaveSameUser" v-if="model.toRestaurant"/>
        </v-layout>
      </div>

     
      <div class="grid" v-if="recipesToTransferExist">
        <v-layout class="ml-5 mr-5 mt-5">
          <v-expansion-panels>
            <v-expansion-panel>
              <expansion-header :item="'Retete'" class="titleHeader" labelClass="label font-semibold text-xl" 
                :inputValue="() => model.transferAllRecipes" :select="() => selectAllRecipes()"/>
              <v-expansion-panel-content>
                <v-expansion-panels focusable multiple>
                  <v-expansion-panel
                    v-for="(category,i) in recipeCategories"
                    :key="i"
                  >
                    <expansion-header :item="category" class="categoryHeader" labelClass="label" 
                      :inputValue="categoryIsSelected" :select="selectCategory"/>

                    <expansion-content :items="recipesByCategory(category)" 
                      :itemIsSelected="recipeIsSelected" :selectItem="selectRecipe"/>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </div>
      <label  v-if="!recipesToTransferExist && model.fromRestaurant" class="label font-semibold padded ml-5">
        Nu exista retete de transferat 
      </label>
      <div class="grid" v-if="categoriesToTransferExist">
        <v-layout class="ml-5 mr-5 mt-5">
          <v-expansion-panels>
            <v-expansion-panel slim>
              <expansion-header item="Categorii" class="titleHeader" labelClass="label font-semibold text-xl"
                :inputValue="() => {return model.transferAllCategories}" :select="() => {selectAllCategories()}"
              />
              <expansion-content :items="categoriesToTransfer" :itemIsSelected="cat => {return categoryItemIsSelected(cat) || categoryIsPartiallySelected(cat)}" 
                :selectItem="selectCategoryItem" itemClass="categoryHeader" :disabled="categoryIsPartiallySelected"
                />

            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </div>
      <label  v-if="!categoriesToTransferExist && model.fromRestaurant" class="label font-semibold padded ml-5">
        Nu exista categorii de transferat 
      </label>

      <div class="grid" v-if="ingredientsToTransferExist && !restaurantsHaveSameUser">
        <v-layout class="ml-5 mr-5 mt-5">
          <v-expansion-panels>
            <v-expansion-panel>
              <expansion-header :item="'Ingrediente'" class="titleHeader" labelClass="label font-semibold text-xl" 
                      :inputValue="() => {return model.transferAllIngredients}" :select="() => {selectAllIngredients()}"/>
             
              <v-expansion-panel-content>
                <v-expansion-panels focusable multiple>
                  <v-expansion-panel
                    v-for="(category,i) in ingredientCategories"
                    :key="i"
                  >
                    <expansion-header :item="category" class="categoryHeader" labelClass="label" 
                      :inputValue="ingredientCategoryIsSelected" :select="selectIngredientCategory"/>

                    <expansion-content :items="ingredientsByCategory(category)" :itemIsSelected="ingredientIsSelected" :selectItem="selectIngredient"/>

                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </div>
      <label v-if="!ingredientsToTransferExist && model.fromRestaurant" class="label font-semibold padded ml-5">
        Nu exista ingrediente de transferat 
      </label>
      <div class="flex" style="padding-top: 15px" v-if="actionsEnabled">
        <v-layout>
          <v-row class="ml-5 mr-5 fullWidth transferSummaryCard" >
            <div class="flex centerJustify">
              <div :class="model.option == 'MOVE' ? 'buttonWrapperSelected' : 'buttonWrapper' ">
              <v-btn  
                color="accent" 
                class="buttonMove"
                @click="pickOption('MOVE')"
              >
                <v-icon> mdi-file-move-outline </v-icon>
                <span style="padding-left: 10px">MUTA DATELE</span>
              </v-btn>
              </div>
              <v-divider style="max-width: 40px; border-block: white;" />
              <div :class="model.option == 'COPY' ? 'buttonWrapperSelected' : 'buttonWrapper' ">
              <v-btn 
                color="accent" 
                class="buttonMove"
                @click="pickOption('COPY')"
              >
                <v-icon> mdi-file-document-multiple-outline </v-icon>
                <span style="padding-left: 10px">COPIAZA DATELE</span>
              </v-btn>
              </div>
            </div>
            <v-row class="ml-5 mr-5 fullWidth transferSummaryCard" >
            <div class="flex " v-if="model.option">
              <v-layout>
                <transfer-data-summary :restaurant="model.fromRestaurant" :restaurantsHaveSameUser="restaurantsHaveSameUser" :model="model" :isDestination="false"/>
                <transfer-data-summary :restaurant="model.toRestaurant" :restaurantsHaveSameUser="restaurantsHaveSameUser" :model="model" :isDestination="true"/>
              </v-layout>
            </div>
            </v-row>
            <v-row class="ml-5 mr-5 fullWidth transferSummaryCard" >
            <div class="flex " v-if="saveEnabled">
              <v-btn type="button"  class="mainButtonRed" text @click="openDialog()">Confirma</v-btn>
            </div>
            </v-row>
          </v-row>
        </v-layout>
        <confirm-transfer 
          v-if="dialog"
          :option="model.option" 
          :source="model.fromRestaurant ? model.fromRestaurant.restaurantName : ''"
          :destination="model.toRestaurant ? model.toRestaurant.restaurantName : ''"
          :closeDialog="closeDialog"
          :confirm="transferData"
        />
      </div>

    </v-form>
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import ExpansionHeader from '../components/TransferData/ExpansionHeader.vue';
  import ExpansionContent from '../components/TransferData/ExpansionContent.vue';

  import TransferSummary from '../components/TransferData/TransferSummary.vue';
  import TransferDataSummary from '../components/TransferData/TransferDataSummary.vue';

  import SubHeader from '../components/Typography/SubHeader.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';
import ConfirmTransfer from '../components/TransferData/ConfirmTransfer.vue';

  export default {
    data() {
      return {
        dialog: false,
        model: {
            fromRestaurant: null,
            toRestaurant: null,
            transferAllRecipes: false,
            selectedRecipes: [],
            transferAllIngredients: false,
            selectedIngredients: [],
            transferAllCategories: false,
            selectedCategories: [],
            option: null
        },
        searchRestaurantFrom: '',
        searchRestaurantTo: '',
      };
    },
    components: { AuthLayout, PrimaryButton, SubHeader, TransferSummary , ExpansionHeader, ExpansionContent, TransferDataSummary, ConfirmTransfer},
    computed: {
      restaurantsFrom() {
        let restaurants = this.$store.state.transfer.restaurantsFrom
        if(this.model.fromRestaurant) {
          restaurants.push({...this.model.fromRestaurant})
        }
        return restaurants
        .filter(r => {
          if(!this.model.toRestaurant) {
            return true
          }
          return this.model.toRestaurant._id != r._id
        })
      },
      restaurantsTo() {
        let restaurants = this.$store.state.transfer.restaurantsTo
        if(this.model.toRestaurant) {
          restaurants.push({...this.model.toRestaurant})
        }
        return restaurants
        .filter(r => {
          if(!this.model.fromRestaurant) {
            return true
          }
          return this.model.fromRestaurant._id != r._id
        })
      },
      restaurantsHaveSameUser() {
        return this.model.toRestaurant && this.model.fromRestaurant && this.model.toRestaurant.user._id == this.model.fromRestaurant.user._id
      },
      actionsEnabled() {
        return this.model.fromRestaurant && this.model.toRestaurant && (
          this.recipesToTransferExist || this.ingredientsToTransferExist || this.categoriesToTransferExist
        )
      },
      saveEnabled() {
        return this.model.fromRestaurant && this.model.toRestaurant && this.model.option && (
          this.model.selectedRecipes.length || this.model.selectedIngredients.length || this.model.selectedCategories.length
        )
      },
      recipesToTransferExist() {
        return this.model.fromRestaurant && this.model.fromRestaurant.activeRecipes.length
      },
      recipesToTransfer() {
        return this.recipesToTransferExist ? this.model.fromRestaurant.activeRecipes : []
      },

      ingredientsToTransferExist() {
        return this.model.fromRestaurant && this.model.fromRestaurant.activeIngredients.length
      },
      ingredientsToTransfer() {
        return this.ingredientsToTransferExist ? this.model.fromRestaurant.activeIngredients : []
      },
      categoriesToTransferExist() {
        return this.model.fromRestaurant && this.model.fromRestaurant.activeCategories.length
      },
      categoriesToTransfer() {
        return this.recipesToTransferExist ? this.model.fromRestaurant.activeCategories : []
      },

      recipeCategories() {
        if(!this.recipesToTransferExist) {
          return []
        }
        let categories = []
        this.recipesToTransfer.map(r => {
          if(!categories.find(c => {return c._id == r.category._id})) {
            categories.push(r.category)
          }
        })
        return categories
      },
      ingredientCategories() {
        if(!this.ingredientsToTransferExist) {
          return []
        }

        return this.ingredientsToTransfer.map(i => i.category).filter((v,i,a) => a.indexOf(v) == i)
      }
    },
    watch: {
      searchRestaurantFrom(newVal, oldval) {
        if(this.model.fromRestaurant && newVal == this.model.fromRestaurant.restaurantName) {
          return
        }
        this.fetchEntriesDebouncedFrom(this.searchRestaurantFrom)
      },
      searchRestaurantTo(newVal, oldval) {
        if(this.model.toRestaurant && newVal == this.model.toRestaurant.restaurantName) {
          return
        }
        this.fetchEntriesDebouncedTo(this.searchRestaurantTo)
      },
    },
    methods: {
      openDialog() {
        this.dialog = true
      },
      closeDialog() {
        this.dialog = false
      },
      transferData() {
        this.$store.dispatch("transferData", {model: this.model, cb: () => {
          this.$router.push(`/restaurants`)
        }})

      },
      pickOption(option) {
        this.model.option = option
      },
      clearData() {
        this.model.selectedIngredients = []
        this.model.selectedRecipes = []
        this.model.selectedCategories = []
        this.model.transferAllIngredients = false
        this.model.transferAllRecipes = false
        this.model.transferAllCategories = false
      },
      selectAll(allItems, transferKey, selectedKey, callBackOnAll) {
        this.model[transferKey] = !this.model[transferKey]
        if(this.model[transferKey]) {
          this.model[selectedKey] = this.model[selectedKey].concat(allItems.map(i => i._id))
            .filter((v,i,a) => a.indexOf(v) == i)
          if(callBackOnAll) {
            callBackOnAll()
          }
        } else {
          this.model[selectedKey] = []
        }
      },
      selectAllRecipes() {
        this.selectAll(this.recipesToTransfer, 'transferAllRecipes', 'selectedRecipes', () => {this.selectAllCategories(true)})
        // this.selectAllCategories()
      },
      selectAllIngredients() {
        this.selectAll(this.ingredientsToTransfer, 'transferAllIngredients', 'selectedIngredients')
      },
      selectAllCategories(forceTrue) {
        if(forceTrue) {
          this.model.transferAllCategories = false
        }
        this.selectAll(this.categoriesToTransfer, 'transferAllCategories', 'selectedCategories')
        // pt fiecare categ partially Selected, o selectam
        this.categoriesToTransfer.map(c => {
          if(this.categoryIsPartiallySelected(c)) {
            this.selectCategoryItem(c, true)
          }
        })
      },

      recipeIsSelected(recipe) {
        let res =  this.model.selectedRecipes.includes(recipe._id)
        return res
      },
      ingredientIsSelected(ingredient) {
        return this.model.selectedIngredients.includes(ingredient._id)
      },
      categoryItemIsSelected(category) {
        return this.model.selectedCategories.includes(category._id)
      },
      categoryIsSelected(category) {
        let recipesInCategory = this.recipesByCategory(category)
        let isSelected = true
        recipesInCategory.map(recipe => {
          if(!this.model.selectedRecipes.includes(recipe._id)) {
            isSelected = false
          }
        })
        return isSelected
      },

      categoryIsPartiallySelected(category) {
        let recipesInCategory = this.recipesByCategory(category)
        let isSelected = false
        recipesInCategory.map(recipe => {
          if(this.model.selectedRecipes.includes(recipe._id)) {
            isSelected = true
          }
        })
        return isSelected
      },
      
      ingredientCategoryIsSelected(category) {
        let ingredientsInCategory = this.ingredientsByCategory(category)
        let isSelected = true
        ingredientsInCategory.map(ingredient => {
          if(!this.model.selectedIngredients.includes(ingredient._id)) {
            isSelected = false
          }
        })
        return isSelected
      },
      selectItem(item, isSelected, selectedKey, transferKey, allItems, forceTrue, cbOnTrue) {
        if(isSelected && !forceTrue) {
          this.model[selectedKey] = this.model[selectedKey].filter(s => s != item._id)
        } else {
          this.model[selectedKey].push(item._id)
          if(cbOnTrue) {
            cbOnTrue()
          }
        }
        this.model[selectedKey] = this.model[selectedKey].filter((v,i,a) => a.indexOf(v) == i)
        this.model[transferKey] = this.model[selectedKey].length == allItems.length

      },

      selectRecipe(recipe) {
        this.selectItem(recipe, this.recipeIsSelected(recipe), 
          'selectedRecipes', 'transferAllRecipes', 
          this.recipesToTransfer, false, () => {this.selectCategoryItem(recipe.category, true)}
          )
      },
      selectIngredient(ingredient) {
        this.selectItem(ingredient, this.ingredientIsSelected(ingredient), 'selectedIngredients', 'transferAllIngredients', this.ingredientsToTransfer)
      },
      selectCategoryItem(category, forceTrue) {
        this.selectItem(category, this.categoryItemIsSelected(category), 'selectedCategories', 'transferAllCategories', this.categoriesToTransfer, forceTrue)
      },

      selectCategory(category) {
        let recipesInCategory = this.recipesByCategory(category)
        if(this.categoryIsSelected(category)) {
          // unselect all
          this.model.selectedRecipes = this.model.selectedRecipes
            .filter(selectedRecipe => {
              return !recipesInCategory.map(r => r._id).includes(selectedRecipe)
            })
        } else {
          this.model.selectedRecipes = this.model.selectedRecipes.concat(recipesInCategory.map(r => r._id))
            .filter((v,i,a) => a.indexOf(v) == i)
        }
        this.model.transferAllRecipes = this.model.selectedRecipes.length == this.recipesToTransfer.length
      },

      selectIngredientCategory(category) {
        let ingredientsInCategory = this.ingredientsByCategory(category)
        if(this.ingredientCategoryIsSelected(category)) {
          // unselect all
          this.model.selectedIngredients = this.model.selectedIngredients
            .filter(selectedIngredient => {
              return !ingredientsInCategory.map(r => r._id).includes(selectedIngredient)
            })
        } else {
          this.model.selectedIngredients = this.model.selectedIngredients.concat(ingredientsInCategory.map(r => r._id))
            .filter((v,i,a) => a.indexOf(v) == i)
        }
        this.model.transferAllIngredients = this.model.selectedIngredients.length == this.ingredientsToTransfer.length
      },
      switchRestaurants() {
        if(!this.model.toRestaurant || !this.model.fromRestaurant) {
          return
        }
        let toRestaurant = {...this.model.toRestaurant}
        let fromRestaurant = {...this.model.fromRestaurant}

        this.model.toRestaurant = fromRestaurant
        this.model.fromRestaurant = toRestaurant

        this.clearData()

      },
      recipesByCategory(category) {
        return this.recipesToTransfer.filter(r => r.category._id == category._id)
      },
      ingredientsByCategory(category) {
        return this.ingredientsToTransfer.filter(r => r.category == category)
      },
      fetchEntriesDebouncedFrom(search) {
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          this.searchRestaurantsFrom(search)
        }, 200)
      },
      fetchEntriesDebouncedTo(search) {
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          this.searchRestaurantsTo(search)
        }, 200)
      },
      
      searchRestaurantsFrom(search) {
        this.$store.dispatch("fetchRestaurantsFrom", {search: search})
      },
      searchRestaurantsTo(search) {
        this.$store.dispatch("fetchRestaurantsTo", {search: search})
      },
      totalRecipes(restaurant) {
        let total = restaurant ? restaurant.activeRecipes.length : 0
        return total == 1 ? `${total} reteta` : `${total} retete`
      },
      totalIngredients(restaurant) {
        let total = restaurant ? restaurant.activeIngredients.length : 0
        return total == 1 ? `${total} ingredient` : `${total} ingrediente`
      },
      totalCredits(restaurant) {
        let total = restaurant ? restaurant.availableCredits : 0
        return total == 1 ? `${total} credit ramas` : `${total} credite ramase`
      }
      
    },
    created() {
      this.searchRestaurantsFrom()
      this.searchRestaurantsTo()
     
    }
  };
</script>
<style scoped>
  .categoryHeader {
    height: 20px;
    padding-left: 0px
  }
  .titleHeader {
    height: 35px;
    padding-left: 0px
  }
  .padded {
    padding: 10px
  }

  /* #custom-disabled.v-btn--disabled {
      background-color: red !important;
  } */

  .disable-events {
    pointer-events: none;
  }


  .buttonWrapperSelected {
    width: 20%;
    justify-content: center;
    display: flex;
    background: radial-gradient(rgb(61 207 211 / 40%), transparent);
    height: 100%;
    border-radius: 100px;
  }

  .buttonWrapper {
    width: 20%;
    justify-content: center;
    display: flex;
    background: transparent;
    height: 100%;
    border-radius: 100px;
  }

  .buttonMove {
    width: 80%;
    margin: 40px;
    border-radius: 100px;
  }

</style>