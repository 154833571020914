<template>
  <div class="tableHeader ml-5 mt-7">
    <label class="block text-gray-700 text-sm font-bold mb-2">
      {{ sectionHeadline }}
    </label>

    <v-chip variant="elevated" v-for="item in ingredientHeaders" color="dark" class="mr-2 mb-2" :key="item.value">
      <b>{{  item.text + ":  " }}</b>
      {{  formattedValues[item.value] }}
    </v-chip>
    <!-- <v-data-table
      :hide-default-footer="true"
      :items="[formattedValues]"
      :headers="ingredientHeaders"
    >
      <template v-slot:[`item.energy`]="props">
        {{props.item.energy}} / {{props.item.energyKJ}}
      </template> -->
    <!-- </v-data-table> -->
  </div>
</template>

<script>
import SectionHeadline from '../Typography/SectionHeadline.vue';
import Paragraph from '../Typography/Paragraph.vue';
import { MapUnitMeasures, nutriKeys } from '../../common/UnitMeasures'


export default {
  components: {
    Paragraph,
    SectionHeadline
  },
  emits: ['confirmationAction', 'cancelAction'],
  props: {
    sectionHeadline: String,
    item: Object
  },
  data() {
    return {
   
    }
  },
  computed: {
    unitMeasures() {
      return MapUnitMeasures
    },
    ingredientHeaders() {
      let headers = []

      // la energie le combinam!!!

      nutriKeys.map(key => {
        if (key == 'energyKJ') {
          return
        }

        if (key == 'energy') {
          headers.push({
            text: `${this.fieldNames[key]?.displayName}`,
            value: key
          })
        } else {
          headers.push({
            text: `${this.fieldNames[key]?.displayName}`,
            value: key
          })

        }

      })


      return headers


    },
    fieldNames() {
      return this.$store.state.recipes.fieldNames
    },
    formattedValues() {
      // adaugam unit measure la fiecare
      let formattedVal = {}
      Object.keys(this.item).map(key => {
        formattedVal[key] = `${this.item[key]} ${this.unitMeasures[key]}`
      })

      return formattedVal
    }
  }
};
</script>

