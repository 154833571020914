<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga nume camp</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Nume camp *"
                  v-model="model.name"
                  required
                  :rules="[v => !!v || `Completeaza numele campului`]"
                  outlined
                />
            </div>

            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Cheie *"
                  v-model="model.key"
                  required
                  :rules="[v => !!v || `Completeaza cheia`]"
                  outlined
                />
            </div>

            <div class="padded">
                <v-autocomplete label="Selecteaza unitatea de masura *" v-model="model.unitMeasure"
                  :items="unitMeasures" color="blue-grey-lighten-2" :rules="[v => !!v || `Alege unitatea de masura`]"
                  item outlined item-text="name" item-value="_id" return-object></v-autocomplete>
              </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "model",
    "closeDialog",
    "saveFieldName"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"]
  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      if(this.$refs.form.validate()) {
        this.saveFieldName(this.model)
        this.close()
      }

    }
  },
  computed: {
    unitMeasures() {
      return this.$store.state.unitMeasures.unitMeasureNames
    },
  },
  created() {
  }
};
</script>