<template>
  <guest-layout >
    <div class="w-full h-full flex items-center justify-center">
      <card class="w-6/12 h-6/12" center="true">
        <span class="mb-4">
          <main-headline label="Reseteaza parola" class="mb-2"></main-headline>
        </span>

        <v-divider style="border-block: white;"/>

        <!-- Register via email + password -->
        <form class="w-full" @submit.prevent="getToken">
          <!-- Email -->

          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            :placeholder="formData.email.placeholder"
            v-model="formData.email.value"
            @change="validateEmail"
          />
          <div v-if="formData.email.error" class="text-sm -mt-4 text-red-400">{{ formData.email.error }}</div>

    

          <!-- Submit button -->
          <primary-button class="w-full" type="submit">
            <div class="w-full flex items-center justify-center">
              <span> Trimite email </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </div>
          </primary-button>

          <!-- Show error message if login fails -->
          <div
            v-if="$store.state.auth.error && $store.state.auth.error.area === 'login'"
            class="text-red-500"
          >
            {{ $store.state.auth.error.message }}
          </div>
        </form>
      </card>
    </div>
  </guest-layout>
</template>

<script>
  import GuestLayout from '../../Layouts/GuestLayout.vue';
  import Card from '../../components/Widgets/Card.vue';
  import MainHeadline from '../../components/Typography/MainHeadline.vue';
  import InputField from '../../components/Form/Input.vue';
  import PrimaryButton from '../../components/Buttons/PrimaryButton.vue';
  import Divider from '../../components/Widgets/Divider.vue';
import { mapActions } from "vuex";

  export default {
    //===========================
    // DATA
    //===========================
    data() {
      return {
        formData: {
          email: {
            type: 'text',
            name: 'email',
            label: 'Your email',
            placeholder: 'bruce@wayne-enterprise.com',
            value: '',
            error: null,
            isRequired: true,
          },
 
        },
      };
    },
    //===========================
    // COMPONENTS
    //===========================
    components: {
      GuestLayout,
      Card,
      MainHeadline,
      InputField,
      PrimaryButton,
      Divider,
    },
    //===========================
    // METHODS
    //===========================
    methods: {

      /**
       * Log in via email and password.
       */
      ...mapActions("auth", [
        "login"
      ]),

      getToken() {
        this.validateEmail()
        if (this.formData.email.error) {
          return;
        }

        this.$store.dispatch('sendResetPasswordEmail', {email: this.formData.email.value})

      },
      /**
       * Validate the form input fields.
       */
      _validateInputs() {
        this.validateEmail();
        this.validatePassword();
      },

      /**
       * Validate the email field
       */
      validateEmail() {
        const isEmail = String(this.formData.email.value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          );
        if (!isEmail) {
          return (this.formData.email.error =
            'Please enter a valid email address.');
        }

        return (this.formData.email.error = null);
      },
      /**
       * Validate the password field
       */
      validatePassword() {
        if (this.formData.password.value.length < 10) {
          return (this.formData.password.error =
            'Please provide a password with at least 10 characters.');
        }
        this.formData.password.error = null;
      },
    },
  };
</script>
