<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="800">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          style="height: 20px !important"
        />
        <v-card-title>
          <span class="dialogText">
            <b>Asigura-te ca ai completat corect campurile:</b>

            <li><b>Descriere ingrediente din componenta sa</b> - acestea trebuie completate cu informatiile de pe eticheta produsului</li>
            <li><b>Aditivi</b> - acestia trebuie completati in campul <i>Aditivi</i></li>
            <li><b>Alergeni</b> - acestia trebuie completati in campul <i>Alergeni</i></li>
            
          </span>
         
        </v-card-title>
   
        <v-card-actions class="justify-end">
          
          <v-btn
            class="mainButtonYes"
            @click="save()"
          >Sunt corecte</v-btn>
          <v-btn
            class="mainButtonNo"
            @click="close()"
            color="red"
          >Corectez acum</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "closeDialog",
    "confirm"
  ],
  data: () => ({
    dialog: true,

  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      this.confirm()
      this.close()
    }
  },
  computed: {
  },
  created() {
  }
};
</script>