<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title >
          <span class="headline">Adauga eticheta</span>
        </v-card-title>
        
        <v-card-text>
          <v-form ref="form">
            <div class="padded flex">
              <div class="fieldsLeft">
                <v-text-field 
                      type="text"
                      placeholder="Nume *"
                      label="Nume *"
                      v-model="model.name"
                      required
                      :rules="[v => !!v || `Completeaza numele`]"
                      outlined
                    />

                  <v-text-field
                    type="text"
                    placeholder="Icon"
                    label="Icon"
                    v-model="model.icon"
                    outlined
                    @change="forceRerender(); updateIcon()"
                  />

                  <div :key="componentKey" class="iconView" v-html="model.icon" :style="`fill: ${model.color} !important`"/>
              </div>
                <v-color-picker class="picker" dot-size="25" swatches-max-height="200" v-model="model.color" width="200" mode="hexa"
                    @input="forceRerender()"></v-color-picker>

            
                          
            </div>
        
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  props: [
    "model",
    "closeDialog",
    "saveTag"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    color: '#000000',
    componentKey: 1

  }),
  methods: {
    forceRerender() {
      this.componentKey += 1;

    
    },
    updateIcon() {
      if(this.model.icon && this.model.icon.length) {
        var xmlString = this.model.icon
        xmlString = xmlString.replace(/\.st0\s?\{fill\:[^}]+;\}/g, '.st0{}');
        var doc = new DOMParser().parseFromString(xmlString, "text/xml");
        doc.firstChild.removeAttribute("height"); 
        doc.firstChild.removeAttribute("width"); 
        doc.firstChild.removeAttribute("fill"); 
        doc.firstChild.style.height = "inherit"
        doc.firstChild.style.width = "inherit"
        doc.firstChild.style.fill = ""
        var elementsWithClass = doc.querySelectorAll('');
       

        // Iterate through each element and remove the 'fill' property
        elementsWithClass.forEach(function(element) {
            element.style.fill = '';
        });
        elementsWithClass.forEach(function(element) {
            // Set the fill attribute to 'none' to remove the fill color defined in <style>
            element.setAttribute('fill', 'none');
        });
        

        // doc.firstChild.fill = ""
        // doc.firstChild.height = ""
        var newIcon = new XMLSerializer().serializeToString(doc.documentElement);
        this.model.icon = newIcon
      }

    },
    close() {
      this.closeDialog()
      
    },
    save() {
      if(this.$refs.form.validate()) {
        this.saveTag(this.model)
        this.close()
      }

    }
  },
  computed: {
  },
  created() {
    if(!this.model.color) {
      this.model.color = '#000000'
    }
  }
};
</script>

<style scoped>
  .fieldsLeft {
    width: 60%;
    padding: 10px;
    margin: 10px;
  }

  .picker {
    margin-top: 10px
  }

  .iconView {
    height: 100px;
    justify-content: center;
    display: flex;
  }
</style>