<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga unitate de masura</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Nume camp *"
                  label="Nume camp *"
                  v-model="model.name"
                  required
                  :rules="[v => !!v || `Completeaza numele`]"
                  outlined
                />
            </div>

            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Prescurtare *"
                  label="Prescurtare *"
                  v-model="model.shortcut"
                  required
                  :rules="[v => !!v || `Completeaza prescurtarea`]"
                  outlined
                />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "model",
    "closeDialog",
    "saveUnitMeasureName"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"]
  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      if(this.$refs.form.validate()) {
        this.saveUnitMeasureName(this.model)
        this.close()
      }

    }
  },
  computed: {
  },
  created() {
  }
};
</script>