<template>
  <v-card class="ml-5 mr-5 halfWidth transferSummaryCard" >
    <div class="grid" v-if="restaurant">
      <v-card-item class="transferSummaryItem">
        <v-icon color="primary"> mdi-account </v-icon>
        {{restaurant.user.name}}
      </v-card-item>
      <v-card-item class="transferSummaryItem">
        <v-icon color="accent"> mdi-hand-coin </v-icon>
        {{totalCredits(restaurant)}}
      </v-card-item>
      <v-card-item class="transferSummaryItem">
        <v-icon color="green"> mdi-bowl-mix </v-icon>
        {{totalRecipes(restaurant)}}
      </v-card-item>
      <v-card-item class="transferSummaryItem">
        <v-icon color="secondary"> mdi-format-list-bulleted-type </v-icon>
        {{totalCategories(restaurant)}}
      </v-card-item>
      <v-card-item class="transferSummaryItem" v-if="!restaurantsHaveSameUser">
        <v-icon color="red"> mdi-spoon-sugar </v-icon>
        {{totalIngredients(restaurant)}}
      </v-card-item>
    </div>
  </v-card>
</template>

<script>
  export default {
    props: [
      "restaurant",
      "restaurantsHaveSameUser"
    ],

    methods: {
      
      totalRecipes(restaurant) {
        let total = restaurant ? restaurant.activeRecipes.length : 0
        return total == 1 ? `${total} reteta` : `${total} retete`
      },
      totalIngredients(restaurant) {
        let total = restaurant ? restaurant.activeIngredients.length : 0
        return total == 1 ? `${total} ingredient` : `${total} ingrediente`
      },
      totalCategories(restaurant) {
        let total = restaurant ? restaurant.activeCategories.length : 0
        return total == 1 ? `${total} categorie` : `${total} categorii`
      },
      totalCredits(restaurant) {
        let total = restaurant ? restaurant.availableCredits : 0
        return total == 1 ? `${total} credit ramas` : `${total} credite ramase`
      },
      
    }
  };
</script>