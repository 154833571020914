import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import "vuetify/dist/vuetify.min.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faA } from '@fortawesome/free-solid-svg-icons'
import { faD } from '@fortawesome/free-solid-svg-icons'
import { faB } from '@fortawesome/free-solid-svg-icons'
import { faP } from '@fortawesome/free-solid-svg-icons'

import Vuetify from "vuetify";
import '@mdi/font/css/materialdesignicons.css'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueQRCodeComponent from 'vue-qrcode-component'

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */
library.add(faUserSecret)
library.add(faPen)
library.add(faXmark)
library.add(faSearch)
library.add(faTrashCan)
library.add(faCopy)
library.add(faPlus)
library.add(faCheck)
library.add(faA)
library.add(faB)
library.add(faP)
library.add(faD)

// Set default base url for axios.
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;


import Vue from "vue";
import './registerServiceWorker'

Vue.directive('init', {
	bind: function (el, binding /*, vnode*/) {
		// console.log(binding.value); //# This line is optional, of course.
	}
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('qr-code', VueQRCodeComponent)

Vue.use(axios)
Vue.use(Vuetify, {

	theme: {
		options: {
			customProperties: true
		},

	}
})
Vue.use(store)
Vue.use(VueTelInput);
Vue.use(() => {
	new Vue({
		el: "#app",
		vuetify: new Vuetify({
			theme: {
				dark: false,
				themes: {
					light: {
						primary: "#18a679",
						accent: "#0A5DF8",
						secondary: "#3D87E4",
						text: "#7A7A7A"
					}
				}
			}
		}),
		components: {
			App
		},
		router,
		store,
		render: h => h(App)
	});
});



