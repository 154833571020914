<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">COD QR</span>
        </v-card-title>
        <v-card-subtitle>
          <!-- {{restaurant}} -->
          <!-- Link reteta: <a :href="link" target="blank">{{link}}</a> -->
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col>
              <div id="qr-code">
                <QRCode :color="color" :bg-color="bgColor" :size="size" :key="componentKey" :restaurant="restaurant" :recipe="recipe"/>

              </div>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <div>
                    <v-label>Alege culoarea</v-label>
                    <v-color-picker dot-size="25" swatches-max-height="200" v-model="color" width="200" mode="hexa"
                      @input="forceRerender()"></v-color-picker>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <v-label>Alege culoarea de fundal</v-label>
                    <v-color-picker dot-size="25" swatches-max-height="200" v-model="bgColor" width="200" mode="hexa"
                      @input="forceRerender()"></v-color-picker>
                  </div>
                </v-col>
              </v-row>



              <v-slider v-model="size" min="150" max="400" label="Alege dimensiunea" @change="forceRerender()" />
            </v-col>
          </v-row>


        </v-card-text>

        <v-card-actions>
          <v-btn class="mainButtonYes" text @click="print()">Descarca</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import QRCode from './QRCode.vue'
import * as htmlToImage from 'html-to-image';

export default {
  components: {
    QRCode

  },
  props: [
    "closeDialog", "restaurant", "recipe"
  ],
  data: () => ({
    dialog: true,
    color: '#000000',
    bgColor: '#ffffff',
    componentKey: 0,
    size: 150,
    sizeItems: [150, 200, 300, 500],
    output: null,
    qrForDownload: ''
  }),
  watch: {

  },
  computed: {
    link() {
      return !this.recipe ? `https://valori-nutritionale.ro/restaurant/${this.restaurant._id}&utm=qrcode` : `https://meniu.valori-nutritionale.ro/recipes/public/${this?.recipe._id}?restaurantId=${this.restaurant.slug}&utm=qrcode`
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    print() {
      var node = document.getElementById('qr-code');
      var img = node.querySelector('img')
      var src = img.getAttribute('src');

      const link = document.createElement('a');
      link.href = src;
      link.setAttribute('download', 'qrcode' + this.restaurant.restaurantName + '.png');
      document.body.appendChild(link);
      link.click();

    },
    close() {
      this.closeDialog()

    },

  },
  created() {
  }
};
</script>