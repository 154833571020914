import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    user: {},
    loadingUser: true,
    isAuthenticated: false,
    access_token: '',
    error: {},
    recipes: [],
    recipe: {},
    allergens: [],
    potentialAllergens: [],
    categories: [],
    additives: [],
    fieldNames: {},
    loadingPdf: false,
    totalRecipes: 0,
    publicRecipes: {},
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_LOADING(state) {
      state.loadingPdf = true
    },
    UNSET_LOADING(state) {
      state.loadingPdf = false
    },
    SET_FIELD_NAMES_UI(state, fieldNames) {
      state.fieldNames = fieldNames
    },
    SET_RECIPES(state, recipes) {
      // recipes.map(r => {
      //   if(!state.recipes.find(recipe => recipe._id == r._id)) {
      //     state.recipes.push(r)
      //   }
      // })
      state.recipes = recipes
    },
    SET_PUBLIC_RECIPES(state, publicRecipes) {
      state.publicRecipes = publicRecipes
    },
    SET_TOTAL_RECIPES(state, total) {
      state.totalRecipes = total
    },
    SET_RECIPE(state, recipe) {
      state.recipe = recipe
    },
    ADD_RECIPE(state, recipe) {
      if (!state.recipes.find(r => r._id == recipe._id)) {
        state.recipes.push(recipe)
      }
    },
    EDIT_RECIPE(state, recipe) {
      state.recipes = state.recipes.map(r => {
        if (r._id == recipe._id) {
          r = { ...recipe }
        }
        return r
      })
    },
    UPDATE_CATEGORY_PRIORITY(state, sortedCategories) {
      let mapPriorities = {}
      sortedCategories = sortedCategories.map((c, index) => {
        c.priority = index + 1
        mapPriorities[c._id] = index + 1
        return c
      })
      state.recipes = state.recipes.map(r => {
        r.category.priority = mapPriorities[r.category._id]
        return r
      })

    },
    UPDATE_RECIPE_PRIORITY(state, data) {
      let recipe = {...state.recipes.find(r => r._id == data.id)}
      recipe.category = data.categoryObj
      recipe.priority = data.priority

      let recipesInCategory = state.recipes.filter(r => r.category._id == data.category).sort((a,b) => {return a.priority - b.priority})
      recipesInCategory = recipesInCategory.filter(r => r._id != data.id)
      recipesInCategory.splice(recipe.priority - 1, 0, recipe)
      recipesInCategory = recipesInCategory.map((r, index) => {
        r.priority = index + 1
        return r
      })

      state.recipes = state.recipes.filter(r => r.category._id != data.category && r._id != data.id)
      state.recipes = state.recipes.concat(...recipesInCategory)
    },
    DELETE_RECIPE(state, recipe) {
      state.recipes = state.recipes.filter(r => {
        return (r._id != recipe._id)
      })
    },
    DELETE_CATEGORY(state, category) {
      state.categories = state.categories.filter(r => {
        return (r._id != category._id)
      })
    },
    ADD_CATEGORY(state, category) {
      state.categories.push(category)
    },
    UPDATE_CATEGORY(state, category) {
      state.categories = state.categories.map(r => {
        if (r._id == category._id) {
          r = { ...category }
        }
        return r
      })
    },
    SET_ALLERGENS(state, allergens) {
      state.allergens = allergens
    },
    SET_ADDITIVES(state, additives) {
      state.additives = additives
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {

    async fetchFieldNamesForUI({ commit }) {
      try {
        // Send api request.
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/fieldNames/forUI`,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('SET_FIELD_NAMES_UI', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchPdf({ commit }, dataAndCb) {
      commit('SET_LOADING');
      let cb = dataAndCb.cb
      let sel = dataAndCb.selected
      let restaurantId = dataAndCb.restaurantId
      let option = dataAndCb.option || "Meniu"
      let format = dataAndCb.format
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes/generatePDF/?option=${option}&format=${format}`
        if (restaurantId) {
          url = url + `&restaurantId=${restaurantId}`
        }
        // Send api request.
        const res = await axios.post(
          url,
          sel,
          {
            withCredentials: true,

            headers: {
              'Content-type': 'application/json',
            },
            responseType: 'blob'
          },
        );


        if (res.status === 201) {
          // commit('SET_RECIPES', res.data);
          cb(res)
          commit('UNSET_LOADING');

        }
      } catch (error) {
        commit('UNSET_LOADING');
        toastr.error("A aparut o eroare la exportul in PDF")
        console.log(error)
        return

      }
    },


    async fetchDocx({ commit }, dataAndCb) {
      commit('SET_LOADING');
      let cb = dataAndCb.cb
      let sel = dataAndCb.selected
      let restaurantId = dataAndCb.restaurantId
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes/generateDocxMenu`
        if (restaurantId) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        // Send api request.
        const res = await axios.post(
          url,
          sel,
          {
            withCredentials: true,

            headers: {
              'Content-type': 'application/json',
            },
            responseType: 'blob'
          },
        );


        if (res.status === 201) {
          // commit('SET_RECIPES', res.data);
          cb(res)
          commit('UNSET_LOADING');

        }
      } catch (error) {
        commit('UNSET_LOADING');
        toastr.error("A aparut o eroare la exportul in DOCX")
        console.log(error)
        return

      }
    },

    async fetchRecipes({ commit }, data) {
      let restaurantId = data ? data.restaurantId : null
      let search = data ? data.search : null
      let page = data && data.page ? data.page : 1
      let perPage = data && data.perPage ? data.perPage : 28
      let category = data ? data.category : null
      let filter = data ? data.activeFilter : null
      // perPage = 99999
      let sort = data && data.sort ? data.sort : 'createdAt'
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes/?page=${page}&perPage=${perPage}&sort=${sort}`
        if (restaurantId && restaurantId.length) {
          url += `&restaurantId=${restaurantId}`
        }
        if (search && search.length) {
          url += `&search=${search}`
        }
        if (category && category.length) {
          url += `&category=${category}`
        }

        if (filter && filter.length) {
          url += `&filter=${filter}`
        }
        // Send api request.
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('SET_RECIPES', res.data.recipes);
          commit('SET_TOTAL_RECIPES', res.data.total);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchPublicRecipes({ commit }, data) {
      let restaurantId = data ? data.restaurant : null
      let recipe = data ? data.recipe : null
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes/restaurantRecipes/public`
        const res = await axios.post(
          url,
          {
            restaurant: restaurantId,
            recipe: recipe,
          },
          {
            withCredentials: true,
          },
        );
        if (res.status <= 201) {
          commit('SET_PUBLIC_RECIPES', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchRecipeById({ commit }, data) {
      let id = data.id
      let restaurantId = data.restaurantId
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/recipes/${id}`
        if (restaurantId) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('SET_RECIPE', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async deleteRecipeById({ commit }, data) {
      let id = data.id
      let restaurantId = data.restaurantId
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/recipes/${id}`
        if (restaurantId) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.delete(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status == 200) {
          if(res.data.isProduct) {
            toastr.success("Produsul a fost sters cu succes!")
          } else {
            toastr.success("Reteta a fost stearsa cu succes!")
          }
          
          commit('DELETE_RECIPE', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async deleteCategoryById({ commit }, data) {
      let id = data.id
      let restaurantId = data.restaurantId
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/categories/${id}`
        if (restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.delete(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('DELETE_CATEGORY', res.data);
          toastr.success("Categoria ta a fost stearsa!")
          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async updateRecipesToGrams({commit}, data) {
      let restaurantId = data.restaurantId
      let cb = data.cb

      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/recipes/transformGrams/${restaurantId}`
       
        const res = await axios.put(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          toastr.success("retele au fost actualizate!!")
          cb()
          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async updateCategoryPriority({ commit }, data) {
      let id = data.id
      let restaurantId = data.restaurantId
      let priority = data.priority
      let cb = data.cb
      commit('UPDATE_CATEGORY_PRIORITY', data.sortedCategories)
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/categories/priority/${id}/${priority}`
        if (restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          // toastr.success("Prioritatea categoriei a fost actualizata")
          cb()
          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async updateRecipePriority({ commit }, data) {
      let id = data.id
      let restaurantId = data.restaurantId
      let priority = data.priority
      let cb = data.cb
      let category = data.category
      commit('UPDATE_RECIPE_PRIORITY', data)
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/recipes/priority/${id}/${priority}/${category}`
        if (restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          // toastr.success("Prioritatea retetei a fost actualizata")
          cb()
          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchAllergens({ commit }, search) {
      try {
        let url = `${process.env.VUE_APP_API_URL}/allergens`
        if (search && search.length) {
          url = `${process.env.VUE_APP_API_URL}/allergens?search=${search}`
        }
        // Send api request.
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('SET_ALLERGENS', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchAdditives({ commit }, search) {
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/additives`
        if (search && search.length) {
          url = `${process.env.VUE_APP_API_URL}/additives?search=${search}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('SET_ADDITIVES', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async fetchCategories({ commit }, restaurantId) {
      try {
        // Send api request.
        let url = `${process.env.VUE_APP_API_URL}/categories`
        if (restaurantId) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('SET_CATEGORIES', res.data);

          return true;
        }
      } catch (error) {
        console.log(error)
        return

      }
    },

    async createRecipe({ commit }, formData) {
      let cb = formData.cb
      let restaurantId = formData.restaurantId
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes`
        if (restaurantId) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.post(
          url,
          formData.data,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          commit('ADD_RECIPE', res.data);
          toastr.success("Reteta ta a fost adaugata!")
          cb()
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async createCategory({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/categories`
        if (restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.post(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status === 201) {
          commit('ADD_CATEGORY', res.data);
          if(cb) {
            cb(res.data)
          }
          toastr.success("Categoria ta a fost adaugata!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateCategory({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let data = formData.data
      try {
        let url = `${process.env.VUE_APP_API_URL}/categories`
        if (restaurantId && restaurantId.length) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          data,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('UPDATE_CATEGORY', res.data);
          toastr.success("Categoria ta a fost modificata!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },

    async updateRecipe({ commit }, formData) {
      let restaurantId = formData.restaurantId
      let cb = formData.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/recipes`
        if (restaurantId) {
          url = url + `/?restaurantId=${restaurantId}`
        }
        const res = await axios.put(
          url,
          formData.data,
          {
            withCredentials: true,
          },
        );

        if (res.status === 200) {
          commit('EDIT_RECIPE', res.data);
          toastr.success("Reteta ta a fost actualizata!")
        }
      } catch (error) {
        console.log(error)

        return this.error;
      }
      cb()
    }

  },
};
