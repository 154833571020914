<template>
  <v-expansion-panel-header :class="headerClass">
    <div class="flex padded ml-5">
      <v-checkbox class="recipeActions" style="align-items: center;" :input-value="inputValue(item)"
        @click="select(item)"
        @click.native.stop
        ></v-checkbox>
      <label :class="labelClass">{{item.name || item}} </label>
      
    </div>
    
  </v-expansion-panel-header>
</template>

<script>
  export default {
    props: [
      "item",
      "headerClass",
      "labelClass",
      "select",
      "inputValue"
    ],

    methods: {

      
    }
  };
</script>
<style scoped>
  .padded {
    padding: 10px
  }
</style>