<template>
  <v-list-item class="px-2"> <v-list-item-content>
      <v-list-item-title class="text-white" color="white">{{$store.state.auth.user.name}}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-avatar>
      <v-img :src="logo" @click="$emit('click-button')"></v-img>
    </v-list-item-avatar>
  </v-list-item>

</template>

<script>
export default {
  props: ["logo"],
};
</script>
