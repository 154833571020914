<template>
  <v-layout>
    <v-autocomplete v-bind:key="model.name" class="ml-5 mr-5" v-model="model.ingredientAdditives" label="Cauta aditivi" hide-selected
      text-color="white" :items="additives" item-text="name" item-value="_id" multiple item small-chips :clearable="true"
      :deletable-chips="true" :search-input.sync="searchAdditive1" no-filter return-object  @change="searchAdditive1=''">
      <template slot="selection" slot-scope="data">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip :input-value="data.selected" color="warning" text-color="white" close
              @click:close="removeIngredientAdditive(model, data.item)" v-bind="attrs" v-on="on">
              <strong>{{ data.item.name.split("-")[0] }}</strong>
            </v-chip>
          </template>
          <span>{{ data.item.name }}</span>
        </v-tooltip>

      </template>
    </v-autocomplete>

  </v-layout>
</template>

<script>
export default {
  props: [
    "model",
    "searchAdditives",
    "additives",
    "removeIngredientAdditive"
  ],
  data: () => ({
    dialog: true,
    searchAdditive1: ''
  }),
  watch: {
    searchAdditive1(newVal) {
      this.timeout = setTimeout(() => {
        this.searchAdditives(this.searchAdditive1)
      }, 500);
    },
  },
  methods: {

  },
  computed: {
  },
  created() {
  }
};
</script>