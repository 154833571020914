<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="800">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          style="height: 20px !important"
        />
        <v-card-title>
          <span class="dialogText">
            <b>Esti sigur ca ai folosit ingredientele utilizate in mod real in reteta ta si acestea sunt complete?</b>
            <div>Te rugam sa te asiguri ca:</div>
            <li>ai folosit ingredientele pe care le utilizezi in mod real in reteta ta cu aditivii si alergenii lor;</li>
            <li>ai adaugat ingredientele Sare, Zahar si/sau Grasimi daca le folosesti in reteta.</li>
            
          </span>
         
        </v-card-title>
   
        <v-card-actions class="justify-end">
          
          <v-btn
            class="mainButtonYes"
            @click="save()"
          >Da, salveaza</v-btn>
          <v-btn
            class="mainButtonNo"
            @click="close()"
            color="red"
          >Nu, corectez</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "closeDialog",
    "confirm"
  ],
  data: () => ({
    dialog: true,

  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      this.confirm()
      this.close()
    }
  },
  computed: {
  },
  created() {
  }
};
</script>