<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga unitate de masura</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Nume *"
                  label="Nume *"
                  v-model="model.name"
                  required
                  :rules="[v => !!v || `Completeaza numele`]"
                  outlined
                />
            </div>
            <div class="padded">
              <v-text-field
                  type="text"
                  placeholder="Prescurtare"
                  label="Prescurtare"
                  v-model="model.shortcut"
                  outlined
                />
            </div>
            <div class="padded">
                <v-text-field type="number" label="Cantitate (g) *" placeholder="Cantitate (g) *" v-model="model.amount"
                  required outlined :max="9999.99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;" :rules="[
                    v => !!v || `Completeaza cantitatea`,
                    v => !v || Number(v) > 0 || `Gramajul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Gramajul trebuie sa aiba cel mult 2 zecimale`
                  ]" />
              </div>

              <div class="padded" style="display: flex">
                <label class="label">Cantitatea este imutabila </label>
                <v-checkbox class="" style="align-items: center;" :input-value="model.default"
                  @click="model.default = !model.default"></v-checkbox>
              </div>

              <div class="padded" style="display: flex">
                <label class="label">Se foloseste la produse fara VN </label>
                <v-checkbox class="" style="align-items: center;" :input-value="model.usedInProducts"
                  @click="model.usedInProducts = !model.usedInProducts"></v-checkbox>
              </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "model",
    "closeDialog",
    "saveUnitMeasure"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"]
  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      if(this.$refs.form.validate()) {
        this.saveUnitMeasure(this.model)
        this.close()
      }

    }
  },
  computed: {
  },
  created() {
  }
};
</script>