<template>
  <auth-layout headline="Nume campuri">
      <v-btn 
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        @click="openDialog">
        ADAUGA NUME CAMP
      </v-btn>

      <create-field-name
        v-if="dialog"
        :closeDialog="closeDialog"
        :model="fieldName"
        :saveFieldName="saveFieldName"
      />

       <v-data-table
          :headers="fieldNameHeaders"
          :items="fieldNames"
          
        >
        <template v-slot:[`item.actions`]="props">
            <v-btn text color="accent" class="categoryActions"
              @click="openDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-pen" />
            </v-btn> 
            <v-btn text color="red" class="categoryActions"
              @click="openDeleteDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-trash-can" />
            </v-btn> 
            
          </template>
        </v-data-table>

        <delete-field-name
          v-if="deleteDialog"
          :item="currentFieldName"
          :closeDialog="closeDeleteDialog"
          :deleteFieldName="deleteFieldName"
        />
  </auth-layout>
</template>
<script>
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import CreateFieldName from '../components/Modals/CreateFieldName.vue'
  import DeleteFieldName from '../components/Modals/DeleteFieldName.vue'


  export default {
    data() {
      return {
        headline: 'Dashboard',
        dialog: false,
        loading:false,
        deleteDialog: false,
        fieldName: {
          name: null,
          key: null,
          unitMeasure: null
        },
        // pagination: {},
        currentCategory: null,
        fieldNameHeaders: [
          {
            text: "Nume",
            value: 'name'
          },
          {
            text: "Cheie",
            value: 'key'
          },
          {
            text: "Unitate de masura",
            value: 'unitMeasure.name'
          },
          {
            text: "Actiuni",
            value: 'actions'
          }
        ],

      };
    },
    components: { AuthLayout,CreateFieldName, DeleteFieldName },
    computed: {
      company() {
        return this.$store.state.auth.user.company
      },
      fieldNames() {
        return this.$store.state.restaurants.fieldNames
      },
      
    },
    methods: {
      openDialog(fieldName) {
        this.dialog = true
        if(fieldName) {
          this.fieldName = fieldName
        }
      },
      closeDeleteDialog() {
        this.deleteDialog = false
      },

      openDeleteDialog(fieldName) {
        this.currentFieldName = fieldName
        this.deleteDialog = true
      },
      deleteFieldName(fieldName) {
        this.currentFieldName = null
        this.$store.dispatch('deleteFieldNameById', fieldName._id);
      },
      closeDialog() {
        this.dialog = false
      },
      saveFieldName(model) {
        if(model._id) {
          this.$store.dispatch('updateFieldName', model);
        } else {
          this.$store.dispatch('createFieldName', model);
        }

      },

    },
    created() {
      this.$store.dispatch('fetchFieldNames');
      this.$store.dispatch('fetchUnitMeasureNames');
      this.$store.dispatch('exitImp');
    }
  };
</script>
