import Dashboard from '../views/Dashboard.vue';
import Configurations from '../views/Configurations.vue';
import TransferData from '../views/TransferData.vue';

import Categories from '../views/Categories.vue';
import Ingredients from '../views/Ingredients.vue';
import MenuConfigurations from '../views/MenuConfigurations.vue';

import FieldNames from '../views/FieldNames.vue';
import UnitMeasureNames from '../views/UnitMeasureNames.vue';
import UnitMeasures from '../views/UnitMeasures.vue';
import Tags from '../views/Tags.vue';


import DashboardRecorders from '../views/DashboardRecorders.vue';
import DashboardRecipes from '../views/DashboardRecipes.vue';

import ConfigurationsAdmin from '../views/ConfigurationsAdmin.vue';

import Profile from '../views/Profile.vue';
import Recipe from '../views/Recipe.vue';

import Login from '../views/Auth/Login.vue';
import ForgotPassword from '../views/Auth/ForgotPassword.vue';
import PublicMenu from '../views/PublicMenu.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';

import Register from '../views/Auth/Register.vue';
import store from "../store";

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/restaurants',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: DashboardRecipes,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/dashboardRecorders',
    name: 'DashboardRecorders',
    component: DashboardRecorders,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/configurationsAdmin',
    name: 'ConfigurationsAdmin',
    component: ConfigurationsAdmin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/transferData',
    name: 'TransferData',
    component: TransferData,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  {
    path: '/configurations',
    name: 'Configurari',
    component: Configurations,
    meta: {
      requiresAuth: true
    },

  },
  {
    path: '/fieldNames',
    name: 'FieldNames',
    component: FieldNames,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/unitMeasureNames',
    name: 'UnitMeasureNames',
    component: UnitMeasureNames,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/unitMeasures',
    name: 'UnitMeasures',
    component: UnitMeasures,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/categories',
    name: 'Categorii de retete',
    component: Categories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/menuConfigurations',
    name: 'Configurari meniu',
    component: MenuConfigurations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ingredients',
    name: 'Ingrediente',
    component: Ingredients,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/recipe',
    name: 'Recipe',
    component: Recipe,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/meniu/:id/:recipeId?',
    name: 'Meniu',
    component: PublicMenu,
  },
  {
    path: '*',
    component: Dashboard
  }
];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

const router = new VueRouter({
  routes: routes,
  base: process.env.BASE_URL,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin);
  if (requiresAdmin) {
    if (store.state.auth.user && store.state.auth.user.role == "ADMIN") {
      next()
    } else {
      next({ name: 'Login' })
    }


  } else {
    next()
  }

})
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
//   const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);

//   if (requiresAuth) {  
//     if(store?._state?.data?.auth?.isAuthenticated){
//       next();
//     }
//     else{
//       next({ name: "Login" }); 
//       // store.dispatch('logout');
//     }
//   }

//   // if (requiresGuest && routerAuthCheck) {  
//   //     next({ name: "dashboard" }); 
//   // } 
//   next(); 
// })
export default router;
