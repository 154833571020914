<template>
  <auth-layout headline="Categorii de retete">
      <v-btn 
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        @click="openDialog">
        ADAUGA CATEGORIE
      </v-btn>

      <create-category
        v-if="dialog"
        :closeDialog="closeDialog"
        :model="category"
        :saveCategory="saveCategory"
      />

       <v-data-table
          :headers="categoryHeaders"
          :items="categories"
        >
        <template v-slot:[`item.unitMeasure`]="props">
           <span>
            {{props.item.unitMeasure ? props.item.unitMeasure : 'Grame'}}
           </span>
            
        </template>
        <template v-slot:[`item.actions`]="props">
            <v-btn text color="accent" class="categoryActions"
              @click="openDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-pen" />
            </v-btn> 
            <v-btn text color="red" class="categoryActions"
              @click="openDeleteDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-trash-can" />
            </v-btn> 
            
          </template>
        </v-data-table>

        <delete-category
          v-if="deleteDialog"
          :item="currentCategory"
          :closeDialog="closeDeleteDialog"
          :deleteCategory="deleteCategory"
        />
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import CreateCategory from '../components/Modals/CreateCategory.vue'
  import DeleteCategory from './../components/Modals/DeleteCategory.vue'

  export default {
    data() {
      return {
        headline: 'Dashboard',
        dialog: false,
        deleteDialog: false,
        category: {
          name: null,
          priority: null
        },
        currentCategory: null,
        categoryHeaders: [
          {
            text: "Nume",
            value: 'name'
          },
          {
            text: "Prioritate",
            value: 'priority'
          },
          {
            text: "Unitate de masura",
            value: 'unitMeasure'
          },
         
          {
            text: "Actiuni",
            value: 'actions'
          }
        ],

      };
    },
    components: { AuthLayout, PrimaryButton,CreateCategory, DeleteCategory },
    computed: {
      company() {
        return this.$store.state.auth.user.company
      },
      categories() {
        return this.$store.state.recipes.categories
      },
      userImpId() {
        return this.$route.query.userId
      },
      restaurantId() {
        return this.$route.query.restaurantId
      }
    },
    watch: {
      userImpId() {
        this.$store.dispatch('fetchUserById', this.userImpId);
        this.$store.dispatch('fetchCategories', this.restaurantId);
      }
    },
    methods: {
      openDialog(category) {
        this.dialog = true
        if(category) {
          this.category = category
        }
      },
      closeDeleteDialog() {
        this.deleteDialog = false
      },

      openDeleteDialog(category) {
        this.currentCategory = category
        this.deleteDialog = true
      },
      deleteCategory(category) {
        this.currentCategory = null
        this.$store.dispatch('deleteCategoryById', {id: category._id, restaurantId: this.restaurantId});
      },
      closeDialog() {
        this.dialog = false
      },
      saveCategory(model) {
        if(model._id) {
          this.$store.dispatch('updateCategory', {data: model, restaurantId: this.restaurantId});
        } else {
          this.$store.dispatch('createCategory', {data: model, restaurantId: this.restaurantId});
        }
        
        this.category = {
          name: null,
          priority: null
        }
      },
      updateCategory(model) {
        this.$store.dispatch('updateCategory', {data: model, restaurantId: this.restaurantId});
        this.category = {
          name: null,
          priority: null
        }
      }
    },
    created() {
      if (this.restaurantId) {
        this.$store.dispatch('fetchUserByRestaurantId', this.restaurantId);
        this.$store.dispatch('fetchRestaurantById', this.restaurantId);
        this.$store.dispatch('fetchCategories', this.restaurantId);
      } else {
        this.$store.dispatch('fetchCategories');
      }
      
    }
  };
</script>
