<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          style="height: 20px !important"
        />
        <v-card-title>
          <span class="dialogText">Esti sigur ca vrei sa stergi unitatea de masura 
            <span class="bold">{{item.name}}</span>
            
          ?</span>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn
            class="mainButtonNo"
            @click="close()"
          >Nu</v-btn>
          <v-btn
            color="red"
            class="mainButtonYes"
            @click="save()"
          >Da</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "item",
    "closeDialog",
    "deleteUnitMeasureName"
  ],
  data: () => ({
    dialog: true,

  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      this.deleteUnitMeasureName(this.item)
      this.close()
    }
  },
  computed: {
  },
  created() {
  }
};
</script>