import axios from 'axios';
import toastr from '../../toastr';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    restaurantsFrom: [],
    restaurantsTo: []
  },

  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_RESTAURANTS_FROM(state, restaurants) {
      state.restaurantsFrom = restaurants
    },
    SET_RESTAURANTS_TO(state, restaurants) {
      state.restaurantsTo = restaurants
    },
    
    // SET_RESTAURANTS_TO(state, restaurants) {
    //     state.restaurantsTo = restaurants
    //   },
    
  },

  //===========================
  // ACTIONS
  //===========================
  actions: {

    async fetchRestaurantsFrom({ commit, dispatch }, options) {
      dispatch('fetchRestaurantsForTransfer',  {...options, mutation: 'SET_RESTAURANTS_FROM'})
    },

    async fetchRestaurantsTo({ commit, dispatch }, options) {
      dispatch('fetchRestaurantsForTransfer', {...options, mutation: 'SET_RESTAURANTS_TO'})
    },
    

    async fetchRestaurantsForTransfer({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
      let mutation = options.mutation
      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/forTransfer/?page=${page}&perPage=${perPage}`
        if(search && search.length) {
          url =`${url}&search=${search}`
        }
        const res = await axios.get(
          url,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 200) {
          commit(mutation, res.data)
          
          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },

    async transferData({ commit }, data) {
      let cb = data.cb

      try {
        // Send api request.
        let url =  `${process.env.VUE_APP_API_URL}/restaurants/transfer`

        const res = await axios.post(
          url,
          data.model,
          {
            withCredentials: true,
          },
        );
        // Put user into store.
        if (res.status === 201) {
          toastr.success("Datele au fost transferate")
          cb()

          return true;
        }
      } catch (error) {
        console.log(error)

      }
    },
    

  },
};
