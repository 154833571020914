<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          style="height: 20px !important"
        />
        <v-card-title>
          <span class="dialogText">Esti sigur ca vrei sa stergi restaurantul 
            <span class="bold">{{restaurantName}}</span>, impreuna cu toate datele acestuia
            
          ?</span>
          <span v-if="companyToBeDeleted">
            Compania <span class="bold">{{companyToBeDeleted.companyName}}</span> va fi stearsa.
          </span>
          <span v-if="userToBeDeleted">
            Utilizatorul <span class="bold">{{userToBeDeleted.name}}</span> va fi sters.
          </span>
          
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn
            class="mainButtonNo"
            @click="close()"
          >Nu</v-btn>
          <v-btn
            color="red"
            class="mainButtonYes"
            @click="save()"
          >Da</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "item",
    "closeDialog",
    "deleteRestaurant"
  ],
  data: () => ({
    dialog: true,

  }),
  methods: {
    close() {
      this.closeDialog()
      
    },
    save() {
      this.deleteRestaurant(this.item)
      this.close()
    }
  },
  computed: {
    userName() {
      return this.item && this.item.user ? this.item.user.name : ''
    },
    restaurantName() {
      return this.item ? this.item.restaurantName : ''
    },
    companyToBeDeleted() {
      if(!this.item) {
        return
      }
      let siblingRestaurants = this.item.company.restaurants
      siblingRestaurants = siblingRestaurants.filter(s => s != this.item._id)
      if(siblingRestaurants.length) {
        return
      }
      return this.item.company
    },
    userToBeDeleted() {
      if(!this.item) {
        return
      }
      if(!this.companyToBeDeleted) {
        return
      }
      let siblingCompanies = this.item.user.companies
      siblingCompanies = siblingCompanies.filter(s => s != this.companyToBeDeleted._id)
      if(siblingCompanies.length) {
        return
      }
      return this.item.user
    }
  },
  created() {
  }
};
</script>