<template>
  <auth-layout headline="Ingrediente">
    <v-layout style="margin: 10px">
      <v-btn 
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        @click="openDialog">
        ADAUGA INGREDIENT
      </v-btn>

      <v-divider style="border-block: white;" />

      <v-btn 
        v-if="restaurantId || !isAdmin"
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        color="primary"
        @click="openReplaceDialog">
        INLOCUIESTE INGREDIENT GLOBAL
      </v-btn>
    </v-layout>

    <replace-ingredient
      v-if="replaceDialog"
      :closeDialog="closeReplaceDialog"
    />

    <create-ingredient
      v-if="dialog"
      :closeDialog="closeDialog"
      :model="newIngr"
      :callback="searchIngredients"
    />

    <v-data-table
        :headers="ingredientHeaders"
        :items="ingredients"
        :search="search"
        :options.sync="options"
        :server-items-length="totalIngredients"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100],
          
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            
          ></v-text-field>
        </template>
        <template v-slot:[`item.energyKJ`]="props">
          <p class="tableData">
            {{ getIngredientValue(props.item, 'energyKJ') }} / {{ getIngredientValue(props.item, 'energy') }}
          </p>
        </template>
        <template v-slot:[`item.actions`]="props">
          <v-btn  class="categoryActions"
            v-if="!props.item.isRecipe"
            @click="openDialog(props.item)"
            color="accent" text
          >
            <font-awesome-icon  icon="fa-solid fa-pen" />
          </v-btn> 
          <v-btn  class="categoryActions" v-if="props.item.createdBy && !props.item.isRecipe" color="red"  
            @click="openDeleteDialog(props.item)" text
          >
            <font-awesome-icon  icon="fa-solid fa-trash-can" />
          </v-btn> 
          
        </template>
    </v-data-table>

    <delete-ingredient
      v-if="deleteDialog"
      :item="currentIngredient"
      :closeDialog="closeDeleteDialog"
      :deleteIngredient="deleteIngredient"
    />
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import CreateIngredient from '../components/Modals/CreateIngredient.vue'
  import DeleteIngredient from '../components/Modals/DeleteIngredient.vue';
  import {nutriKeys} from '../common/UnitMeasures'
import ReplaceIngredient from '../components/Modals/ReplaceIngredient.vue';


  export default {
    data() {
      return {
        dialog: false,
        replaceDialog: false,
        options: {},
        search: '',
        deleteDialog: false,
        category: {
          name: null,
          priority: null
        },
        loading:false,
        newIngr: {
          name: null,
          energy: null,
          energyKJ: null,
          fat: null,
          saturated_fat: null,
          carbohydrates: null,
          sugars: null,
          proteins: null,
          fiber: null,
          sodium: null,
          category: null,
          serving: {name: 'Grame', amount: 1}
        },
        currentIngredient: null,
        categoryHeaders: [
          {
            text: "Nume",
            value: 'name'
          },
          {
            text: "Prioritate",
            value: 'priority'
          },
         
          {
            text: "Actiuni",
            value: 'actions'
          }
        ],

      };
    },
    components: { AuthLayout, PrimaryButton, DeleteIngredient, CreateIngredient, ReplaceIngredient },
    computed: {
      // company() {
      //   return this.$store.state.auth.user.company
      // },
      isAdmin() {
        return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
      },
      categories() {
        return this.$store.state.recipes.categories
      },
      ingredients() {
        return this.$store.state.ingredients.userIngredients.map(i => {
          i.sodium = i.sodium ? Math.round(i.sodium * 100) / 100 : 0
          return i
        })
      },
      totalIngredients() {
        return this.$store.state.ingredients.totalIngredients
      },
      fieldNames() {
        return this.$store.state.recipes.fieldNames
      },
      // userImpId() {
      //   return this.$route.query.userId
      // },
      restaurantId() {
        return this.$route.query.restaurantId
      },
      
      ingredientHeaders() {
        let headers = [
          {
            text: "Nume",
            value: 'name',
            sortable: false,
            width: "150px"
          }
        ]

        // la energie le combinam!!!

        nutriKeys.map(key => {
          if(key == 'energy') {
            return
          }

          if(key == 'energyKJ') {
              headers.push({
              text: `${this.fieldNames[key]?.displayName} (${this.fieldNames[key]?.unitMeasure} / ${this.fieldNames['energy']?.unitMeasure})`,
              value: key,
              sortable: false
            })
          } else {
            headers.push({
              text: `${this.fieldNames[key]?.displayName} (${this.fieldNames[key]?.unitMeasure})`,
              value: key,
              sortable: false
            })

          }
          
        })

        headers.push(
          {
            text: "Adaugat de",
            value: 'createdBy.name',
            sortable: false
          },
          
          {
            text: "Actiuni",
            value: 'actions',
            sortable: false
          }
        )

        return headers


      },
    },
    watch: {
      options() {
        this.searchIngredients()
      },
      search(newVal) {
        this.timeout = setTimeout(() => {
          this.searchIngredients()
        }, 500);
      },
      restaurantId() {
        this.$store.dispatch('fetchRestaurantById', this.restaurantId);
        this.$store.dispatch('fetchUserIngredients', {page: 1, perPage: 25, search: '', restaurantId: this.restaurantId});
      }
      
    },
    methods: {
      getIngredientValue(item, field) {
        let val = Math.round(item[field] * 100) / 100
        let unitMeasure = this.fieldNames[field]?.unitMeasure
        return `${val} ${unitMeasure}`

      },
      searchIngredients() {
        this.$store.dispatch('fetchUserIngredients', {
          page: this.options.page, 
          perPage: this.options.itemsPerPage, 
          search: this.search,
          restaurantId: this.restaurantId
        })
      },
      openReplaceDialog() {
        this.replaceDialog = true
      },
      closeReplaceDialog() {
        this.replaceDialog = false
      },
      openDialog(ingredient) {
        this.dialog = true
        if(ingredient && ingredient.name) {
          
          if(ingredient.servings && ingredient.servings.length) {
            ingredient.serving = ingredient.servings[0]
          } else {
            ingredient.serving = {name: 'Grame', amount: 1}
          }
          this.newIngr = ingredient
        } else {
          this.newIngr = {
            name: null,
            energy: null,
            energyKJ: null,
            fat: null,
            saturated_fat: null,
            carbohydrates: null,
            sugars: null,
            proteins: null,
            fiber: null,
            sodium: null,
            category: null,
            serving: {name: 'Grame', amount: 1}
          }
        }
      },
      deleteIngredient(ingredient) {
        this.currentIngredient = null
        this.$store.dispatch('deleteIngredientById', {id: ingredient._id, restaurantId: this.restaurantId});
      },
      closeDeleteDialog() {
        this.deleteDialog = false
      },
      openDeleteDialog(ingredient) {
        this.currentIngredient = ingredient
        this.deleteDialog = true
      },
      closeDialog() {
        this.dialog = false
        this.newIngr = {
          name: null,
          energy: null,
          energyKJ: null,
          fat: null,
          saturated_fat: null,
          carbohydrates: null,
          sugars: null,
          proteins: null,
          fiber: null,
          sodium: null,
          category: null,
          serving: {name: 'Grame', amount: 1}
        }
      },
    },
    created() {
      if (this.restaurantId) {
        this.$store.dispatch('fetchRestaurantById', this.restaurantId);
        this.$store.dispatch('fetchUserIngredients', {page: 1, perPage: 25, search: '', restaurantId: this.restaurantId});
      } else {
        this.$store.dispatch('fetchUserIngredients', {page: 1, perPage: 25, search: ''});
      }
      
      this.$store.dispatch('fetchFieldNamesForUI');
    }
  };
</script>
