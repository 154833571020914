<template>
  <base-layout :headline="headline">
    <template #header>
      <authenticated-navbar :user="$store.state.auth.user"></authenticated-navbar>
    </template>

    <div v-if="$store.state.auth.loadingUser">
      <!-- Loading spinner -->
      <spinner size="large"></spinner>
    </div>
    <div  v-else-if="
      !$store.state.auth.loadingUser && $store.state.auth.isAuthenticated
        ">
      <!-- Child components -->
      <slot></slot>
    </div>
    <div v-else  >You are not authorized to access this page.
   
      <div class="w-full h-full flex items-center justify-center">
        <card class="w-6/12 h-6/12" center="true">

          <router-link to="/login">
              <span class="text-blue-700 underline">Login</span>
            </router-link>
            <br>
            <v-divider style="border-block: white;"/>
          <span class="mb-6">Don't have an account yet?
            <router-link to="/register">
              <span class="text-blue-700 underline">Register</span>
            </router-link></span>
        </card>
      </div>
    </div>
  </base-layout>
</template>

<script>
import AuthenticatedNavbar from '../components/Navigation/AuthenticatedNavbar.vue';
import BaseLayout from './BaseLayout.vue';
import Spinner from '../components/Widgets/Spinner/Spinner.vue';

export default {
  data() {
    return {
      loading: true,
    };
  },
  props: ['headline'],
  components: { BaseLayout, AuthenticatedNavbar, Spinner },
  async beforeCreate() {
    await this.$store.dispatch('fetchUser');
    await this.$store.dispatch('fetchRestaurants', {});
    if (
        !this.$store.state.auth.loadingUser &&
        !this.$store.state.auth.isAuthenticated
      ) {
        this.$router.push('/login');
      } 
      // else if(!this.$route.name) {
        // if(this.$store.state.restaurants.restaurants.length == 1 && (!this.$route.name)) {
        //   let restaurantId = this.$store.state.restaurants.restaurants[0]._id
        //   this.$router.push(`/recipes/?restaurantId=${restaurantId}`);
        // } else {
        //   this.$router.push('/restaurants');
        // }
        
      // }
      // if (
      //   !this.$store.state.auth.loadingUser &&
      //   this.$store.state.auth.isAuthenticated
      // ) {
      //   this.$router.push('/dashboard');
      // }
      this.loading = false;
  },
};
</script>
