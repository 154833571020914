<template>
  <auth-layout :headline="restaurant ? restaurant.restaurantName : ''">
    <div class="p-4">
      <div class="mb-4 flex">
        <section-headline>
          {{ headline }}
        </section-headline>
      </div>

      <v-form class="w-full" ref="form1">
        <div class="flex">
          <v-row>
            <v-col cols="12" md="4" sm="12">
              <div class="padded">
                <v-text-field ref='focusMe' label="Nume reteta *" type="text" placeholder="Nume reteta *"
                  v-model="model.name" required :rules="[v => !!v || `Completeaza numele retetei`,
                  v => v.length >= 5 || `Numele trebuie sa contina cel putin 5 caractere`,
                  v => v.length <= 100 || `Numele trebuie sa contina cel mult 100 de caractere`
                  ]" outlined />
              </div>
              <v-subheader style="color: red; height: 2px; line-height: 12px;
                                                  word-break: break-word;
                                                  overflow-wrap: break-word;
                                                  word-wrap: break-word;
                                                  -webkit-hyphens: auto;
                                                  hyphens: auto;" v-if="formErrors.name && formErrors.name.length">
                {{ formErrors.name }}
              </v-subheader>


            </v-col>
            <v-col cols="12" md="2" sm="12">
              <div class="padded">
                <v-text-field type="number" label="Numar portii *" placeholder="Numar portii *" v-model="model.portions"
                  :max="999" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;" :rules="[v => !!v || `Completeaza numarul de portii`,
                  v => Number(v) > 0 && Number.isInteger(Number(v)) || `Numarul de portii trebuie sa fie pozitiv si intreg`
                  ]" required outlined />
              </div>


            </v-col>
            <v-col cols="12" md="2" sm="12">
              <div class="padded">
                <v-text-field type="number" :label="`Greutate neta portie gatita (${unitMeasureForRecipe})*`"
                  placeholder="`Greutate neta portie gatita (${unitMeasureForRecipe})*`" v-model="model.totalWeight" required outlined
                  :max="99999.99" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;" :rules="[
                    v => !!v || `Completeaza greutatea`,
                    v => !v || Number(v) > 0 || `Gramajul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Gramajul trebuie sa aiba cel mult 2 zecimale`
                  ]">
                  <template v-slot:prepend>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="orange" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>

                      </template>
                      <span>Camp cu caracter informativ pentru client. Este luat in calcul in afisarea valorilor
                        nutritionale pe portie neta</span>
                    </v-tooltip>

                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <div class="padded">
                <v-text-field type="number" label="Pret vanzare" placeholder="Pret vanzare" v-model="model.price" required
                  outlined :max="9999.99" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :rules="[
                    v => !v || Number(v) > 0 || `Pretul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Pretul trebuie sa aiba cel mult 2 zecimale`
                  ]" />
              </div>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <div class="padded">
                <v-autocomplete label="Selecteaza categoria retetei *" v-model="model.category" :items="recipeCategories"
                  color="primary" :rules="[v => !!v || `Alege categoria retetei`]" item outlined item-text="name"
                  item-value="_id" return-object>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Nu ai nicio categorie adaugata. 
                        <v-btn 
                          type="button"
                          class="mainButtonYes"
                          @click="openCategoryDialog">
                          ADAUGA CATEGORIE
                        </v-btn>
                        <!-- Mergi la
                        <strong>Configurari -> Categorii de retete</strong> -->
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="ingredientError" class="errorMessage padded">{{ ingredientError }}</div>
        <div class="flex padded">

          <v-autocomplete label="Cauta ingredient" outlined v-model="currentIngredient" :items="ingredientsItems"
            color="primary" item item-text="displayName" item-value="_id" :search-input.sync="search" return-object
            :clearable="true" hide-no-data hide-selected no-filter @change="pickIngredient(currentIngredient)"
            :loading="isLoading" prepend-icon="mdi-database-search">

            <template slot="item" slot-scope="{ item, tile }">

              <p>
                <v-tooltip top v-if="item.isCustom && !item.isRecipe">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="orange" v-bind="attrs" v-on="on"> mdi-account-star </v-icon>

                  </template>
                  <span>Ingredient adaugat de tine</span>
                </v-tooltip>
                <v-tooltip top v-if="item.explanation && !item.isCustom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" v-bind="attrs" v-on="on"> mdi-storefront </v-icon>

                  </template>
                  <span>Ingredient compus cumparat, ambalat</span>
                </v-tooltip>
                <v-tooltip top v-if="item.isRecipe">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" v-bind="attrs" v-on="on"> mdi-bowl-mix </v-icon>

                  </template>
                  <span>Reteta adaugata de tine</span>
                </v-tooltip>

                <b> {{ item.manufacturerName }}</b> {{ item.name }}<br>
                <small class="small" v-if="item.explanation">{{ item.explanation }}<br></small>
                <small>
                  <v-chip small color="accent" v-if="item.allergens
                    && item.allergens.length > 0">{{ item.allergens.length }}
                    Alergeni
                  </v-chip>
                  <v-chip small color="primary" v-if="item.potentialAllergens
                    && item.potentialAllergens.length > 0">{{ item.potentialAllergens.length }}
                    Potentiali Alergeni
                  </v-chip>
                  <v-chip small color="orange" v-if="item.additives
                    && item.additives.length > 0">{{ item.additives.length }}
                    Aditivi
                  </v-chip>

                </small>
              </p>


            </template>
          </v-autocomplete>


          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="align-self: inherit; border: none; height: 55px; margin-left: 5px" v-bind="attrs" v-on="on" type="button"
                @click="openIngredientDialog" color="secondary" >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </v-btn>
            </template>
            <span>Adauga ingredient</span>
          </v-tooltip>
        </div>
        <div class="ingredientInfo">Asigura-te ca ingredientele selectate sunt cele pe care le folosesti real in reteta, in cazul in care nu gasesti ingredientul tau il poti adauga chiar tu.</div>
      </v-form>

      <v-form ref="form2" class="padded">
        <v-data-table :headers="ingredientHeaders" :items="recipeIngredients" disable-filtering
          :hide-default-footer="true" :items-per-page="-1">

          <template v-slot:[`item.name`]="props">
            <p class="tableData">
              <div class="ingredientMeasure">
                <v-text-field 
                  :rules="[v => !!v || `Completeaza numele ingredientului`]"
                  v-model="props.item.name"
                />
              </div>
              <!-- {{ props.item.name }} -->
            </p>
          </template>
          <template v-slot:[`item.quantity`]="props">
            <div class="ingredientMeasure">

              <div>
                <v-text-field type="number" placeholder="Cantitate" :max="99999.99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;" :rules="[
                    v => !!v || `Completeaza cantitatea`,
                    v => Number(v) > 0 || `Cantitatea trebuie sa fie pozitiva`,
                    v => /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Cantitatea trebuie sa aiba cel mult 2 zecimale`
                  ]" v-model="props.item.quantity" @change="updateWeight(props.item)" required />
              </div>

              <div>
                <v-autocomplete v-model="props.item.unitMeasure" :items="props.item.ingredient.servings"
                  color="blue-grey-lighten-2" :rules="[v => !!v || `Alege unitatea de masura`]"
                  @change="updateWeight(props.item)" item item-text="name" item-value="name"
                  return-object></v-autocomplete>

              </div>

            </div>

          </template>
          <template v-slot:[`item.energyKJ`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'energyKJ') }} / {{ getIngredientValue(props.item, 'energy') }}
            </p>
          </template>
          <template v-slot:[`item.proteins`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'proteins') }}
            </p>
          </template>
          <template v-slot:[`item.fat`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'fat') }}
            </p>
          </template>
          <template v-slot:[`item.saturated_fat`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'saturated_fat') }}
            </p>
          </template>
          <template v-slot:[`item.carbohydrates`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'carbohydrates') }}
            </p>
          </template>
          <template v-slot:[`item.sugars`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'sugars') }}
            </p>
          </template>
          <template v-slot:[`item.sodium`]="props">
            <p class="tableData">
              {{ getIngredientValue(props.item, 'sodium') }}
            </p>
          </template>
          <template v-slot:[`item.isFrozen`]="props">
            <p class="tableData">
              <v-checkbox class="recipeActions" :input-value="props.item.isFrozen"
                @click="props.item.isFrozen = !props.item.isFrozen"></v-checkbox>
            </p>
          </template>

          <template v-slot:[`item.allergens`]="props">
            <v-autocomplete class="ml-5 mr-5" d-flex v-model="props.item.allergens" label="Cauta alergeni" hide-selected
              text-color="white" :items="allergens" item-text="name" multiple small-chips :clearable="true"
              :deletable-chips="true" return-object>
              <template slot="selection" slot-scope="data">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip :input-value="data.selected" color="accent" text-color="white" close
                      @click:close="removeAllergen(data.item, props.item)" v-bind="attrs" v-on="on">
                      <strong>{{ data.item.name.charAt(0) }}</strong>

                    </v-chip>
                  </template>
                  <span>{{ data.item.name }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </template>
          <template v-slot:[`item.potentialAllergens`]="props">
            <v-autocomplete class="ml-5 mr-5" v-model="props.item.potentialAllergens" :items="allergens" :clearable="true"
              :deletable-chips="true" label="Cauta alergeni" hide-selected text-color="white" item-text="name" d-flex
              multiple small-chips return-object>
              <template slot="selection" slot-scope="data">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip :input-value="data.selected" color="primary" text-color="white" close
                      @click:close="removePotentialAllergen(data.item, props.item)" v-bind="attrs" v-on="on">
                      <strong>{{ data.item.name.charAt(0) }}</strong>


                    </v-chip>
                  </template>
                  <span>{{ data.item.name }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </template>
          <template v-slot:[`item.ingredientAdditives`]="props">
            <autocomplete-additives :model="props.item" :searchAdditives="searchAdditives" :additives="additives"
              :removeIngredientAdditive="removeIngredientAdditive" />
          </template>
          <template v-slot:[`item.actions`]="props">
            <p class="tableData" style="justify-content: center; display: flex;">
              <v-btn color="red" @click="removeIngredient(props.item)" icon>
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </p>
          </template>
        </v-data-table>
      </v-form>

      <div class="flex padded">
        <v-autocomplete class="" d-flex v-model="model.additives" label="Cauta aditivi" hide-selected
          text-color="white" :items="additives" item-text="name" outlined multiple item small-chips
          :clearable="true" :deletable-chips="true" :search-input.sync="searchAdditive" no-filter return-object
          @change="searchAdditive = ''">
          <template slot="selection" slot-scope="data">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip :input-value="data.selected" color="warning" text-color="white" close
                  @click:close="removeAdditive(data.item)" v-bind="attrs" v-on="on">
                  <strong>{{ data.item.name.split("-")[0] }}</strong>
                </v-chip>
              </template>
              <span>{{ data.item.name }}</span>
            </v-tooltip>

          </template>
        </v-autocomplete>
      </div>

      <div class=" mb-10">
        <v-row cols="12" md="2" sm="12" class="weightInfo">
          Greutatea ingredientelor: {{getTotalRecipeWeight()}}g
        </v-row>
        <v-row>
          <v-col cols="12" md="2" sm="12"> <v-radio-group inline label="Valori per" v-model="displayMode">
              <v-radio label="portie bruta" :value="1"></v-radio>
              <v-radio label="portie neta" :value="2"></v-radio>
              <v-radio label="100g" :value="3"></v-radio>
            </v-radio-group></v-col>
          <v-col cols="12" md="7" sm="12">
            <div v-if="displayMode == 1">
              <NutriInfoTable sectionHeadline="Valori per portie bruta" :item="getValuesPerPortions()" />
            </div>
            <div v-if="displayMode == 2">
              <NutriInfoTable sectionHeadline="Valori per portie neta" :item="getValuesPerPortionsNet()" />
            </div>
            <div v-if="displayMode == 3">
              <NutriInfoTable sectionHeadline="Valori per 100g" :item="getValuesPer100g()" />
            </div>
          </v-col>
          <v-divider :thickness="3" class="border-opacity-50" vertical></v-divider>
          <v-col cols="12" md="3" sm="12" class="grid">
            <v-combobox clearable label="Adauga Etichete" v-model="model.tags"
             underlined  class="left  paddedLeft" density="compact"
              :items="tags" item-text="name" item-value="_id" color="primary" return-object multiple chips>
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  close
                  color="primary" text-color="white" 
                  @click:close="data.parent.selectItem(data.item)"
                >
                  
                  {{ data.item.name }}
                </v-chip>
          </template>
            </v-combobox>
            <div class="flex padded ml-5">
              <label class="label" for="isCustomIngredient">Foloseste pentru alte retete </label>
              <v-checkbox class="recipeActions" style="align-items: center;" :input-value="model.isCustomIngredient"
                @click="model.isCustomIngredient = !model.isCustomIngredient" name="isCustomIngredient">

              </v-checkbox>
              <v-divider style="max-width: 40px; border-block: white;" />
              <label class="label">Exclude din meniu </label>
              <v-checkbox class="recipeActions" style="align-items: center;" :input-value="model.excludeFromMenu"
                @click="model.excludeFromMenu = !model.excludeFromMenu"></v-checkbox>
            </div>
          </v-col>
        </v-row>




      </div>


      <v-row align="center" justify="center" class="mb-5">
        <v-btn :loading="isSaving" :disabled="isSaving" @click="openConfirmDialog()" color="accent" class="mainButtonYes mr-5">{{
          'Salveaza' }}
        </v-btn>
        <v-btn :loading="false" :disabled="isSaving" @click="cancel()" color="red" class="mainButtonNo">{{ 'Renunta' }}

        </v-btn>
      </v-row>

    </div>

    <create-ingredient v-if="ingredientDialog" :closeDialog="closeIngredientDialog" :model="newIngr" />
    <create-category
        v-if="categoryDialog"
        :closeDialog="closeCategoryDialog"
        :model="category"
        :saveCategory="saveCategory"
      />
      <confirm-create-recipe v-if="confirmDialog" :closeDialog="closeConfirmDialog" :confirm="confirm"/>

  </auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import InputField from '../components/Form/Input.vue';
import Select from '../components/Form/Select.vue';
import NutriInfoTable from '../components/Tables/NutriInfoTable.vue'
import { nutriKeys } from '../common/UnitMeasures'
import SubHeader from '../components/Typography/SubHeader.vue';
import CreateIngredient from '../components/Modals/CreateIngredient.vue'
import AutocompleteAdditives from '../components/Form/AutocompleteAdditives.vue';
import CreateCategory from '../components/Modals/CreateCategory.vue'
import ConfirmCreateRecipe from '../components/Modals/ConfirmCreateRecipe.vue'
import toastr from '../toastr';
import axios from "axios"


export default {
  components: {
    AuthLayout,
    SectionHeadline,
    InputField,
    Select,
    NutriInfoTable,
    SubHeader,
    CreateIngredient,
    AutocompleteAdditives,
    CreateCategory,
    ConfirmCreateRecipe
  },
  data() {
    return {
      headline: 'Adauga reteta',
      ingredientDialog: false,
      displayMode: 3,
      isSaving: false,
      categoryDialog: false,

      newIngr: {
        name: null,
        energy: null,
        energyKJ: null,
        fat: null,
        saturated_fat: null,
        carbohydrates: null,
        sugars: null,
        proteins: null,
        fiber: null,
        sodium: null,
        category: null,
        serving: { name: 'Grame', amount: 1 }
      },
      model: {
        name: '',
        portions: 1,
        price: null,
        category: null,
        totalWeight: 0,
        additives: []
      },
      formErrors: {
        name: null,
        portions: null
      },
      sectionHeadline: "Adauga reteta",
      ingredientError: null,
      currentIngredient: { _id: null, name: null },
      recipeIngredients: [],
      editWeight: 0,
      queryTerm: '',
      search: '',
      searchAllergen: '',
      searchpotentialAllergens: '',
      searchAdditive: '',
      searchAdditive1: '',
      sel: [],
      count: 0,
      isLoading: false,
      originalName: '',
      originalIngredients: [],
      category: {
        name: null,
        priority: null
      },
      confirmDialog: false,
      abortController: null,
    };
  },

  watch: {
    recipe(newVal) {
      this.model = newVal
      if (!this.model) {
        return
      }
      this.recipeIngredients = this.model.ingredients.map(i => {
        i.ingredientAdditives = i.ingredientAdditives ? i.ingredientAdditives.map(a => {
          return this.formatAdditiveForView(a)
        }) : []
        return i
      })

      this.model.additives = this.model.additives.map(a => {
        return this.formatAdditiveForView(a)
      })

      // stergem numele daca e clonare!
      if (this.isEdit) {
        this.originalName = this.model.name
        this.originalIngredients = this.model.ingredients
      } else if (this.isClone) {
        this.model.name = ''
      }

    },
    search(newVal) {
      if (!newVal) {
        return
      }

      this.clearEntries()
      this.isLoading = true
      this.fetchEntriesDebounced()
    },
    searchAdditive(newVal) {
      this.timeout = setTimeout(() => {
        this.searchAdditives(this.searchAdditive)
      }, 500);
    },
    searchAdditive1(newVal) {
      this.timeout = setTimeout(() => {
        this.searchAdditives(this.searchAdditive1)
      }, 500);
    },
    currentIngredient(val, oldval) {
      if (val._id) {
        this.pickIngredient(val)
      }
    },
  },

  computed: {
    isClone() {
      return this.$route.query.copy
    },
    page() {
      return this.$route.query.page || 1
    },
    perPage() {
      return this.$route.query.perPage || 28
    },
    isEdit() {
      return this.$route.query.id && !this.$route.query.copy
    },
    isAdmin() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
    },
    userImpId() {
      return this.$route.query.userId
    },
    restaurantId() {
      let id = this.$route.query.restaurantId
      if ((!id || !id.length) && this.onlyOneRestaurant) {
        id = this.$store.state.restaurants.restaurants[0]._id
      }
      return id
    },
    restaurant() {
      return this.$store.state.auth.restaurant
    },
    onlyOneRestaurant() {
      return this.$store.state.restaurants.realTotalRestaurants == 1
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    unitMeasureForRecipe() {
      let unitMeasure = this.model && this.model.category && this.model.category.unitMeasure ? this.model.category.unitMeasure : 'Grame'
      return unitMeasure == 'Mililitri' ? 'ml' : 'g'
    },
    ingredientHeaders() {


      let headers = [
        {
          text: "Actiuni",
          value: 'actions',
          sortable: false,
          width: "50px"
        },
        {
          text: "Nume",
          value: 'name',
          sortable: false,
          width: "150px"
        },
        {
          text: "Cantitate*",
          value: 'quantity',
          sortable: false,
          width: "200px"
        },
      ]

      // la energie le combinam!!!
      nutriKeys.map(key => {
        if (key == 'energy') {
          return
        }

        if (key == 'energyKJ') {
          headers.push({
            text: `${this.fieldNames[key]?.displayName} (${this.fieldNames[key]?.unitMeasure} / ${this.fieldNames['energy']?.unitMeasure})`,
            value: key,
            sortable: false,
            // width: "100px"
          })
        } else {
          headers.push({
            text: `${this.fieldNames[key]?.displayName}`,
            value: key,
            width: "50px",
            sortable: false
          })

        }
      })

      headers.push(
        {
          text: "Congelat?",
          value: 'isFrozen',
          sortable: false
        },
        {
          text: "Alergeni",
          value: 'allergens',
          sortable: false,

        },
        {
          text: "Potentiali\nAlergeni",
          value: 'potentialAllergens',
          sortable: false,

        },
        {
          text: "Aditivi",
          value: 'ingredientAdditives',
          sortable: false,

        }
      )



      return headers
    },
    fieldNames() {
      return this.$store.state.recipes.fieldNames
    },
    // company() {
    //   return this.$store.state.auth.user.company
    // },
    recipe() {
      return this.$store.state.recipes.recipe
    },
    recipeCategories() {
      return this.$store.state.recipes.categories
    },
    allergens() {
      return this.$store.state.recipes.allergens
    },
    ingredientAdditives() {
      let ingredientAdditives = []
      this.recipeIngredients.map(i => {
        if (i.ingredientAdditives) {
          ingredientAdditives = ingredientAdditives.concat(i.ingredientAdditives)
        }
      })
      return ingredientAdditives
    },
    additives() {
      return this.$store.state.recipes.additives.map(a => this.formatAdditiveName(a))
        .concat(this.model.additives.map(a => this.formatAdditiveName(a)))
        .concat(this.ingredientAdditives.map(a => this.formatAdditiveName(a)))
    },
    ingredients() {
      return this.$store.state.ingredients.ingredients
    },
    ingredientsItems(clear) {
      // si la alea care sunt retete sa nu fie in ele insele
      // care nu sunt deja bagate!
      let existingIngredients = this.recipeIngredients.map(i => i.ingredient._id)
      let selfId = this.model._id
      return this.$store.state.ingredients.ingredients.filter(ingredient => {
        return !existingIngredients.includes(ingredient._id) && !(ingredient.isRecipe && ingredient.recipe == selfId)
      }).map(i => {
        // i.displayName = i.isCustom ? `${i.name} *` : i.name
        return i
      })
    },
    tags() {
      return this.$store.state.tags.tags
    },
    isRecorder() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'DATA_RECORDER'
    },
  },

  methods: {
    closeCategoryDialog() {
      this.categoryDialog = false
    },
    openCategoryDialog() {
      this.categoryDialog = true
    },
    confirm() {
      this.save()
    },
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    openConfirmDialog() {
      if (!this.$refs.form1.validate() || !this.$refs.form2.validate()) {
        return
      }
      if(this.isRecorder) {
        this.save()
      } else {
        this.confirmDialog = true
      }
      
    },
    saveCategory(model) {
      this.$store.dispatch('createCategory', {data: model, restaurantId: this.restaurantId, cb: (category) => {
        this.model.category = category
      }});
    },
    formatAdditiveForView(additive) {
      return {
        _id: additive.additive,
        name: additive.name,
        category: additive.category,
        categories: additive.category
      }
    },
    formatAdditiveName(additive) {
      if (additive.name.includes(additive.categories)) {
        return additive
      }
      additive.name = additive.name + ' (' + additive.categories + ')'
      return additive
    },
    clearEntries() {
      this.count = 0
      this.$store.state.ingredientsItems = []
    },
    fetchEntriesDebounced() {
      // get the CancelToken object from axios
    
      clearTimeout(this._searchTimerId)
      const abortController = new AbortController()

      console.log("CS: ", this.abortController)
      if(this.abortController) {
        console.log("CANCELED")
        this.abortController.abort()
      }
      
      this.abortController = abortController
      this._searchTimerId = setTimeout(() => {
        this.searchIngredients(this.abortController)
        this.isLoading = false
      }, 500) /* 500ms throttle */
    },
    openIngredientDialog() {
      this.ingredientDialog = true
      this.newIngr = {
        name: null,
        energy: null,
        energyKJ: null,
        fat: null,
        saturated_fat: null,
        carbohydrates: null,
        sugars: null,
        proteins: null,
        fiber: null,
        sodium: null,
        category: null,
        serving: { name: 'Grame', amount: 1 }
      }
    },
    closeIngredientDialog() {
      this.ingredientDialog = false
      this.newIngr = {
        name: null,
        energy: null,
        energyKJ: null,
        fat: null,
        saturated_fat: null,
        carbohydrates: null,
        sugars: null,
        proteins: null,
        fiber: null,
        sodium: null,
        category: null,
        serving: { name: 'Grame', amount: 1 }
      }
    },
    updateWeight(recipeIngredient) {
      // weight
      recipeIngredient.weight = recipeIngredient.quantity * recipeIngredient.unitMeasure.amount
      this.calculateIngredient(recipeIngredient)
    },
    removeAllergen(allergen, ingredient) {
      ingredient.allergens = ingredient.allergens.filter(a => {
        return a._id != allergen._id
      })
    },
    removePotentialAllergen(allergen, ingredient) {
      ingredient.potentialAllergens = ingredient.potentialAllergens.filter(a => {
        return a._id != allergen._id
      })
    },
    removeAdditive(additive) {
      this.model.additives = this.model.additives.filter(a => {
        return a._id != additive._id
      })
    },
    removeIngredientAdditive(ingredient, additive) {
      ingredient.ingredientAdditives = ingredient.ingredientAdditives.filter(a => {
        return a._id != additive._id
      })
    },
    searchIngredients(abortController) {
      let search = this.search ? this.search.substring(0, 60) : ''
      this.$store.dispatch("fetchIngredients", { search: search, restaurantId: this.restaurantId, abortController: abortController })
    },
    searchAllergens() {
      this.$store.dispatch("fetchAllergens", this.searchAllergen)
    },
    searchAdditives(search) {
      this.$store.dispatch("fetchAdditives", search)
    },
    saveRecipe(recipe, callback) {
      this.$store.dispatch('createRecipe', {
        restaurantId: this.restaurantId,
        data: recipe, cb: () => {
          this.$store.dispatch('fetchUser');
          callback()
        }
      });
    },
    updateRecipe(recipe, callback) {
      this.$store.dispatch('updateRecipe', {
        data: recipe, restaurantId: this.restaurantId, cb: () => {
          callback()
        }
      });
    },
    removeIngredient(ingredient) {
      this.recipeIngredients = this.recipeIngredients.filter(i => {
        return i.ingredient._id != ingredient.ingredient._id
      })
    },
    save() {
      if (!this.$refs.form1.validate() || !this.$refs.form2.validate()) {
        return
      }

      if (this.isClone) {
        delete this.model._id
      }
      this.model.recipeIngredients = this.recipeIngredients.map(i => {
        i.weight = Number(i.weight)
        i.quantity = Number(i.quantity)
        return i
      })

      let hasError = this.verifyIngredientWeights()
      if (hasError) {
        return
      }

      hasError = this.verifyIngredientsExist()
      if (hasError) {
        return
      }

      this.model.portions = Number(this.model.portions)
      this.isSaving = true

      // daca e clonare, stergem id ul......
      if (this.model._id) {
        this.updateRecipe(this.model, () => {
          this.$router.push(`/recipes/?restaurantId=${this.restaurantId}&page=${this.page}&perPage=${this.perPage}`)
          this.recipeIngredients = []
          this.isSaving = false
        })
      } else {
        this.saveRecipe(this.model, () => {
          this.$router.push(`/recipes/?restaurantId=${this.restaurantId}&page=${this.page}&perPage=${this.perPage}`)
          this.recipeIngredients = []
          this.isSaving = false
        })
      }
    },
    cancel() {
      this.$router.push(`/recipes/?restaurantId=${this.restaurantId}&page=${this.page}&perPage=${this.perPage}`)
    },
    verifyIngredientsExist() {
      if (!this.model.recipeIngredients || !this.model.recipeIngredients.length) {
        this.ingredientError = "Alegeti cel putin un ingredient!"
        return true
      }
      return false
    },
    verifyIngredientWeights() {
      let hasError = false
      this.model.recipeIngredients.map(i => {
        if (!i.weight) {
          this.formErrors[i.name] = "Camp obligatoriu"
          hasError = true
        }
      })
      return hasError
    },

    getIngredientValue(item, field) {
      let valueForWeight = this.valueForWeight(item.ingredient.weight, item.ingredient[field], item.weight)
      let val = Math.round(valueForWeight * 100) / 100
      let unitMeasure = this.fieldNames[field]?.unitMeasure
      return `${val} ${unitMeasure}`

    },
    getTotalRecipeWeight() {
      let weight = 0
      this.recipeIngredients.map(recipeIngredient => {
        weight += Number(recipeIngredient.weight)
      })
      return weight
    },
    getValuesPerTotal() {
      let values = {
        energy: 0,
        energyKJ: 0,
        fat: 0,
        fiber: 0,
        carbohydrates: 0,
        proteins: 0,
        sodium: 0,
        saturated_fat: 0,
        sugars: 0
      }
      // adunam fiecare ingredient
      this.recipeIngredients.map(recipeIngredient => {
        values.energy += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.energy, recipeIngredient.weight)
        values.energyKJ += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.energyKJ, recipeIngredient.weight)
        values.fat += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.fat, recipeIngredient.weight)
        values.carbohydrates += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.carbohydrates, recipeIngredient.weight)
        values.proteins += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.proteins, recipeIngredient.weight)
        values.sodium += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.sodium, recipeIngredient.weight)
        values.saturated_fat += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.saturated_fat, recipeIngredient.weight)
        values.sugars += this.valueForWeight(recipeIngredient.ingredient.weight, recipeIngredient.ingredient.sugars, recipeIngredient.weight)
      })
      return values
    },
    getValuesPer100g() {
      let totalValues = this.getValuesPerTotal()
      let totalWeight = this.getTotalRecipeWeight()
      // deci pe totalWe grame am total values...
      // deci pe 100 de grame am
      return {
        energy: this.valueFor100g(totalWeight, totalValues.energy),
        energyKJ: this.valueFor100g(totalWeight, totalValues.energyKJ),
        fat: this.valueFor100g(totalWeight, totalValues.fat),
        fiber: this.valueFor100g(totalWeight, totalValues.fiber),
        carbohydrates: this.valueFor100g(totalWeight, totalValues.carbohydrates),
        proteins: this.valueFor100g(totalWeight, totalValues.proteins),
        sodium: this.valueFor100g(totalWeight, totalValues.sodium),
        saturated_fat: this.valueFor100g(totalWeight, totalValues.saturated_fat),
        sugars: this.valueFor100g(totalWeight, totalValues.sugars)
      }
    },
    round(value) {
      return (Math.round(value * 100) / 100) || 0
    },
    getValuesPerPortions() {
      let totalValues = this.getValuesPerTotal()
      let portions = this.model.portions
      // se imparte totalul la nr de portii
      return {
        energy: portions ? this.round(totalValues.energy / portions) : 0,
        energyKJ: portions ? this.round(totalValues.energyKJ / portions) : 0,
        fat: portions ? this.round(totalValues.fat / portions) : 0,
        fiber: portions ? this.round(totalValues.fiber / portions) : 0,
        carbohydrates: portions ? this.round(totalValues.carbohydrates / portions) : 0,
        proteins: portions ? this.round(totalValues.proteins / portions) : 0,
        sodium: portions ? this.round(totalValues.sodium / portions) : 0,
        saturated_fat: portions ? this.round(totalValues.saturated_fat / portions) : 0,
        sugars: portions ? this.round(totalValues.sugars / portions) : 0
      }
    },
    getValuesPerPortionsNet() {
      let brutWeight = this.getTotalRecipeWeight()

      let brutWeightPortion = brutWeight / this.model.portions
      // daca e totalWeight punem cu el, daca nu, ramane brut
      let netWeight = this.model.totalWeight
      if (!(netWeight > 0)) {
        netWeight = brutWeightPortion
      }

      let divider = brutWeightPortion / netWeight
      //   else {
      //     return totalValues
      //   }
      // luam pt bruta si facem cu regula de 3 simpla
      let valuesBrut = this.getValuesPerPortions()

      // se imparte totalul la nr de portii
      return {
        energy: this.round(valuesBrut.energy / divider),
        energyKJ: this.round(valuesBrut.energyKJ / divider),
        fat: this.round(valuesBrut.fat / divider),
        fiber: this.round(valuesBrut.fiber / divider),
        carbohydrates: this.round(valuesBrut.carbohydrates / divider),
        proteins: this.round(valuesBrut.proteins / divider),
        sodium: this.round(valuesBrut.sodium / divider),
        saturated_fat: this.round(valuesBrut.saturated_fat / divider),
        sugars: this.round(valuesBrut.sugars / divider)
      }
    },
    resetIngredient() {
      this.currentIngredient = { _id: null, name: null }
    },
    valueForWeight(baseWeight, baseValue, weight) {
      // adica daca in baseWeight am baseValue, in weight cat am?
      // if(!baseWeig)
      return Math.round(((baseValue * weight) / baseWeight) * 100) / 100
    },
    valueFor100g(totalWeight, valueForWeight) {
      if (!totalWeight) {
        return 0
      }
      let res = Math.round(((100 * valueForWeight) / totalWeight) * 100) / 100

      return res
    },
    calculateIngredient(recipeIngredient) {
      let base = recipeIngredient.ingredient
      let weight = recipeIngredient.weight
      // cele din base sunt la suta de grame
      // deci rez este energy * weight totul / 100
      recipeIngredient.energy = this.valueForWeight(base.weight, base.energy, weight)
      recipeIngredient.energyKJ = this.valueForWeight(base.weight, base.energyKJ, weight)
      recipeIngredient.fat = this.valueForWeight(base.weight, base.fat, weight)
      recipeIngredient.fiber = this.valueForWeight(base.weight, base.fiber, weight)
      recipeIngredient.carbohydrates = this.valueForWeight(base.weight, base.carbohydrates, weight)
      recipeIngredient.proteins = this.valueForWeight(base.weight, base.proteins, weight)
      recipeIngredient.sodium = this.valueForWeight(base.weight, base.sodium, weight)
      recipeIngredient.saturated_fat = this.valueForWeight(base.weight, base.saturated_fat, weight)
      recipeIngredient.sugars = this.valueForWeight(base.weight, base.sugars, weight)

    },
    sortServings(ingr) {
      let sorting = {
        'Portie': 1, 'Grame': 2, 'Mililitri': 3
      }
      if(ingr.category == 'Bauturi') {
        sorting = {
        'Portie': 1, 'Grame': 3, 'Mililitri': 2
        }
      }
      ingr.servings = ingr.servings.sort((a, b) => {
        if (!sorting[a.name]) {
          sorting[a.name] = 99
        }
        if (!sorting[b.name]) {
          sorting[b.name] = 99
        }
        return sorting[a.name] - sorting[b.name]
      })
      return ingr
    },
    pickIngredient(ingr) {
      this.ingredientError = null
      ingr = this.sortServings(ingr)

      let newRecipeIngredient = {
        name: ingr.name,
        ingredient: ingr,
        weight: "",
        quantity: "",
        unitMeasure: ingr.servings && ingr.servings.length ? ingr.servings[0] : { name: 'Grame', amount: 1 },
        allergens: ingr.allergens || [],
        potentialAllergens: ingr.potentialAllergens || [],
        isFrozen: false
      }


      if (ingr.additives && ingr.additives.length) {
        let newAdd = ingr.additives.map(a => {
          let category = a.category
          if (!category || !category.length) {
            let cat = a.name.split('(').length > 1 ? a.name.split('(')[1].slice(0, -1) : null
            if (cat && cat.length) {
              category = cat
            }
          }
          return {
            _id: a.additive,
            name: a.name,
            category: category,
            categories: category
          }
        })
        newRecipeIngredient.ingredientAdditives = newRecipeIngredient.ingredientAdditives ? newRecipeIngredient.ingredientAdditives.concat(newAdd) : newAdd

      }

      this.calculateIngredient(newRecipeIngredient)


      this.recipeIngredients.unshift(newRecipeIngredient)
      this.resetIngredient()
      this.clearEntries()
      toastr.info("Asigura-te ca ingredientul ales este cel folosit real in reteta")
    },
  },

  created() {
    this.$store.dispatch('fetchTags');
    if (this.$route.query.id) {
      this.headline = this.$route.query.copy ? "Cloneaza reteta" : "Editeaza reteta"
      this.$store.dispatch('fetchRecipeById', { id: this.$route.query.id, restaurantId: this.restaurantId });
    }

    // let userId = this.userImpId ? this.userImpId : null
    this.$store.dispatch('fetchCategories', this.restaurantId);
    this.$store.dispatch('fetchRestaurantById', this.restaurantId);
    this.$store.dispatch('fetchFieldNamesForUI');
    this.searchAllergens()
  },
  mounted() {
    this.$nextTick(() => this.$refs.focusMe && this.$refs.focusMe.focus())
  }
};
</script>

<style scoped>
  .weightInfo {
    margin-left: 4px;
    font-size: 15px;
  }

  .ingredientInfo {
    margin-left: 25px;
    font-size: 13px;
    font-style: italic;
    margin-top: -20px;
    margin-bottom: 15px;
  }
  
  
</style>

