<template>
  <auth-layout headline="Unitati de masura">
      <v-btn 
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        @click="openDialog">
        ADAUGA UNITATE DE MASURA
      </v-btn>

      <create-unit-measure
        v-if="dialog"
        :closeDialog="closeDialog"
        :model="unitMeasure"
        :saveUnitMeasure="saveUnitMeasure"
      />

       <v-data-table
          :headers="unitMeasureHeaders"
          :items="unitMeasures"
        >
          <template v-slot:[`item.default`]="props">
     
            <v-icon v-if="props.item.default" color="accent">mdi-check-bold</v-icon>
            <v-icon v-else color="red">mdi-close-thick</v-icon>
           
            
          </template>
          <template v-slot:[`item.usedInProducts`]="props">
     
            <v-icon v-if="props.item.usedInProducts" color="accent">mdi-check-bold</v-icon>
            <v-icon v-else color="red">mdi-close-thick</v-icon>
           
            
          </template>
          <template v-slot:[`item.actions`]="props">
            <v-btn text color="accent" class="categoryActions"
              @click="openDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-pen" />
            </v-btn> 
            <v-btn text color="red" class="categoryActions"
              @click="openDeleteDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-trash-can" />
            </v-btn> 
            
          </template>
        </v-data-table>

        <delete-unit-measure
          v-if="deleteDialog"
          :item="currentUnitMeasure"
          :closeDialog="closeDeleteDialog"
          :deleteUnitMeasure="deleteUnitMeasure"
        />
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import CreateUnitMeasure from '../components/Modals/CreateUnitMeasure.vue'
  import DeleteUnitMeasure from '../components/Modals/DeleteUnitMeasure.vue'


  export default {
    data() {
      return {
        headline: 'Dashboard',
        dialog: false,
        deleteDialog: false,
        unitMeasure: {
          name: null,
          amount: null,
          default: false
        },
        currentCategory: null,
        unitMeasureHeaders: [
          {
            text: "Nume",
            value: 'name'
          },
          {
            text: "Prescurtare",
            value: 'shortcut'
          },
          {
            text: "Cantitate (g)",
            value: 'amount'
          },
          {
            text: "Cantitatea este imutabila",
            value: 'default'
          },
          {
            text: "Se foloseste la produse fara VN",
            value: 'usedInProducts'
          },
          {
            text: "Actiuni",
            value: 'actions'
          }
        ],

      };
    },
    components: { AuthLayout, PrimaryButton,CreateUnitMeasure, DeleteUnitMeasure },
    computed: {
      company() {
        return this.$store.state.auth.user.company
      },
      unitMeasures() {
        return this.$store.state.unitMeasures.unitMeasures
      },
    },
    methods: {
      openDialog(unitMeasure) {
        this.dialog = true
        if(unitMeasure) {
          this.unitMeasure = unitMeasure
        }
      },
      closeDeleteDialog() {
        this.deleteDialog = false
      },

      openDeleteDialog(unitMeasure) {
        this.currentUnitMeasure = unitMeasure
        this.deleteDialog = true
      },
      deleteUnitMeasure(unitMeasure) {
        this.currentUnitMeasure = null
        this.$store.dispatch('deleteUnitMeasureById', unitMeasure._id);
      },
      closeDialog() {
        this.dialog = false
      },
      saveUnitMeasure(model) {
        if(model._id) {
          this.$store.dispatch('updateUnitMeasure', model);
        } else {
          this.$store.dispatch('createUnitMeasure', model);
        }
        
        this.unitMeasure = {
          name: null,
          amount: null,
          default: false
        }
      },
    },
    created() {
      this.$store.dispatch('fetchUnitMeasures');
      this.$store.dispatch('exitImp');
    }
  };
</script>
