<template>
  <auth-layout headline="Denumiri unitati de masura">
      <v-btn 
        type="button"
        :loading="loading" 
        class="mainButtonYes"
        @click="openDialog">
        ADAUGA DENUMIRE
      </v-btn>

      <create-unit-measure-name
        v-if="dialog"
        :closeDialog="closeDialog"
        :model="unitMeasureName"
        :saveUnitMeasureName="saveUnitMeasure"
      />

       <v-data-table
          :headers="unitMeasureHeaders"
          :items="unitMeasureNames"
        >
          <template v-slot:[`item.actions`]="props">
            <v-btn text color="accent" class="categoryActions"
              @click="openDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-pen" />
            </v-btn> 
            <v-btn text color="red" class="categoryActions"
              @click="openDeleteDialog(props.item)"
            >
              <font-awesome-icon  icon="fa-solid fa-trash-can" />
            </v-btn> 
            
          </template>
        </v-data-table>

        <delete-unit-measure-name
          v-if="deleteDialog"
          :item="currentUnitMeasureName"
          :closeDialog="closeDeleteDialog"
          :deleteUnitMeasureName="deleteUnitMeasureName"
        />
  </auth-layout>
</template>
<script>
  import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
  import AuthLayout from '../Layouts/AuthLayout.vue';
  import CreateUnitMeasureName from '../components/Modals/CreateUnitMeasureName.vue'
  import DeleteUnitMeasureName from '../components/Modals/DeleteUnitMeasureName.vue'


  export default {
    data() {
      return {
        headline: 'Dashboard',
        dialog: false,
        deleteDialog: false,
        unitMeasureName: {
          name: null,
          key: null
        },
        currentCategory: null,
        unitMeasureHeaders: [
          {
            text: "Nume",
            value: 'name'
          },
          {
            text: "Prescurtare",
            value: 'shortcut'
          },
          {
            text: "Actiuni",
            value: 'actions'
          }
        ],

      };
    },
    components: { AuthLayout, PrimaryButton,CreateUnitMeasureName, DeleteUnitMeasureName },
    computed: {
      company() {
        return this.$store.state.auth.user.company
      },
      unitMeasureNames() {
        return this.$store.state.unitMeasures.unitMeasureNames
      },
    },
    methods: {
      openDialog(unitMeasureName) {
        this.dialog = true
        if(unitMeasureName) {
          this.unitMeasureName = unitMeasureName
        }
      },
      closeDeleteDialog() {
        this.deleteDialog = false
      },

      openDeleteDialog(unitMeasureName) {
        this.currentUnitMeasureName = unitMeasureName
        this.deleteDialog = true
      },
      deleteUnitMeasureName(unitMeasureName) {
        this.currentUnitMeasureName = null
        this.$store.dispatch('deleteUnitMeasureNameById', unitMeasureName._id);
      },
      closeDialog() {
        this.dialog = false
      },
      saveUnitMeasure(model) {
        if(model._id) {
          this.$store.dispatch('updateUnitMeasureName', model);
        } else {
          this.$store.dispatch('createUnitMeasureName', model);
        }
        
        this.unitMeasureName = {
          name: null,
          key: null
        }
      },
    },
    created() {
      this.$store.dispatch('fetchUnitMeasureNames');
      this.$store.dispatch('exitImp');
    }
  };
</script>
