<template>
  <guest-layout >
    <div class="w-full h-full flex items-center justify-center">
      <card class="w-6/12 h-6/12" center="true">
        <span class="mb-4">
          <main-headline label="Reseteaza parola" class="mb-2"></main-headline>
        </span>

        <v-divider style="border-block: white;"/>

        <!-- Register via email + password -->
        <form class="w-full" @submit.prevent="resetPassword">
          <!-- Email -->


          <v-subheader>Parola noua</v-subheader>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pass"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :placeholder="formData.password.placeholder"
            v-model="formData.password.value"
            @change="validatePassword"
          />


          <v-subheader>Repeta parola noua</v-subheader>
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pass1"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :placeholder="formData.password2.placeholder"
            v-model="formData.password2.value"
            @change="validatePassword"
          />

          <div v-if="formData.password.error" style="color:red" class="text-sm -mt-4 text-red-400">{{ formData.password.error }}</div>

  
          <!-- Submit button -->
          <primary-button class="w-full" type="submit">
            <div class="w-full flex items-center justify-center">
              <span> Reseteza parola </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </div>
          </primary-button>

          <!-- Show error message if login fails -->
          <div
            v-if="$store.state.auth.error && $store.state.auth.error.area === 'login'"
            class="text-red-500"
            style="color:red"
          >
            {{ $store.state.auth.error.message }}
          </div>
        </form>
      </card>
    </div>
  </guest-layout>
</template>

<script>
  import GuestLayout from '../../Layouts/GuestLayout.vue';
  import Card from '../../components/Widgets/Card.vue';
  import MainHeadline from '../../components/Typography/MainHeadline.vue';
  import InputField from '../../components/Form/Input.vue';
  import PrimaryButton from '../../components/Buttons/PrimaryButton.vue';
  import Divider from '../../components/Widgets/Divider.vue';

  export default {
    //===========================
    // DATA
    //===========================
    data() {
      return {
        show1: false,
        show2: false,
        formData: {
          password: {
            type: 'password',
            name: 'password',
            label: 'Parola noua',
            placeholder: '',
            value: '',
            error: null,
            isRequired: true,
          },
          password2: {
            type: 'password',
            name: 'password',
            label: 'Repera parola noua',
            placeholder: '',
            value: '',
            error: null,
            isRequired: true,
          },
 
        },
      };
    },
    //===========================
    // COMPONENTS
    //===========================
    components: {
      GuestLayout,
      Card,
      MainHeadline,
      InputField,
      PrimaryButton,
      Divider,
    },

    computed: {
      token() {
        return this.$route.query.token
      }
    },
    //===========================
    // METHODS
    //===========================
    methods: {
      resetPassword() {
        this.validatePassword()
        if (this.formData.password.error) {
          return;
        }

        this.$store.dispatch('resetPassword', {newPassword: this.formData.password.value, token: this.token})

      },

      /**
       * Validate the password field
       */
      validatePassword() {
        if (this.formData.password.value.length < 10) {
          return (this.formData.password.error =
            'Parola trebuie sa contina cel putin 10 caractere');
        }


        if (this.formData.password.value !== this.formData.password2.value) {
          return (this.formData.password.error =
            'Parolele nu coincid');
        }

        this.formData.password.error = null;
      },
    },
    created() {
    }
  };
</script>
