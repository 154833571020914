var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Adauga ingredient")])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"customFlex"},[_c('div',{staticClass:"padded w-half m-5"},[_c('v-text-field',{ref:"focusMe",attrs:{"type":"text","placeholder":"Nume ingredient *","required":"","label":"Nume ingredient *","rules":[
                  function (v) { return !!v || "Completeaza numele ingredientului"; },
                  function (v) { return v && v.length <= 50 || "Numele trebuie sa contina cel mult 50 de caractere"; }
                ],"outlined":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('div',{staticClass:"padded w-half m-5"},[_c('v-autocomplete',{attrs:{"label":"Categorie *","items":_vm.ingredientCategories,"color":"blue-grey-lighten-2","rules":[function (v) { return !!v || "Alege categoria"; }],"item":"","outlined":"","return-object":""},model:{value:(_vm.model.category),callback:function ($$v) {_vm.$set(_vm.model, "category", $$v)},expression:"model.category"}})],1),_c('div',{staticClass:"padded w-full"},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Completati ingredientele de pe eticheta","label":"Descriere ingrediente","outlined":""},model:{value:(_vm.model.explanation),callback:function ($$v) {_vm.$set(_vm.model, "explanation", $$v)},expression:"model.explanation"}})],1),_c('div',{staticClass:"padded w-full"},[_vm._v(" Valori pentru 100 g ")]),_vm._l((_vm.inputFields),function(field){return _c('div',{key:field.fieldName,staticClass:"padded w-half m-5"},[_c('v-text-field',{attrs:{"type":"number","label":field.label,"placeholder":field.placeholder,"required":"","outlined":"","max":9999.99,"oninput":"if(Number(this.value) > Number(this.max)) this.value = this.max;","rules":[
                  function (v) { return !!v || "Completeaza valoarea"; }, 
                  function (v) { return Number(v) >= 0 || "Numarul trebuie sa fie pozitiv"; },
                  function (v) { return !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || "Numarul trebuie sa aiba cel mult 2 zecimale"; }
                  ]},model:{value:(_vm.model[field.fieldName]),callback:function ($$v) {_vm.$set(_vm.model, field.fieldName, $$v)},expression:"model[field.fieldName]"}})],1)}),_c('div',{staticClass:"padded w-full"},[_vm._v(" Unitate de masura ")]),_c('div',{staticClass:"padded w-half m-5"},[_c('v-autocomplete',{attrs:{"label":"Unitate *","items":_vm.unitMeasures,"color":"blue-grey-lighten-2","rules":[function (v) { return !!v || "Alege categoria"; }],"item":"","outlined":"","return-object":""},on:{"change":function($event){return _vm.setServingAmount()}},model:{value:(_vm.model.serving.name),callback:function ($$v) {_vm.$set(_vm.model.serving, "name", $$v)},expression:"model.serving.name"}})],1),(!_vm.showAmount(_vm.model.serving.name))?_c('div',{staticClass:"padded w-half m-5"},[_c('v-text-field',{attrs:{"type":"number","label":"Grame per "+ _vm.model.serving.name + ' *',"placeholder":"Grame per "+ _vm.model.serving.name + ' *',"required":"","outlined":"","disabled":_vm.isDefaultUnitMeasure(_vm.model.serving.name),"max":9999.99,"oninput":"if(Number(this.value) > Number(this.max)) this.value = this.max;","rules":[
                  function (v) { return !!v || "Completeaza valoarea"; }, 
                  function (v) { return Number(v) >= 0 || "Numarul trebuie sa fie pozitiv"; },
                  function (v) { return !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || "Numarul trebuie sa aiba cel mult 2 zecimale"; }
                  ]},model:{value:(_vm.model.serving.amount),callback:function ($$v) {_vm.$set(_vm.model.serving, "amount", $$v)},expression:"model.serving.amount"}})],1):_vm._e(),_c('div',{staticClass:"padded w-full"},[_vm._v(" Aditivi ")]),_c('div',{staticClass:"padded w-half m-5"},[_c('v-autocomplete',{attrs:{"d-flex":"","label":"Cauta aditivi","hide-selected":"","text-color":"white","items":_vm.additives,"item-text":"name","outlined":"","multiple":"","item":"","small-chips":"","clearable":true,"deletable-chips":true,"search-input":_vm.searchAdditive,"no-filter":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchAdditive=$event},"update:search-input":function($event){_vm.searchAdditive=$event},"change":function($event){_vm.searchAdditive=''}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":data.selected,"color":"warning","text-color":"white","close":""},on:{"click:close":function($event){return _vm.removeAdditive(data.item)}}},'v-chip',attrs,false),on),[_c('strong',[_vm._v(_vm._s(data.item.name.split("-")[0]))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.item.name))])])]}}]),model:{value:(_vm.model.additives),callback:function ($$v) {_vm.$set(_vm.model, "additives", $$v)},expression:"model.additives"}})],1),_c('div',{staticClass:"padded w-full"},[_vm._v(" Alergeni ")]),_c('div',{staticClass:"padded w-half m-5"},[_c('v-autocomplete',{staticClass:"ml-5 mr-5",attrs:{"d-flex":"","label":"Cauta alergeni","hide-selected":"","text-color":"white","items":_vm.allergens,"item-text":"name","multiple":"","small-chips":"","clearable":true,"deletable-chips":true,"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":data.selected,"color":"accent","text-color":"white","close":""},on:{"click:close":function($event){return _vm.removeAllergen(data.item, _vm.model)}}},'v-chip',attrs,false),on),[_c('strong',[_vm._v(_vm._s(data.item.name.charAt(0)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.item.name))])])]}}]),model:{value:(_vm.model.allergens),callback:function ($$v) {_vm.$set(_vm.model, "allergens", $$v)},expression:"model.allergens"}})],1),_c('div',{staticClass:"padded w-half m-5"},[_c('v-autocomplete',{staticClass:"ml-5 mr-5",attrs:{"items":_vm.allergens,"clearable":true,"deletable-chips":true,"label":"Cauta potentiali alergeni","hide-selected":"","text-color":"white","item-text":"name","d-flex":"","multiple":"","small-chips":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":data.selected,"color":"primary","text-color":"white","close":""},on:{"click:close":function($event){return _vm.removePotentialAllergen(data.item, _vm.model)}}},'v-chip',attrs,false),on),[_c('strong',[_vm._v(_vm._s(data.item.name.charAt(0)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.item.name))])])]}}]),model:{value:(_vm.model.potentialAllergens),callback:function ($$v) {_vm.$set(_vm.model, "potentialAllergens", $$v)},expression:"model.potentialAllergens"}})],1)],2)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mainButtonYes",attrs:{"type":"button","text":""},on:{"click":function($event){return _vm.openConfirmDialog()}}},[_vm._v("Salveaza")]),_c('v-btn',{staticClass:"mainButtonNo",attrs:{"type":"button","color":"red"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Renunta")])],1)],1)],1),(_vm.confirmDialog)?_c('ConfirmCreateIngredient',{attrs:{"closeDialog":_vm.closeConfirmDialog,"confirm":_vm.confirm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }