<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card  v-if="step == 'PICK'">
        <v-card-title>
          <span class="headline">Inlocuieste ingredient global</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete label="Inlocuieste ingredientul" outlined v-model="ingredientToReplace" :items="ingredientsItemsFromRecipes"
            color="primary" item item-text="name" item-value="_id" :search-input.sync="searchInRecipes" return-object
            :clearable="true" hide-no-data hide-selected no-filter 
            :rules="[v => !!v || `Alege un ingredient`]"
            :loading="isLoadingRecipes" prepend-icon="mdi-database-search">
            <template slot="item" slot-scope="{ item, tile }">
              <p>
                <div>{{ item.name }}</div>
                <v-divider style="border-block: white;" />
                <label class="recipeInfoLabel">
                  Folosit in {{item.numberOfRecipes > 1 ? `${item.numberOfRecipes} retete` : 'o reteta'}}
                </label>
              </p>
            </template>
          </v-autocomplete>

          <v-autocomplete label="Cu ingredientul" outlined v-model="ingredientToReplaceWith" :items="ingredientsItems"
            placeholder="Cauta ingredient..."
            color="primary" item item-text="name" item-value="_id" :search-input.sync="search" return-object
            :clearable="true" hide-no-data hide-selected no-filter
            :rules="[v => !!v || `Alege un ingredient`]" 
            :loading="isLoading" prepend-icon="mdi-database-search">
            <template slot="item" slot-scope="{ item, tile }">
              <p>
                <v-tooltip top v-if="item.isCustom && !item.isRecipe">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="orange" v-bind="attrs" v-on="on"> mdi-account-star </v-icon>

                  </template>
                  <span>Ingredient adaugat de tine</span>
                </v-tooltip>
                <v-tooltip top v-if="item.explanation && !item.isCustom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" v-bind="attrs" v-on="on"> mdi-storefront </v-icon>

                  </template>
                  <span>Ingredient compus cumparat, ambalat</span>
                </v-tooltip>
                <v-tooltip top v-if="item.isRecipe">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" v-bind="attrs" v-on="on"> mdi-bowl-mix </v-icon>

                  </template>
                  <span>Reteta adaugata de tine</span>
                </v-tooltip>

                <b> {{ item.manufacturerName }}</b> {{ item.name }}<br>
                <!-- <small class="small" v-if="item.explanation" style="overflow: hidden">{{ item.explanation }}<br></small> -->
                <small>
                  <v-chip small color="accent" v-if="item.allergens
                    && item.allergens.length > 0">{{ item.allergens.length }}
                    Alergeni
                  </v-chip>
                  <v-chip small color="primary" v-if="item.potentialAllergens
                    && item.potentialAllergens.length > 0">{{ item.potentialAllergens.length }}
                    Potentiali Alergeni
                  </v-chip>
                  <v-chip small color="orange" v-if="item.additives
                    && item.additives.length > 0">{{ item.additives.length }}
                    Aditivi
                  </v-chip>

                </small>
              </p>
            </template>
          </v-autocomplete>

        </v-card-text>

        <v-card-actions class="paddedButton">
          <v-btn 
            v-if="ingredientToReplace && ingredientToReplaceWith"
            type="button" 
            class="mainButtonYes paddedButton" 
            text 
            @click="nextStep()"
          >Vezi modificari</v-btn>
          <v-divider style="border-block: white;" />
          <v-btn type="button"  class="mainButtonNo paddedButton" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
      <v-card  v-else>
        <v-card-title>
          <span class="headline">Vei inlocui ingredientul <b>{{ingredientToReplace.name}}</b> in retetele</span>
        </v-card-title>
        <v-card-text>
          <div class="padded">
            <v-list>
              <v-list-item v-for="(restaurantName, idx) in recipesRestaurants" :key="idx" class="grid">
                <div class="grid">
                <div class="flex">
                  <v-checkbox v-if="recipesRestaurants.length > 1" v-on:click="select(restaurantName)" 
                    :input-value="selected.find(s => s == restaurantName)"
                  ></v-checkbox>
                  <b style="align-self: center;">{{restaurantName}}</b>
                </div>
                <v-list>
                  <v-list-item v-for="(recipe, index) in recipes.filter(r => r.restaurant.restaurantName == restaurantName)" :key="index">
                    <div><b>{{recipe.name}}</b> {{formatRecipe(recipe)}}</div>
                  </v-list-item>
                </v-list>
                </div>
              </v-list-item>
            </v-list>
            
            
          </div>
          <div v-if="restaurantError" class="text-sm mt-4" style="color: red">{{ restaurantError }}</div>
        </v-card-text>
      
        <v-card-actions>
          <v-btn type="button"  class="mainButtonNo" color="primary" @click="() => {step='PICK'}">Inapoi</v-btn>
          <v-divider style="border-block: white;" />
          <v-btn type="button" class="mainButtonYes padded" text @click="save()">Confirma</v-btn>
          
          <v-divider style="border-block: white;" />
          <v-btn type="button"  class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "model",
    "closeDialog",
    "replaceIngredient"
  ],
  data: () => ({
    dialog: true,
    ingredientToReplaceWith: null,
    ingredientToReplace: null,
    searchInRecipes: '',
    isLoadingRecipes: false,
    isLoading: false,
    search: '',
    step: 'PICK',
    inputRules: [v => !!v || "Campul este obligatoriu"],
    categoryUnitMeasures: ["Grame", "Mililitri"],
    selected: [],
    restaurantError: null
  }),
  watch: {
    searchInRecipes(newVal) {
      this.isLoadingRecipes = true
      this.fetchEntriesInRecipesDebounced()
    },
    search(newVal) {
      if (!newVal) {
        return
      }

      this.isLoading = true
      this.fetchEntriesDebounced()
    },
  },
  methods: {
    nextStep() {
      this.step='PREVIEW'
      this.selected = [...this.recipesRestaurants]
    },
    select(item) {
      let existing = this.selected.find(i => i == item)
      if (!existing) {
        this.selected.push(item)
        this.restaurantError = null
      } else {
        this.selected = this.selected.filter(i => i != item)
      }
    },
    formatRecipe(recipe) {
      let unitMeasureString = (recipe.unitMeasure.name == 'Grame' || recipe.unitMeasure.name == 'Mililitri') ?
        `${recipe.quantity} ${recipe.unitMeasure.name}` :
        `${recipe.quantity} x ${recipe.unitMeasure.name} (${recipe.weight} grame)`

      return ` - ${unitMeasureString}`
    },
    fetchEntriesInRecipesDebounced() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.searchIngredientsInRecipes()
        this.isLoadingRecipes = false
      }, 500) /* 500ms throttle */
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.searchIngredients()
        this.isLoading = false
      }, 500) /* 500ms throttle */
    },
    searchIngredientsInRecipes(e) {
      let searchInRecipes = this.searchInRecipes ? this.searchInRecipes.substring(0, 60) : ''
      this.$store.dispatch("fetchIngredientsInRecipes", { search: searchInRecipes, restaurantId: this.restaurantId })
    },
    searchIngredients(e) {
      let search = this.search ? this.search.substring(0, 60) : ''
      this.$store.dispatch("fetchIngredients", { search: search, restaurantId: this.restaurantId })
    },
    close() {
      this.closeDialog()
      
    },
    save() {
      if(!this.selected.length) {
        this.restaurantError = "Selectati cel putin un restaurant!"
        return
      }
      if(this.ingredientToReplace && this.ingredientToReplaceWith) {
        this.$store.dispatch("replaceIngredient", { 
          ingredientToReplace: this.ingredientToReplace._id,
          ingredientToReplaceWith: this.ingredientToReplaceWith._id,
          selectedRestaurants: this.selected,
          restaurantId: this.restaurantId 
        })
        this.close()
      }

    }
  },
  computed: {
    recipes() {
      if(!this.ingredientToReplace) {
        return []
      }
      if(!this.ingredientToReplaceWith) {
        return []
      }
      if(this.ingredientToReplaceWith.recipe) {
        return this.ingredientToReplace.recipes.filter(recipe => {
          return recipe._id != this.ingredientToReplaceWith.recipe
        })
      }
      return this.ingredientToReplace.recipes
    },
    recipesRestaurants() {
      return this.recipes.map(r => r.restaurant.restaurantName).filter((v,i,a) => a.indexOf(v) == i)
    },
    ingredientsItemsFromRecipes() {
      return this.$store.state.ingredients.ingredientsInRecipes
    },
    relatedRestaurants() {
      return this.$store.state.restaurants.relatedRestaurants
    },
    ingredientsItems() {
      if(this.ingredientToReplace) {
        return this.$store.state.ingredients.ingredients.filter(i => {
          if(!i.recipe) {
            return true
          }

          if(i._id == this.ingredientToReplace._id) {
            return false
          }

          return this.ingredientToReplace.recipes.find(recipe => {
            return recipe._id != i.recipe
          })

        })
      }
      return this.$store.state.ingredients.ingredients
    },
    restaurantId() {
      return this.$route.query.restaurantId
    },
  },
  created() {
    this.$store.dispatch("fetchIngredientsInRecipes", { search: this.searchInRecipes, restaurantId: this.restaurantId })
    this.$store.dispatch("fetchRelatedRestaurants", this.restaurantId )

  }
};
</script>
<style scoped>
  .paddedButton {
    padding-left: 50px;
    padding-right: 50px;
  }

  .recipeInfoLabel {
    font-size: 10px; 
    font-style: italic; 
    padding-right: 10px
  }
</style>