<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1200px" height="800px">
      <v-card>
        <v-card-title>
          <span class="headline" v-if="!item || !item._id">Adauga produse fara valori nutritionale</span>
          <span class="headline" v-else>Editeaza produs fara valori nutritionale</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-data-table
              :headers="productHeaders"
              :items="models"
              disable-filtering
              :hide-default-footer="true"
              :items-per-page="-1"
            >
              <template v-slot:[`item.name`]="props">
                <div class="ingredientMeasure">
                  <v-text-field
                    ref="focusMe"
                    type="text"
                    placeholder="Nume produs *"
                    v-model="props.item.name"
                    required
                    label="Nume produs *"
                    :rules="[
                      (v) => !!v || `Completeaza numele produsului`,
                      (v) =>
                        (v && v.length <= 50) ||
                        `Numele trebuie sa contina cel mult 50 de caractere`,
                    ]"
                  />
                </div>
              </template>

              <template v-slot:[`item.quantity`]="props">
                <div class="ingredientMeasure">
                  <v-text-field
                    type="number"
                    placeholder="Cantitate"
                    :max="99999.99"
                    oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                    :rules="[
                      (v) => !!v || `Completeaza cantitatea`,
                      (v) =>
                        Number(v) > 0 || `Cantitatea trebuie sa fie pozitiva`,
                      (v) =>
                        /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) ||
                        `Cantitatea trebuie sa aiba cel mult 2 zecimale`,
                    ]"
                    v-model="props.item.quantity"
                    required
                  />
                </div>
              </template>

              <template v-slot:[`item.unitMeasure`]="props">
                <div class="ingredientMeasure">
                  <!-- <div> -->
                  <v-autocomplete
                    label="Unitate *"
                    v-model="props.item.unitMeasure"
                    :label="' Unitate de masura'"
                    :placeholder="' Unitate de masura'"
                    :items="unitMeasures"
                    color="blue-grey-lighten-2"
                    item-text="name"
                    item-value="_id"
                    :rules="[(v) => !!v || `Alege unitatea de masura`]"
                    return-object
                  >
                  </v-autocomplete>
                  <!-- </div> -->
                </div>
              </template>

              <template v-slot:[`item.price`]="props">
                <div class="ingredientMeasure">
                  <!-- <div> -->
                  <v-text-field
                    type="number"
                    label="Pret vanzare"
                    placeholder="Pret vanzare"
                    v-model="props.item.price"
                    required
                    :max="9999.99"
                    oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                    :rules="[
                      (v) =>
                        !v || Number(v) > 0 || `Pretul trebuie sa fie pozitiv`,
                      (v) =>
                        !v ||
                        /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) ||
                        `Pretul trebuie sa aiba cel mult 2 zecimale`,
                    ]"
                  />
                  <!-- </div> -->
                </div>
              </template>

              <template v-slot:[`item.category`]="props">
                <div class="ingredientMeasure">
                  <v-autocomplete
                    label="Categorie *"
                    v-model="props.item.category"
                    :items="categories"
                    color="blue-grey-lighten-2"
                    :rules="[(v) => !!v || `Alege categoria`]"
                    item
                    item-text="name"
                    item-value="_id"
                    return-object
                  >
                  </v-autocomplete>
                </div>
              </template>
              <template v-slot:[`item.excludeFromMenu`]="props">
                <div class="ingredientMeasure">
                  <v-checkbox
                    class="recipeActions"
                    style="align-items: center"
                    :input-value="props.item.excludeFromMenu"
                    @click="
                      props.item.excludeFromMenu = !props.item.excludeFromMenu
                    "
                  >
                  </v-checkbox>
                </div>
              </template>

              <template v-slot:[`item.actions`]="props">
                <div class="ingredientMeasure" v-if="!item || !item._id">
                  <v-btn color="red" @click="removeProduct(props.item)" icon>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <template slot="body.append" v-if="!item || !item._id">
                <tr>
                  <th :colspan="7" class="text-right">
                    <!-- <v-btn color="primary" x-large @click="addProduct" icon>
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn> -->
                    <v-btn
                      v-on:click="addProduct"
                      class="text-black primary paddedLeft"
                    >
                      <v-icon left dark>mdi-plus</v-icon>
                      <span class="hidden-xs-only smallText" black>Adauga</span>
                    </v-btn>
                  </th>
                </tr>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>

    
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="save()"
            >Salveaza</v-btn
          >
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()"
            >Renunta</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "closeDialog",
    // "model",
    "item",
    "callback",
    "restaurantId"
  ],
  data: () => ({
    dialog: true,
    inputRules: [(v) => !!v || "Campul este obligatoriu"],
    isEdit: false,
    searchAdditive: "",
    models: [],
    productHeaders: [
      {
        text: "Nume *",
        value: "name",
        sortable: false,
        // width: "150px"
      },
      {
        text: "Cantitate *",
        value: "quantity",
        sortable: false,
        // width: "200px"
      },
      {
        text: "Unitate de masura *",
        value: "unitMeasure",
        sortable: false,
        // width: "200px"
      },
      {
        text: "Pret",
        value: "price",
        sortable: false,
        // width: "200px"
      },
      {
        text: "Categorie",
        value: "category",
        sortable: false,
        // width: "200px"
      },
      {
        text: "Exclude din meniu",
        value: "excludeFromMenu",
        sortable: false,
      },
      {
        text: "Actiuni",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  watch: {},
  computed: {
    // restaurantId() {
    //   return this.$route.query.restaurantId;
    // },
    userImpId() {
      return this.$route.query.userId;
    },
    categories() {
      return this.$store.state.recipes.categories
    },
    unitMeasures() {
      return this.$store.state.unitMeasures.unitMeasures.filter(
        (u) => u.usedInProducts
      );
    },

  },
  methods: {
    addProduct() {
      this.models.push({});
    },
    removeProduct(product) {
      this.models = this.models.filter((model, i, arr) => {
        return model !== product;
      });
    },
    close() {
      this.closeDialog();
    },

    save() {
      if (this.$refs.form.validate()) {
        if(this.item && this.item._id) {
          this.$store.dispatch("updateProduct", {
            data: this.models[0],
            restaurantId: this.restaurantId,
            cb: () => {
              if (this.callback) {
                this.callback();
              }
            },
          });
        } else {
          this.$store.dispatch("createProducts", {
            data: this.models,
            restaurantId: this.restaurantId,
            cb: () => {
              if (this.callback) {
                this.callback();
              }
            },
          });
        }

       
        // if (this.model._id) {
        //   this.$store.dispatch("updateProduct", {
        //     data: this.model,
        //     restaurantId: this.restaurantId,
        //     cb: () => {
        //       if (this.callback) {
        //         this.callback();
        //       }
        //     },
        //   });
        // } else {
        //   this.$store.dispatch("createProduct", {
        //     data: this.model,
        //     restaurantId: this.restaurantId,
        //     cb: () => {
        //       if (this.callback) {
        //         this.callback();
        //       }
        //     },
        //   });
        // }

        this.close();
      }
    },
  },
  created() {
    this.$store.dispatch('fetchCategories', this.restaurantId);
    this.$store.dispatch("fetchUnitMeasures");
    if(this.item && this.item._id) {
      this.models.push(this.item);
    } else {
      this.models.push({});
    }
    
  },
  mounted() {
    this.$nextTick(() => this.$refs.focusMe && this.$refs.focusMe.focus());
  },
};
</script>

<style>
.customFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.m-0 {
  margin: 0;
}

.padded.w-half.m-5 {
  margin: 0.5em !important;
}
</style>