<template>
  <div>
    <v-layout row style="margin: 20px">
      <div>
        <section-headline>
          Retete
        </section-headline>
        <div v-if="restaurant && restaurant.restaurantName">
          <div v-if="isDisabled" class="flex">
            <div class="errorMessage" v-if="isDisabled">Planul tau {{ restaurant.lastActivePlan }} a expirat</div>
            <v-divider style="border-block: white; width: 900px"/>
            <v-btn v-if="isDisabled" class="primary" @click="upgradePlan">
              Activeaza planul
            </v-btn>
          </div>
          
          <div class="errorMessage" v-else-if="!availableCredits">Nu mai aveti credite</div>
          <div class="goodMessage" v-else>Mai aveti {{ availableCredits }} credite disponibile.</div>
        </div>
       
        <div v-if="restaurant.hasPublicMenu " class="text-green">
          <div v-if="restaurant.canHavePublicMenu">Link meniu public: <a
              :href="`https://valori-nutritionale.ro/restaurant/${this.restaurant.slug}`"
              target="blank">https://valori-nutritionale.ro/restaurant/{{ this.restaurant.slug }}&utm=qrcode</a>
          </div>
          <v-btn v-if="restaurant.canHavePublicMenu && !isDisabled" v-on:click="openQRDialog" class="text-gray-500 white">
            <v-icon left dark>mdi-qrcode</v-icon>
            <span>Descarca codul QR</span>
          </v-btn>

          
        </div>
     
        
      </div>
    </v-layout>

    <div row style="margin: 20px">
      <v-toolbar class="mb-2 ">
        <!-- <v-col cols="12" md="3"> -->
        <div class="flex">
          <v-text-field id="search" class="left searchWidth" v-model="searchRecipe" label="Cauta reteta..."
            single-line="single-line" hide-details="hide-details" v-on:keyup.enter="searchRecipes()"></v-text-field>
          <!-- </v-col> -->
          <!-- <v-col cols="6" md="2"> -->
          <v-combobox clearable label="Filtreaza dupa categorie" v-model="categoryFilter"
            class="left filterWidth paddedLeft" density="compact" style="max-height: 33px;" underlined
            :items="allCategories" item-text="name" item-value="_id" color="primary" return-object>
          </v-combobox>

          <!-- <v-combobox label="Filtreaza dupa tip retete" v-model="activeFilter" class="left filterWidth paddedLeft" density="compact"
          style="max-height: 33px;" underlined :items="filterItems" color="primary" item>
        </v-combobox> -->

          <v-select label="Filtreaza dupa tip retete" v-model="activeFilter" class="left smallText sortWidth paddedLeft"
            density="compact" style="max-height: 33px;" underlined hide-no-data :items="filterItems" color="primary" item>
          </v-select>

        </div>

        <!-- </v-col> -->
        <v-divider style="border-block: white;" />
        <div class="paddedLeft" v-if="!isDisabled">
          <v-btn v-on:click="openRecipeDialog" class="text-black primary paddedLeft " :disabled="!availableCredits">
            <v-icon left dark>mdi-plus</v-icon>
            <span class="hidden-xs-only smallText" black>Adauga reteta</span>
          </v-btn>
        </div>
        <div class="paddedLeft" v-if="!isBasic && !isDisabled">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-icon color="accent" v-bind="attrs" v-on="on" class="paddedLeft">mdi-bowl-mix</v-icon> -->

              <v-btn v-bind="attrs" v-on="on" v-on:click="openProductDialog" class="text-black primary paddedLeft " >
                <v-icon left dark>mdi-plus</v-icon>
                <span class="hidden-xs-only smallText" black>Adauga produs fara VN</span>
              </v-btn>
            </template>
            <span>Adauga produse care nu necesita calcul Valori Nutritionale (ex: Racoritoare, Spirtoase, Vin,
              etc.)</span>
          </v-tooltip>

        </div>
        <v-divider style="max-width: 40px; border-block: white;" />
        <div class="text-center paddedLeft smallText" v-if="!isDisabled">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="text-gray-500 accent smallText" v-bind="attrs" v-on="on">
                <v-icon left dark>mdi-download-box</v-icon>
                <span class="hidden-xs-only smallText">Exporta</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in pdfOptions" :key="index"
                v-on:click="item.children ? () => { } : exportPdf(item.action)">

                <!-- {{item.children}} -->
                <v-menu open-on-hover text offset-x v-if="item.children">
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <v-btn text v-bind="attrs" v-on="on"  > -->
                    <v-list-item-title v-bind="attrs" v-on="on"> <v-icon left>mdi-{{ item.icon }}</v-icon>{{ item.action
                    }}</v-list-item-title>
                    <!-- </v-btn> -->
                  </template>
                  <v-list>
                    <v-list-item v-for="(it, index) in item.children" :key="index"
                      v-on:click="exportPdf(item.action, it.action)">
                      <v-icon left>mdi-{{ it.icon }}</v-icon>{{ it.action }}
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-list-item-title v-else> <v-icon left>mdi-{{ item.icon }}</v-icon>{{ item.action }}</v-list-item-title>
              </v-list-item>
            </v-list>

          </v-menu>
        </div>

        <v-divider style="max-width: 40px; border-block: white;" />
        <div class="paddedLeft">
          <v-btn v-if="restaurant.canHavePublicMenu && !isRecorder && !isDisabled" v-on:click="activatePublicMenu" class="text-white text-center smallText"
            color="orange">
            <v-icon left dark>mdi-earth</v-icon>
            <span class="hidden-xs-only smallText"> {{ restaurant.hasPublicMenu ? 'Dezactiveaza' : 'Activeaza' }} QR code</span>
          </v-btn>

          <div class="flex justify-center items-center" style="max-width: 50px;" v-if="loadingPdf">
            <div :class="`animate-spin rounded-full border-b-2 border-gray-900 w-8 h-8`"></div>
          </div>
        </div>

      </v-toolbar>
    
      <div class="text-xs-center flex">
        <v-switch class="mb-2 " v-if="!isBasic && scene.children.length" v-model="enabledEditPriorities"
          :label="`Mod reordonare categorii si retete ${enabledEditPriorities ? 'activat' : 'dezactivat'}`"
          inset></v-switch>
        <v-switch class="mb-2 ml-5" v-if="(isAdmin || isRecorder) && scene.children.length" v-model="isForDelivery"
          :label="`Mod afisare pentru livratori ${isForDelivery ? 'activat' : 'dezactivat'}`" inset></v-switch>
        <!-- <v-divider/> -->
        <v-divider style="border-block: white;" />

        <v-select label="Sorteaza dupa" v-model="sortOption" class="smallText paddedLeft sortField" density="compact"
          style="max-height: 33px;" :disabled="enabledEditPriorities" underlined hide-no-data
          :items="enabledEditPriorities ? sortOptionPriority : sortOptions" color="primary" item>
        </v-select>
      </div>
      <div class="kanban-cards" v-if="enabledEditPriorities && !isForDelivery">
        <component v-bind:is="enabledEditPriorities ? 'Container' : 'div'" orientation="horizontal"
          @drop="onColumnDrop($event)" drag-class="card-ghost" drop-class="card-ghost-drop"
          :drop-placeholder="upperDropPlaceholderOptions" group-name="categories"
          :non-drag-area-selector="enabledEditPriorities ? `` : `.itemCategory`"
          drag-handle-selector=".column-drag-handle">

          <component v-bind:is="enabledEditPriorities ? 'Draggable' : 'div'" v-for="column in scene.children"
            :key="column.id">
            <div class="itemCategory" :style="'border-color: #' + column.border + '3b'">
              <div class="card-column-header card-column-header1">
                <span class="column-drag-handle cards-drop-preview" style="display:block">{{ column.name }}</span>
              </div>
              <component v-bind:is="enabledEditPriorities ? 'Container' : 'div'" group-name="recipes"
                @drop="(e) => onCardDrop(column.id, e)" :get-child-payload="getCardPayload(column.id)"
                drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="dropPlaceholderOptions"
                :non-drag-area-selector="enabledEditPriorities ? `` : `.item`">

                <component v-bind:is="enabledEditPriorities ? 'Draggable' : 'div'" v-for="card in column.children"
                  :key="card.id">
                  <div class="item" :style="'border-color: #' + column.border + 'c4'">
                    <div class="kanban-card">
                      <span class="card">
                        <div class="kanban-action" style=" border: 1px solid transparent">
                          <!-- <label
                            > -->
                          <v-checkbox v-if="!enabledEditPriorities" v-on:click="select(card.data)"
                            class="extraFlex mt-5"></v-checkbox>
                          <!-- </label> -->
                          <label class="label recipeName" style="color: black">

                            <div v-if="card.data.isProduct">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="primary">mdi-food-variant-off</v-icon>

                                </template>
                                <span>Produs fara calcul Valori Nutritionale</span>
                              </v-tooltip>
                              {{ card.data.name }} {{ `(${card.data.quantity} ${card.data.unitMeasure.shortcut})` }}
                            </div>
                            <div v-else>
                              {{ card.data.name }} {{ card.data.totalWeight ? `(${card.data.totalWeight}g)` : '' }}
                            </div>
                          </label>

                        </div>

                        <div class="kanban-action" v-if="!enabledEditPriorities">

                          <v-tooltip top v-if="card.data.isCustomIngredient" :class="'kanban-button'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="accent" v-bind="attrs" v-on="on" class="paddedLeft">mdi-bowl-mix</v-icon>
                            </template>
                            <span>Utilizabil in alte retete</span>
                          </v-tooltip>

                          <v-tooltip top v-if="card.data.excludeFromMenu" :class="'kanban-button'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" v-bind="attrs" v-on="on"
                                class="paddedLeft">mdi-file-document-remove</v-icon>
                            </template>
                            <span>Exclus din meniu</span>
                          </v-tooltip>

                          <v-divider style="border-block: white;" />


                          <v-btn v-on:click="editRecipe(card.data)" icon color="accent" :class="'kanban-button'">
                            <v-icon>mdi-pen</v-icon>
                          </v-btn>
                          <v-btn v-on:click="copyRecipe(card.data)" icon color="primary" :class="'kanban-button'"
                            :disabled="card.data.isProduct">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn v-on:click="openDelete(card.data)" icon color="red" :class="'kanban-button'">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                          <v-btn v-on:click="selectedRecipe = card.data; openQRDialog()" icon color="orange"
                            v-if="!isBasic" :class="'kanban-button'">
                            <v-icon>mdi-qrcode</v-icon>
                          </v-btn>
                        </div>
                      </span>
                    </div>


                  </div>
                </component>
              </component>
            </div>
          </component>
        </component>
      </div>
      <v-data-table v-if="!enabledEditPriorities && !isForDelivery" :headers="ingredientHeaders" :items="recipes"
        :search="search" :options.sync="options" :server-items-length="totalRecipes" :footer-props="{
          itemsPerPageOptions: [28, 50, 100],

        }">
        <template v-slot:[`item.select`]="props">
          <div class="flex">
            <v-checkbox v-on:click="select(props.item)"></v-checkbox>
            <v-divider />
            <v-tooltip top v-if="props.item.isProduct">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary">mdi-food-variant-off</v-icon>

              </template>
              <span>Produs fara calcul Valori Nutritionale</span>
            </v-tooltip>

          </div>

        </template>

        <template v-slot:[`item.name`]="props">

          {{ props.item.name }}
        </template>
        <template v-slot:[`item.price`]="props">
          {{ props.item.price ? props.item.price : "-" }}
        </template>
        <template v-slot:[`item.isCustomIngredient`]="props">
          {{ props.item.isCustomIngredient ? 'Da' : "Nu" }}
        </template>
        <template v-slot:[`item.excludeFromMenu`]="props">
          {{ props.item.excludeFromMenu ? 'Da' : "Nu" }}
        </template>
        <template v-slot:[`item.actions`]="props" v-if="!isDisabled">
          <v-btn v-on:click="editRecipe(props.item)" icon color="accent" :class="'kanban-button'">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn v-on:click="copyRecipe(props.item)" icon color="primary" :class="'kanban-button'"
            :disabled="props.item.isProduct">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn v-on:click="openDelete(props.item)" icon color="red" :class="'kanban-button'">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn v-on:click="selectedRecipe = props.item; openQRDialog()" icon color="orange" v-if="!isBasic"
            :class="'kanban-button'">
            <v-icon>mdi-qrcode</v-icon>
          </v-btn>

        </template>
      </v-data-table>
      <div v-if="isForDelivery">
        <v-form>
          <v-container>
            <v-row v-for="recipe in recipes" :key="recipe._id" class="mb-5">
              <v-col cols="12" md="12">
                <h2>{{ recipe.name }}</h2>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field :value="recipe.valuesPer100g.energyKJ" label="Valoare energetica
(kJ)" @click="copyURL(recipe.valuesPer100g.energyKJ)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>/ <v-col cols="12" md="1">
                <v-text-field :value="recipe.valuesPer100g.energy" label="Valoare energetica
( kcal)" @click="copyURL(recipe.valuesPer100g.energy)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field :value="recipe.valuesPer100g.fat" label="Grasimi
(grame)" @click="copyURL(recipe.valuesPer100g.fat)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field :value="recipe.valuesPer100g.saturated_fat" label="din care acizi grasi saturati
(grame)" @click="copyURL(recipe.valuesPer100g.saturated_fat)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field :value="recipe.valuesPer100g.carbohydrates" label="Glucide
(grame)" @click="copyURL(recipe.valuesPer100g.carbohydrates)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field :value="recipe.valuesPer100g.sugars" label="din care zaharuri
(grame)" @click="copyURL(recipe.valuesPer100g.sugars)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field :value="recipe.valuesPer100g.sodium" label="Sare
(grame)" @click="copyURL(recipe.valuesPer100g.sodium)" readonly variant="solo-inverted"> </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field :value="returnAdditives(recipe.ingredients)" label="Aditivi"
                  @click="copyURL(returnAdditives(recipe.ingredients))" readonly variant="solo-inverted"> </v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-form>

      </div>
    </div>

    <delete-recipe v-if="deleteDialog" :item="currentRecipe" :closeDialog="closeDeleteDialog"
      :deleteRecipe="deleteRecipe" />

    <CreateQRCode v-if="qrDialog" :closeDialog="closeQRDialog" :restaurant="restaurant" :recipe="selectedRecipe" />
    <ConfirmTransformRecipe v-if="confirmDialog" :closeDialog="closeConfirmDialog" :confirmTransform="confirmTransform"/>

    <create-product v-if="createProductDialog" :closeDialog="closeProductDialog" :callback="searchRecipes"
      :item="currentProduct" :restaurantId="restaurantId" />

    <slot></slot>

  </div>
</template>

<script>
import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
import Input from '../components/Form/Input.vue'
import MainHeadline from '../components/Typography/MainHeadline.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import DeleteRecipe from '../components/Modals/DeleteRecipe.vue'
import CreateProduct from '../components/Modals/CreateProduct.vue'
import ConfirmTransformRecipe from '../components/Modals/ConfirmTransformRecipe.vue'

import CreateQRCode from '../components/Modals/CreateQRCode.vue'

import { MapUnitMeasures, nutriKeys } from '../common/UnitMeasures'

import { Container, Draggable } from "vue-dndrop";

export default {
  components: {
    MainHeadline, PrimaryButton, Input,
    SectionHeadline,
    DeleteRecipe,
    CreateQRCode,
    Container, Draggable,
    CreateProduct,
    ConfirmTransformRecipe

  },
  props: [],
  data() {
    return {
      confirmDialog: false,
      createProductDialog: false,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "100",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "100",
        showOnTop: true,
      },
      search: '',
      showPdfOptions: false,
      enabledEditPriorities: false,
      isForDelivery: false,
      qrDialog: false,
      searchRecipe: '',
      selected: [],
      currentRecipe: {},
      currentProduct: null,
      selectedRecipe: null,
      deleteDialog: false,
      options: {},
      categoryFilter: null,
      categories: [],
      activeFilter: 'all',
      filterItems: [
        {
          text: 'Toate',
          value: 'all'
        },
        {
          text: 'Retete cu valori nutritionale',
          value: 'recipes'
        },
        {
          text: 'Produse fara valori nutritionale',
          value: 'products'
        }
      ],
      sortOptions: [
        {
          text: 'Data adaugare',
          value: 'createdAt'
        },

        {
          text: 'Denumire',
          value: 'name'
        }
      ],
      sortOptionPriority: [
        {
          text: 'Prioritate',
          value: 'priority'
        },
      ],
      sortOption: 'createdAt'

    };
  },
  watch: {
    categoryFilter() {
      this.searchRecipes()
    },
    activeFilter() {
      this.searchRecipes()
    },
    searchRecipe(newVal) {
      this.timeout = setTimeout(() => {
        this.options.page = 1

        this.searchRecipes()
      }, 500);
    },
    options() {
      this.searchRecipes()
    },
    enabledEditPriorities(newTitle, oldTitle) {
      if (newTitle != oldTitle) {

        this.options.page = 1
        this.options.itemsPerPage = this.enabledEditPriorities ? '9999' : '28'
        this.options.sort = this.enabledEditPriorities ? 'priority' : 'createdAt'
        this.sortOption = this.enabledEditPriorities ? 'priority' : 'createdAt'
        this.searchRecipes()
      }

    },
    isForDelivery(newTitle, oldTitle) {
      if (newTitle != oldTitle) {
        this.enabledEditPriorities = false
        this.options.page = 1
        this.options.itemsPerPage = this.isForDelivery ? '9999' : '28'

        this.searchRecipes()
      }

    },
    sort() {
      this.options.page = 1
      this.searchRecipes()
    },
    sortOption() {
      this.options.page = 1
      this.options.sort = this.sortOption
      this.searchRecipes()
    },
    restaurantId() {
      this.searchRecipes()
    }
  },
  computed: {
    pdfOptions() {
      return this.isBasic ? [{ action: "Meniu", icon: "file-pdf-box" }] : [
        {
          action: "Meniu",
          icon: "file-download-outline",
          children: [
            {
              action: "PDF",
              icon: "file-pdf-box"
            },
            {
              action: "JSON",
              icon: "code-json"
            }
          ]
        },
        {
          action: "Meniu restrans",
          icon: "file-download-outline",
          children: [
            {
              action: "PDF",
              icon: "file-pdf-box"
            },
            {
              action: "DOCX",
              icon: "file-word"
            },
            {
              action: "JSON",
              icon: "code-json"
            },
            // {
            //   action: "EXCEL",
            //   icon: "file-excel"
            // }
          ]
        },
        {
          action: "Etichete A4",
          icon: "file-pdf-box"
        },
        {
          action: "Etichete A6",
          icon: "file-pdf-box"
        }
      ]
    },
    ingredientHeaders() {
      let headers = [

        {
          text: "",
          value: 'select',
          sortable: false


        },
        {
          text: "Nume",
          value: 'name',
          sortable: false


        },
        {
          text: "Categorie",
          value: 'category.name',
          sortable: false


        },
        {
          text: "Pret",
          value: 'price',
          sortable: false


        },
        {
          text: "Greutate neta",
          value: 'totalWeight',
          sortable: false


        },
        {
          text: "Utilizabil in alte retete",
          value: 'isCustomIngredient',
          sortable: false


        },
        {
          text: "Exclus din meniu",
          value: 'excludeFromMenu',
          sortable: false


        },

        {
          text: "Actiuni",
          value: 'actions',
          sortable: false
        }
      ]

      return headers


    },
    scene() {
      let categories = []
      this.recipes.map(r => r.category).map(category => {
        if (!categories.find(c => c._id == category._id)) {
          categories.push(category)
        }
      })
      categories = categories.sort((a, b) => { return a.priority - b.priority })
      this.categories = categories
      return {
        type: 'container',
        props: {
          orientation: 'horizontal',
        },
        children: this.categories.map(category => {
          return {
            id: category._id,
            type: 'container',
            name: `${category.name}`,
            border: (Math.random() * 0xFFFFFF << 0).toString(16),
            props: {
              orientation: this.categoryFilter ? 'horizontal' : 'vertical',
              className: 'card-container card-container1',
            },
            children: this.recipes.filter(r => r.category._id == category._id).map(recipe => {
              return {
                type: 'draggable',
                id: recipe._id,
                props: {
                  className: 'card card1',
                },
                data: recipe

              }
            })
          }

        })

      }
    },
    additives() {


    },

    page() {
      return Number(this.$route.query.page) || 1
    },
    perPage() {
      return Number(this.$route.query.perPage) || 28
    },
    isAdmin() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'ADMIN'
    },
    userImpId() {
      return this.$route.query.userId
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    restaurantNameImp() {
      return this.userImp && this.userImp.company ? this.userImp.company.restaurantName : ''
    },

    fieldNames() {
      return this.$store.state.recipes.fieldNames
    },
    unitMeasures() {
      return MapUnitMeasures
    },
    company() {
      if (this.isAdmin && this.userImpId && this.userImp.company) {
        return this.userImp.company
      }
      return this.$store.state.auth.user.company
    },
    userEmail() {
      if (this.isAdmin && this.userImpId && this.userImp) {
        return this.userImp.email
      }
      return this.$store.state.auth.user.email
    },
    userName() {
      if (this.isAdmin && this.userImpId && this.userImp) {
        return this.userImp.name
      }
      return this.$store.state.auth.user.name
    },
    userPhone() {
      if (this.isAdmin && this.userImpId && this.userImp) {
        return this.userImp.phoneNumber
      }
      return this.$store.state.auth.user.phoneNumber
    },
    loadingPdf() {
      return this.$store.state.recipes.loadingPdf
    },
    pdfName() {
      let name = this.restaurant.restaurantName
      return `Meniu ${name}`
    },
    docxName() {
      let name = this.restaurant.restaurantName
      return `Meniu ${name}.docx`
    },
    jsonName() {
      let name = this.restaurant.restaurantName
      return `Meniu ${name}.json`
    },
    recipes() {
      return this.$store.state.recipes.recipes
    },
    allCategories() {
      return this.$store.state.recipes.categories
    },
    totalRecipes() {
      return this.$store.state.recipes.totalRecipes
    },
    availableCredits() {

      return this.$store.state.auth.restaurant ?
        (this.$store.state.auth.restaurant.totalCredits - this.$store.state.auth.restaurant.usedCredits)
        : 0

    },
    restaurant() {
      return this.$store.state.auth.restaurant
    },
    isBasic() {
      return this.restaurant.paymentStatus == 'BASIC'
    },
    isDisabled() {
      return this.restaurant.paymentStatus == 'DISABLED'
    },
    isRecorder() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'DATA_RECORDER'
    },
    restaurantId() {
      let id = this.$route.query.restaurantId
      if ((!id || !id.length) && this.onlyOneRestaurant) {
        id = this.$store.state.restaurants.restaurants[0]._id
      }
      return id
    },
    onlyOneRestaurant() {
      return this.$store.state.restaurants.realTotalRestaurants == 1
    },
  },
  methods: {
    upgradePlan() {
      let url = `https://valori-nutritionale.ro/asp-payment-box/?product_id=1626&email=${this.userEmail}&cif=${this.company.vatId}&nume=${this.userName}&phoneNumber=${this.userPhone}&restaurant=${this.restaurant.restaurantName}`
      window.open(url, "_blank");
    },

    returnAdditives(ingredients) {
      let add = [];
      ingredients.forEach(element => {
        let adda = element.ingredientAdditives ? element.ingredientAdditives.filter(n => n).map(i => i.name.split('-')[0]) : null;
        if (adda && !add.includes(...adda)) {
          // console.log(...adda)
          add.push(...adda)
        }
      });

      return add.join()

    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);

      } catch ($e) {
        alert('Cannot copy');
      }
    },
    confirmTransform() {
      //restaurantId
      this.$store.dispatch('updateRecipesToGrams', {
        restaurantId: this.restaurantId, cb: () => {
            // this.searchRecipes()
          }
        });

    },

    openConfirmDialog() {
      this.confirmDialog = true
    },
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    openProductDialog(product) {
      this.createProductDialog = true
      this.currentProduct = product
    },
    closeProductDialog() {
      this.createProductDialog = false
      this.currentProduct = null
    },

    onColumnDrop(dropResult) {
      let category = this.categories[dropResult.removedIndex]
      let priority = dropResult.addedIndex + 1
      if (category) {
        this.categories = this.categories.filter(c => c._id != category._id)
        this.categories.splice(priority - 1, 0, category)
        this.$store.dispatch('updateCategoryPriority', {
          sortedCategories: this.categories, id: category._id, priority: priority, restaurantId: this.restaurantId, cb: () => {
            // this.searchRecipes()
          }
        });
      }

    },

    onCardDrop(columnId, dropResult) {
      if (dropResult.addedIndex !== null) {
        let priority = dropResult.addedIndex + 1
        let category = columnId
        let recipeId = dropResult.payload.id
        let categoryObj = this.categories.find(c => c._id == category)
        this.$store.dispatch('updateRecipePriority', {
          id: recipeId, categoryObj: categoryObj, category: category, priority: priority, restaurantId: this.restaurantId, cb: () => {
            // this.searchRecipes()
          }
        });

      }
    },

    getCardPayload(columnId) {
      return (index) => {
        return this.scene.children.filter((p) => p.id === columnId)[0].children[
          index
        ];
      };
    },

    searchRecipes() {
      this.$store.dispatch("fetchRecipes", {
        search: this.searchRecipe,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        restaurantId: this.restaurantId,
        sort: this.options.sort,
        category: this.categoryFilter ? this.categoryFilter._id : null,
        activeFilter: this.activeFilter == 'all' ? null : this.activeFilter
      })
      this.$store.dispatch('fetchCategories', this.restaurantId);
    },
    openRecipeDialog() {


      this.$router.push(`/recipe/?restaurantId=${this.restaurantId}&page=${this.options.page || 1}&perPage=${this.options.itemsPerPage || 28}`);
    },
    openQRDialog() {
      this.qrDialog = true
    },
    closeQRDialog() {
      this.qrDialog = false
    },
    activatePublicMenu() {
      this.$store.dispatch('updatePublicMenu', {
        restaurantId: this.restaurantId,
        hasPublicMenu: this.restaurant.hasPublicMenu ? false : true,
        cb: () => {
          this.$store.dispatch('fetchUser');
          this.$store.dispatch('fetchRestaurantById', this.restaurantId);
        }
      });
    },
    exportPdf(option, format) {
      if (!format) {
        format = "PDF"
      }
      // if(format == "DOCX") {

      //   this.$store.dispatch('fetchPdf', {
      //     selected: this.selected.map(s => s._id),
      //     restaurantId: this.restaurantId,
      //     option: option,
      //     format: format,
      //     cb: response => {
      //       let url = window.URL.createObjectURL(new Blob([response.data], { type: "application/docx" }));
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', this.docxName); //or any other extension
      //       document.body.appendChild(link);
      //       link.click();
      //     }
      //   });


      //   return
      // }
      let type, filename
      if (format == 'PDF') {
        type = "application/pdf"
        filename = this.pdfName
      }
      if (format == 'DOCX') {
        type = "application/docx"
        filename = this.docxName
      }
      if (format == 'JSON') {
        type = "application/json"
        filename = this.jsonName
      }

      this.$store.dispatch('fetchPdf', {
        selected: this.selected.map(s => s._id),
        restaurantId: this.restaurantId,
        option: option,
        format: format,
        cb: response => {
          let url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      });

    },
    select(item) {
      let existing = this.selected.find(i => i == item)
      if (!existing) {
        this.selected.push(item)
      } else {
        this.selected = this.selected.filter(i => i != item)
      }
    },
    editRecipe(recipe) {
      if (recipe.isProduct) {
        this.openProductDialog(recipe)
        return
      }
      this.$router.push(`/recipe/?restaurantId=${this.restaurantId}&id=${recipe._id}&page=${this.options.page || 1}&perPage=${this.options.itemsPerPage || 28}`);
    },
    copyRecipe(recipe) {
      this.$router.push(`/recipe/?restaurantId=${this.restaurantId}&id=${recipe._id}&copy=true&page=${this.options.page || 1}&perPage=${this.options.itemsPerPage || 28}`);
    },
    openDelete(recipe) {
      this.currentRecipe = recipe
      this.deleteDialog = true
    },
    deleteRecipe(recipe) {
      this.currentRecipe = null
      this.$store.dispatch('deleteRecipeById', {
        id: recipe._id,
        restaurantId: this.restaurantId
      });
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    }
  },
  created() {
    this.$store.dispatch('fetchFieldNamesForUI');
    if (this.page) {
      this.options.page = this.page
    }
    if (this.perPage) {
      this.options.itemsPerPage = this.perPage
    }
    this.searchRecipes()
  }
};
</script>
<style>
.v-toolbar__title {
  white-space: pre-wrap;
}

.kanban {
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  user-select: none;
}

.kanban__move-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 0.9rem;
  color: #b0b0b0;
  user-select: none;
}

.kanban__move-icon span {
  margin-left: 10px;
}

.kanban__move-icon svg {
  width: 20px;
  height: 20px;
}

.kanban__drop-preview {
  position: relative;
  margin: 10px;
  background-color: #edf1f3;
  border: 2px dashed rgba(0, 0, 0, 0.059);
  border-radius: 4px;
}

.kanban__drop-preview::before {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #b0b0b0;
  transform: translate(-50%, -50%);
}

.kanban__drop-preview::after {
  position: absolute;
  top: 50%;
  left: 27%;
  width: 20px;
  height: 20px;
  content: "";
  transform: translate(-50%, -50%);
}

.kanban__description {
  display: flex;
  align-items: center;
}

.kanban__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf5f7;
  padding: 0.5rem;
  border-radius: 8px;
}

.kanban__title {
  margin: 0 0 0 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}

.kanban__quantity {
  margin-top: 0;
  font-size: small;
  color: #b0b0b0;
  text-align: right;
}

.kanban__quantity--inner-count {
  margin: 1rem 1rem 0 1rem;
  padding-top: 0.5rem;
}

.kanban__column {
  flex: 1;
  height: fit-content;
  padding: 10px;
  border-radius: 6px;
  transition: height 0.5s ease;
  border: 1px solid #e0e0e0;
}

.kanban__column:not(:first-of-type) {
  margin-left: 15px;
}

.kanban__column--no-border {
  border: none;
}

.kanban__header {
  margin-bottom: 15px;
  background-color: #fff;
}

.kanban__content {
  position: relative;
}

.kanban__skeleton {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-right: 15px;
  background-color: #f8f8f8;
  border-radius: 4px;
  overflow: auto;
}

.kanban__skeleton-container {
  width: 100%;
}

.kanban__skeleton-column {
  flex: 1;
  height: calc(100% - 30px);
  padding: 10px;
  margin-left: 15px;
  background-color: #e9e9e9;
  border-radius: 6px;
}

.kanban__skeleton-card {
  position: relative;
  height: 100px;
  padding: 15px;
  margin-top: 16px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

div.itemCategory {
  min-width: 250px;
  max-width: 350px;
}

.kanban-cards>div {
  display: flex;
}

.kanban__skeleton-card--one {
  position: absolute;
  top: 8px;
}

.kanban__skeleton-card--two {
  position: absolute;
  top: 8px;
  right: 10px;
}

.kanban__skeleton-card--three {
  position: absolute;
  top: 30px;
}

.kanban__skeleton-card--four {
  position: absolute;
  bottom: 2rem;
}

.kanban__skeleton-card--five {
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
}

.item,
.draggable-item,
.draggable-item-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 8px;
  cursor: pointer;
  user-select: none;
  background-color: white;
  border: 1px solid transparent;
  border-left: 5px solid #0a5df8;
  /* border-bottom: 5px solid #0a5df8; */
  border-radius: 4px;
  transition: border-color 0.2s linear;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
}

.dndrop-container.horizontal {
  display: flex !important;
}

.dndrop-container.vertical>.dndrop-draggable-wrapper {
  min-width: 250px;
  max-width: 350px;
}


.itemCategory,
.draggable-item,
.draggable-item-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 8px;
  cursor: pointer;
  user-select: none;
  background-color: #0a5df806;
  border: 1px solid transparent;
  border-left: 5px solid #0a5df86c;
  border-radius: 4px;
  transition: border-color 0.2s linear;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
}

.item .card {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item .card small {
  color: #838383;
}

.badge {
  margin: 1rem 0;
  cursor: pointer;
}

.label {
  color: #838383;
  margin-bottom: 5px;
}

.kanban-action {
  display: flex;
  justify-content: left;
  padding-left: 5px
}

.kanban-button {
  max-width: 130px;
  /* pointer-events: none; */
  border: none;
  color: #f7f7f7;
  padding: 1px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}

.kanban-button.open {
  background-color: #f3c1c1;
}

.kanban-button.executing {
  background-color: #c4ebaf;
}

.kanban-button.finished {
  background-color: #b3d5e9;
}

.kanban-button.paralised {
  background-color: #dc3545;
}

.kanban-button.review {
  background-color: #e0e0e0;
}

.kanban-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 0;
  overflow-x: scroll
}

.card-container1 {
  margin: 10px;
  width: 300px;
  /* border: 1px solid blue; */
}

.card-column-header1 {
  font-weight: bold;
}

.card1 {
  margin-bottom: 10px;
}

.recipeName {
  font-size: 15px;
  /* font-weight: bold */
}

.smallText {
  font-size: 12px;
}

/* #search.v-text-field input {
  font-size: 14px;
} */

.v-text-field.searchWidth label,
.v-text-field.filterWidth label,
.v-text-field.searchWidth input,
.v-text-field.filterWidth input {
  font-size: 14px;
}

.v-text-field.sortWidth label,
.v-text-field.sortField label {
  font-size: 12px;
}

.v-select.sortWidth .v-select__selection,
.v-select.sortField .v-select__selection {
  font-size: 14px;
}

.searchWidth {
  width: 25%;
}

.filterWidth {
  width: 50%
}

.sortWidth {
  width: 34%;
}


.paddedLeft {
  padding-left: 10px
}

.paddedRight {
  padding-right: 15px
}

.sortField {
  max-width: 250px !important
}
</style>