<template>
  <auth-layout headline="Configurari meniu">

    <v-form class="w-full padded p-5" ref="form1">
      <h3 class="font-semibold text-xl text-gray-800 leading-tight">
        Mod afisare valori nutritionale in meniul public si export PDF
      </h3>
      <div class="padded">
        <v-select label="Mod afisare valori nutritionale *" v-model="model.nutriValuesDisplay"
          :items="nutriValuesViews" color="primary" :rules="[v => !!v || `Alege modul de afisare valori nutritionale`]" item
           item-text="name" item-value="value">
         
        </v-select>
      </div>

      <h3 class="font-semibold text-xl text-gray-800 leading-tight">
        Mod afisare cantitati ingrediente in meniul public si export PDF
      </h3>
      <div class="padded">
        <v-select label="Mod afisare cantitati ingrediente *" v-model="model.showIngredientQuantities"
          :items="ingredientViews" color="primary" :rules="[v => v !== null || `Alege modul de afisare ingrediente`]" item
           item-text="name" item-value="value" >
         
        </v-select>
      </div>


      <h3 class="font-semibold text-xl text-gray-800 leading-tight">
       Afiseaza preturile in meniu public si pdf
      </h3>
      <div class="padded">
        <v-switch  inset v-model="model.showPricesMenu"></v-switch>
      </div>

      <h3 class="font-semibold text-xl text-gray-800 leading-tight">
       Afiseaza numai ingredientele care au peste 20g la nivel de reteta
      </h3>
      <div class="padded">
        <v-switch  inset v-model="model.showOnlyIngredientsOver20"></v-switch>
      </div>

      <h3 class="font-semibold text-xl text-gray-800 leading-tight">
       Afiseaza cantitatile ca procente
      </h3>
      <div class="padded">
        <v-switch  inset v-model="model.showQuantitiesAsPercentages"></v-switch>
      </div>
      <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
    </v-form>

  </auth-layout>
</template>
<script>
import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
import AuthLayout from '../Layouts/AuthLayout.vue';

export default {
  data() {
    return {
      model: {
      },
      nutriValuesViews: [
        {
          name: 'Per portie (bruta si neta) si per 100 g',
          value: 5
        },
        {
          name: 'Per portie bruta si per 100 g',
          value: 4
        },
        {
          name: 'Per portie neta si per 100 g',
          value: 1
        },
        {
          name: 'Per portie (bruta si neta)',
          value: 2
        },
        {
          name: 'Per portie bruta',
          value: 6
        },
        {
          name: 'Per portie neta',
          value: 7
        },
        {
          name: 'Per 100 g',
          value: 3
        }
      ],
      // portionTypes: [
      //   {
      //     name: 'Portia neta si portia bruta',
      //     value: 1
      //   },
      //   {
      //     name: 'Portie bruta',
      //     value: 2
      //   },
      //   {
      //     name: 'Portie neta',
      //     value: 3
      //   }
      // ],
      ingredientViews: [
        {
          name: 'Afiseaza cantitatile ingredientelor in meniul public si in export PDF',
          value: true
        },
        {
          name: 'Nu afisa cantitatile ingredientelor in meniul public si in export PDF',
          value: false
        }
      ]
    };
  },
  watch: {
    restaurant() {
      this.model = {...this.restaurant}
    }
  },
  computed: {

    restaurant() {
      return this.$store.state.auth.restaurant
    },

    restaurantId() {
      return this.$route.query.restaurantId
    },
  },
  components: { AuthLayout, PrimaryButton },
  methods: {
    save() {
      if (!this.$refs.form1.validate()) {
        return
      }
      this.$store.dispatch('updateMenuConfigurations', {
        restaurantId: this.restaurantId,
        // showIngredientQuantities: this.model.showIngredientQuantities,
        // nutriValuesDisplay: this.model.nutriValuesDisplay,
        // portionType: this.model.portionType,
        // showPricesMenu: this.model.showPricesMenu
        model: this.model
      });
    },

  },
  created() {
    this.$store.dispatch('fetchRestaurantById', this.restaurantId);
  }
};
</script>
