<template>
  <auth-layout headline="Introducatori retete">

    <v-data-table
      :items="dataRecorders" 
      :headers="restaurantHeaders" 
      :search="search"
      :options.sync="options"
      :server-items-length="totalDataRecorders"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100],
        
      }"
    >
      <template v-slot:top>
        <v-toolbar class="mb-2 ">
          <v-text-field
            v-model="search"
            label="Search"
           
          ></v-text-field>
          <v-divider style="border-block: white;" />
          <v-btn @click="openRecorderDialog" class="text-gray-500 primary">
            <v-icon left dark>mdi-plus</v-icon>
            <span class="hidden-xs-only">Adauga introducator</span>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.recorderRecipes`]="props">
        <div v-if="props.item.recorderRecipes != undefined">
          {{props.item.recorderRecipes}}
        </div>
        <v-btn v-else @click="getNumberOfRecipes(props.item)" class="text-gray-500 primary">
            <span class="hidden-xs-only">Afla numar retete introduse</span>
        </v-btn>
        <!-- <router-link :to="`recipes/?userId=${props.item.userId}`"
          :class="`px-3 py-2 rounded-md text-sm font-medium sm:ml-6 grid`" aria-current="page"> <span>
            {{ props.item.restaurantName }}</span>
        </router-link> -->

      </template>
      <template v-slot:[`item.actions`]="props">
        <div class="flex">
          <v-btn  
            @click="openDialog(props.item)"
            color="accent" text
          >
            <font-awesome-icon  icon="fa-solid fa-pen" />
          </v-btn> 
          <v-btn  color="red"
            @click="openDeleteDialog(props.item)" text
          >
            <font-awesome-icon  icon="fa-solid fa-trash-can" />
          </v-btn> 
        </div>
      </template>
    </v-data-table>

    <create-recorder v-if="recorderDialog" :model="recorderModel" :closeDialog="closeRecorderDialog"/>

    <delete-recorder v-if="deleteDialog"
      :deleteRecorder="deleteRecorder"
      :closeDialog="closeDeleteDialog"
      :item="recorderModel"
    />

  </auth-layout>
</template>
<script>
import CreateRecorder from '../components/Modals/CreateRecorder.vue';
import DeleteRecorder from '../components/Modals/DeleteRecorder.vue';
import DeleteRestaurant from '../components/Modals/DeleteRestaurant.vue';
import EditRestaurant from '../components/Modals/EditRestaurant.vue';
import AuthLayout from '../Layouts/AuthLayout.vue';

export default {
  data() {
    return {
      deleteDialog: false,
      editDialog: false,
      recorderDialog: false,
      currentRestaurant: null,
      search: '',
      options: {},
      recorderModel: {},
      restaurantHeaders: [

        {
          text: "Nume",
          value: 'name'
        },
        {
          text: "Email",
          value: 'email'
        },
       
        {
          text: "Tip user",
          value: 'role'
        },
       
        {
          text: "Retete introduse",
          value: 'recorderRecipes'
        },
      
        {
          text: "Actiuni",
          value: 'actions'
        }
      ],
    };
  },
  components: { AuthLayout, DeleteRestaurant, EditRestaurant, CreateRecorder, DeleteRecorder },
  watch: {
    options() {
      this.searchUsers()
    },
    search(newVal) {
      this.timeout = setTimeout(() => {
        this.searchUsers()
      }, 500);
    },
  },
  computed: {
    totalDataRecorders() {
      return this.$store.state.users.totalDataRecorders
    },
    dataRecorders() {
      return this.$store.state.users.dataRecorders
    }
  },
  methods: {
    closeRecorderDialog() {
      this.recorderDialog = false
    },
    openRecorderDialog() {
      this.recorderDialog = true
      this.recorderModel = {}
    },
    openDeleteDialog(user) {
      this.deleteDialog = true
      this.recorderModel = user
    },
    closeDeleteDialog() {
      this.deleteDialog = false
      this.recorderModel = {}
    },
    openDialog(user) {
      this.recorderDialog = true
      this.recorderModel = user
    },
    closeDialog() {
      this.recorderDialog = false
      this.recorderModel = {}
    },
    searchUsers() {
      this.$store.dispatch('fetchDataRecorders', {
        page: this.options.page, 
        perPage: this.options.itemsPerPage, 
        search: this.search
      });
    },
    
    
    deleteRecorder(dataRecorder) {
      this.$store.dispatch('deleteRecorderById', dataRecorder._id);
    },
    getNumberOfRecipes(dataRecorder) {
      this.$store.dispatch('fetchNumberOfRecipes', {
        dataRecorderId: dataRecorder._id, 
      });
    }
  },
  created() {
    this.$store.dispatch('fetchDataRecorders', {
      page: this.options.page, 
      perPage: this.options.itemsPerPage, 
      search: ''
    });
    
  }
};
</script>
