<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga ingredient</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" class="customFlex">
            <div class="padded w-half m-5">
              <v-text-field
                  ref='focusMe'
                  type="text"
                  placeholder="Nume ingredient *"
                  v-model="model.name"
                  required
                  label="Nume ingredient *"
                  :rules="[
                    v => !!v || `Completeaza numele ingredientului`,
                    v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
                  ]"
                  outlined
                />
            </div>

            <div class="padded w-half m-5">
              <v-autocomplete label="Categorie *" v-model="model.category"
                :items="ingredientCategories" color="blue-grey-lighten-2" 
                :rules="[v => !!v || `Alege categoria`]"
                item 
                outlined 
                return-object
                >
                </v-autocomplete>
            </div>

            <div class="padded w-full">
              <v-text-field
                type="text"
                placeholder="Completati ingredientele de pe eticheta"
                v-model="model.explanation"
                label="Descriere ingrediente"
                outlined
                />
            </div>

            <div class="padded w-full">
            Valori pentru 100 g
            </div>

            <div class="padded w-half m-5" v-for="field in inputFields" :key="field.fieldName">
              <v-text-field 
                type="number" 
                :label="field.label" 
                :placeholder="field.placeholder"
                v-model="model[field.fieldName]"
                required outlined
                :max="9999.99"
                oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                :rules="[
                    v => !!v || `Completeaza valoarea`, 
                    v => Number(v) >= 0 || `Numarul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                    ]"
                />
            </div>

            <div class="padded w-full">
            Unitate de masura
            </div>

            <div class="padded w-half m-5">
              <v-autocomplete label="Unitate *" v-model="model.serving.name"
                :items="unitMeasures" color="blue-grey-lighten-2"
                @change="setServingAmount()"
                :rules="[v => !!v || `Alege categoria`]"
                item 
                outlined 
                return-object
                >
                </v-autocomplete>
            </div>

            <div class="padded w-half m-5" v-if="!showAmount(model.serving.name)">
              <v-text-field 
                type="number" 
                :label="`Grame per `+ model.serving.name + ' *'"
                :placeholder="`Grame per `+ model.serving.name + ' *'"
                v-model="model.serving.amount"
                required outlined
                :disabled="isDefaultUnitMeasure(model.serving.name)"
                :max="9999.99"
                oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                :rules="[
                    v => !!v || `Completeaza valoarea`, 
                    v => Number(v) >= 0 || `Numarul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                    ]"
                />
            </div>

            <div class="padded w-full">
            Aditivi
            </div>

            <div class="padded w-half m-5">
              <v-autocomplete class="" d-flex v-model="model.additives" label="Cauta aditivi" hide-selected
                text-color="white" :items="additives" item-text="name" outlined multiple item small-chips :clearable="true"
                :deletable-chips="true" :search-input.sync="searchAdditive" no-filter return-object  @change="searchAdditive=''">
                <template slot="selection" slot-scope="data">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :input-value="data.selected" color="warning" text-color="white" close
                        @click:close="removeAdditive(data.item)" v-bind="attrs" v-on="on">
                        <strong>{{ data.item.name.split("-")[0] }}</strong>
                      </v-chip>
                    </template>
                    <span>{{ data.item.name }}</span>
                  </v-tooltip>

                </template>
              </v-autocomplete>
            </div>

            <div class="padded w-full">
            Alergeni
            </div>

            <div class="padded w-half m-5">

              <v-autocomplete class="ml-5 mr-5" d-flex v-model="model.allergens" label="Cauta alergeni" hide-selected
                text-color="white" :items="allergens" item-text="name" multiple small-chips :clearable="true"
                :deletable-chips="true"  return-object >
                <template slot="selection" slot-scope="data">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :input-value="data.selected" color="accent" text-color="white" close
                        @click:close="removeAllergen(data.item, model)" v-bind="attrs" v-on="on">
                        <strong>{{ data.item.name.charAt(0) }}</strong>

                      </v-chip>
                    </template>
                    <span>{{ data.item.name }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </div>
            <div class="padded w-half m-5">
            <v-autocomplete class="ml-5 mr-5" v-model="model.potentialAllergens" :items="allergens"
               :clearable="true" :deletable-chips="true"
              label="Cauta potentiali alergeni" hide-selected text-color="white" item-text="name" d-flex multiple
              small-chips return-object >
              <template slot="selection" slot-scope="data">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip :input-value="data.selected" color="primary" text-color="white" close
                      @click:close="removePotentialAllergen(data.item, model)" v-bind="attrs" v-on="on">
                      <strong>{{ data.item.name.charAt(0) }}</strong>


                    </v-chip>
                  </template>
                  <span>{{ data.item.name }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
            </div>


          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn type="button" class="mainButtonYes" text @click="openConfirmDialog()">Salveaza</v-btn>
          <v-btn type="button"  class="mainButtonNo" color="red"  @click="close()">Renunta</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmCreateIngredient v-if="confirmDialog" :closeDialog="closeConfirmDialog" :confirm="confirm"/>

  </v-layout>
</template>

<script>

import {nutriKeys} from '../../common/UnitMeasures'
import ConfirmCreateIngredient from './ConfirmCreateIngredient.vue';

export default {
  components: {
    ConfirmCreateIngredient: ConfirmCreateIngredient,
},
  props: [
    "closeDialog",
    "model",
    "callback"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    isEdit: false,
    searchAdditive: '',
    confirmDialog: false
  }),
  watch: {
    searchAdditive(newVal) {
      this.timeout = setTimeout(() => {
        this.searchAdditives()
      }, 500);
    }

  },
  computed: {
    restaurantId() {
      return this.$route.query.restaurantId
    },
    additives() {
      return this.$store.state.recipes.additives.map(a => this.formatAdditiveName(a))
        .concat(this.model.additives ? this.model.additives.map(a => this.formatAdditiveName(a)) : [])
    },
    userImpId() {
      return this.$route.query.userId
    },
    ingredientCategories() {
      return this.$store.state.ingredients.ingredientCategories
    },
    unitMeasures() {
      return this.$store.state.unitMeasures.unitMeasures.map(i => i.name)
    },
    fieldNames() {
      return this.$store.state.recipes.fieldNames
    },
    allergens() {
      return this.$store.state.recipes.allergens
    },
    inputFields() {
      let fields = []

      let allNutri = [...nutriKeys, 'fiber'].filter(k => k != 'energyKJ')

      // deci label, place holder, fieldname,...
      allNutri.map(key => {
        
        fields.push({
          label: `${this.fieldNames[key]?.displayName} (${this.fieldNames[key]?.unitMeasure})`,
          fieldName: key,
          placeholder: `${this.fieldNames[key]?.displayName}`,
        })

      })

      return fields

    }
  },
  methods: {
    close() {
      this.closeDialog()
      
    },
    formatAdditiveName(additive) {
      if(additive.name.includes(additive.categories)) {
        return additive
      }
      additive.name = additive.name + ' (' + additive.categories + ')'
      return additive
    },
    searchAdditives() {
      this.$store.dispatch("fetchAdditives", this.searchAdditive)
    },
    setServingAmount() {
      let unitMeasure = this.model.serving.name
      if(this.isDefaultUnitMeasure(unitMeasure)) {
        this.model.serving.amount = this.getAmount(unitMeasure)
      } else {
        this.model.serving.amount = null
      }

    },
    removeAdditive(additive) {
      this.model.additives = this.model.additives.filter(a => {
        return a._id != additive._id
      })
    },
    removeAllergen(allergen, ingredient) {
      ingredient.allergens = ingredient.allergens.filter(a => {
        return a._id != allergen._id
      })
    },
    removePotentialAllergen(allergen, ingredient) {
      ingredient.potentialAllergens = ingredient.potentialAllergens.filter(a => {
        return a._id != allergen._id
      })
    },
    getAmount(unitMeasure) {
      let unit = this.$store.state.unitMeasures.unitMeasures.find(u => u.name == unitMeasure)
      return unit && unit.amount ? unit.amount : 1
    },
    isDefaultUnitMeasure(unitMeasure) {
      let unit = this.$store.state.unitMeasures.unitMeasures.find(u => u.name == unitMeasure)
      return unit && unit.default
    },
    showAmount(unitMeasure) {
      return unitMeasure == 'Grame'
    },
    confirm() {
      this.save()
    },
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    openConfirmDialog() {
      if(this.$refs.form.validate()) {
        this.confirmDialog = true
      }
    },
    save() {
      if(this.$refs.form.validate()) {
        if(this.model._id) {
          this.$store.dispatch('updateIngredient', {data: this.model, restaurantId: this.restaurantId, cb: () => {if(this.callback) {this.callback()}}});
        } else {
          this.$store.dispatch('createIngredient', {data: this.model, restaurantId: this.restaurantId, cb: () => {if(this.callback) {this.callback()}}});
        }
        
        this.close()
      }

    }
  },
  created() {
    this.$store.dispatch("fetchIngredientCategories")
    this.$store.dispatch("fetchUnitMeasures")
    this.$store.dispatch("fetchAllergens")
    
  },
  mounted() {
    this.$nextTick(() => this.$refs.focusMe && this.$refs.focusMe.focus())
  }

};
</script>

<style>
.customFlex{
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.m-0{
  margin:0
}
.padded.w-half.m-5 {
    margin: 0.5em!important;
}
</style>